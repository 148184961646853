import { config } from "components/AIScorecardComponents/config";
import * as API from "api/index";
import { TrendDataType } from "components/AIScorecardComponents/CopilotTrends/types";
import {
    AIScorecardTableType,
    CopilotWeeklyChartType,
    LicenseType,
    NewTenantsType,
    TopTenantUsageType,
    MTEandTVTableType,
} from "components/AIScorecardComponents/types";
import { Workload } from "config/PlatformConfig";
import { KustoResponseType } from "pages/commonTypes";
import { QueryDictType } from "utils/types";

export const fetchCopilotData = async (
    params: QueryDictType,
    queryName: string,
): Promise<KustoResponseType<string | number>> => {
    const queryParams = { ...params };
    const response = await API.getKustoResponse({
        queryName: queryName,
        platform: Workload.WEB,
        queryParams,
    });
    return response?.data;
};

interface tempFormatInterface {
    [date: string]: Partial<AIScorecardTableType>;
}

const getFlattenedGoalData = (goalResponse: KustoResponseType<string | number>) => {
    const goalsData = goalResponse.Tables[0];
    const tempGoalData = {};
    // goals schema- MetricName, Goal, GoalExpression, GoalDisplayText
    goalsData.Rows.forEach((dataRow) => {
        const goalMetricName = dataRow[0] as string;
        const goal = dataRow[1];
        const goalExpression = dataRow[2] as string;
        const goalDisplayText = dataRow[3] as string;

        tempGoalData[goalMetricName] = goalDisplayText;
        tempGoalData[goalMetricName + "Goal"] = goal;
        tempGoalData[goalMetricName + "GoalExpression"] = goalExpression;
    });
    return tempGoalData;
};

export const formatMBROverviewScorecardData = (
    response: KustoResponseType<string | number>,
    goalResponse: KustoResponseType<string | number>,
) => {
    const scorecardData = response.Tables[0];
    const temp: tempFormatInterface = {};
    const tempGoalData = getFlattenedGoalData(goalResponse);

    //response schema- Rowlabel, MetricName, MetricValue
    scorecardData.Rows.forEach((dataRow) => {
        const rowLabel = dataRow[0] as string;
        const metricName = dataRow[1] as string;
        const metricValue = dataRow[2] as number;
        if (!(rowLabel in temp)) temp[rowLabel] = {};
        temp[rowLabel][metricName] = metricValue ?? "-";
        if (metricValue != null && tempGoalData[metricName]) {
            temp[rowLabel][metricName + "IsGoalMet"] = IsGoalMet(
                metricValue,
                tempGoalData[metricName + "Goal"],
                tempGoalData[metricName + "GoalExpression"],
            );
        }
    });

    const flattendData: AIScorecardTableType[] = [];

    // First row is the goal display text
    flattendData.push({
        RowLabel: "Goal",
        ...tempGoalData,
        Date: "",
        Capability: "",
        Feature: "",
    });

    Object.keys(temp).forEach((rowLabel) => {
        flattendData.push({
            RowLabel: rowLabel,
            ...temp[rowLabel],
            Date: "",
            Capability: "",
            Feature: "",
        });
    });

    return flattendData;
};

const IsGoalMet = (metricValue: number, goal: number, goalExpression: string) => {
    switch (goalExpression) {
        case "LessThan":
            return metricValue < goal;
        case "GreaterThan":
            return metricValue > goal;
        case "LessThanEqual":
            return metricValue <= goal;
        case "GreaterThanEqual":
            return metricValue >= goal;
        default:
            return undefined;
    }
};

export const formatMBRFeatureScorecardTableData = (
    response: KustoResponseType<string | number>,
    goalResponse: KustoResponseType<string | number>,
) => {
    const scorecardData = response.Tables[0];
    const temp: tempFormatInterface = {};
    const tempCapabilityLevelMetrics = {};
    const tempAppLevelMetrics = {};
    const tempGoalData = getFlattenedGoalData(goalResponse);

    //response schema- Feature, Capability, MetricName, MetricValue

    scorecardData.Rows.forEach((dataRow) => {
        const feature = dataRow[0] as string;
        const capability = dataRow[1] as string;
        const metricName = dataRow[2] as string;
        const metricValue = dataRow[3] as number;
        const isGoalMet = "IsGoalMet";
        const goal = "Goal";
        const goalExpression = "GoalExpression";

        // Extract app level metrics
        if (feature === "All" && capability === "All") {
            const appMetricName = "App" + metricName;
            tempAppLevelMetrics[appMetricName] = metricValue;

            if (metricValue != null && tempGoalData[appMetricName]) {
                tempAppLevelMetrics[appMetricName + isGoalMet] = IsGoalMet(
                    metricValue,
                    tempGoalData[appMetricName + goal],
                    tempGoalData[appMetricName + goalExpression],
                );
            }
        }

        // Extract capabiliy level metrics
        if (feature === "All" && capability !== "All") {
            if (!(capability in tempCapabilityLevelMetrics)) {
                tempCapabilityLevelMetrics[capability] = {};
            }
            const capabilityMetricName = "Capability" + metricName;
            tempCapabilityLevelMetrics[capability][capabilityMetricName] =
                metricValue;
            if (metricValue != null && tempGoalData[capabilityMetricName]) {
                tempCapabilityLevelMetrics[capability][
                    capabilityMetricName + isGoalMet
                ] = IsGoalMet(
                    metricValue,
                    tempGoalData[capabilityMetricName + goal],
                    tempGoalData[capabilityMetricName + goalExpression],
                );
            }
        }

        // Extract feature level metrics
        if (feature !== "All" && capability !== "All") {
            var firstFeatureForCapability = false;
            if (!(capability in temp)) {
                temp[capability] = {};
                // first feature for capability
                firstFeatureForCapability = true;
            }
            if (!(feature in temp[capability])) {
                temp[capability][feature] = {};
                temp[capability][feature]["FirstFeatureForCapability"] =
                    firstFeatureForCapability;
            }
            temp[capability][feature][metricName] = metricValue;
            if (metricValue != null && tempGoalData[metricName]) {
                temp[capability][feature][metricName + isGoalMet] = IsGoalMet(
                    metricValue,
                    tempGoalData[metricName + goal],
                    tempGoalData[metricName + goalExpression],
                );
            }
        }
    });

    // Add App level metrics to features
    Object.keys(temp).forEach((capability) => {
        Object.keys(temp[capability]).forEach((feature) => {
            Object.keys(tempAppLevelMetrics).forEach((metricName) => {
                temp[capability][feature][metricName] =
                    tempAppLevelMetrics[metricName];
            });
        });
    });

    // Add Capablity level metrics to features
    Object.keys(temp).forEach((capability) => {
        if (capability in tempCapabilityLevelMetrics) {
            Object.keys(temp[capability]).forEach((feature) => {
                var firstFeatureForCapability =
                    temp[capability][feature]["FirstFeatureForCapability"];
                Object.keys(tempCapabilityLevelMetrics[capability]).forEach(
                    (metricName) => {
                        if (firstFeatureForCapability) {
                            temp[capability][feature][metricName] =
                                tempCapabilityLevelMetrics[capability][metricName];
                        } else {
                            temp[capability][feature][metricName] = "";
                        }
                    },
                );
            });
        }
    });

    const flattendData: AIScorecardTableType[] = [];

    // First row is the goal row
    flattendData.push({
        Capability: "Goal",
        Feature: "Goal",
        ...tempGoalData,
        Date: "",
    });

    // Rest of the rows are the metric rows
    Object.keys(temp).forEach((capability) => {
        Object.keys(temp[capability]).forEach((feature) => {
            flattendData.push({
                Capability: capability,
                Feature: feature,
                ...temp[capability][feature],
            });
        });
    });

    flattendData.forEach((row) => {
        if (row.Feature !== "Goal") {
            if (row.Tried && row.Seen) {
                row.TriedRate = Math.round((row.Tried / row.Seen) * 100);
                if (row["TriedDeltaValue"] && row["SeenDeltaValue"]) {
                    const prevTried = row.Tried - row["TriedDeltaValue"];
                    const prevSeen = row.Seen - row["SeenDeltaValue"];
                    const prevTriedRate = Math.round((prevTried / prevSeen) * 100);
                    row["TriedRateDeltaValue"] = row.TriedRate - prevTriedRate;
                }
            }
            if (row["TriedCounts"] && row["KeptCounts"]) {
                row["KeptFeatureRate"] = Math.round(
                    (row["KeptCounts"] / row["TriedCounts"]) * 100,
                );
                if (row["TriedCountsDeltaValue"] && row["KeptCountsDeltaValue"]) {
                    const prevTried =
                        row["TriedCounts"] - row["TriedCountsDeltaValue"];
                    const prevKept = row["KeptCounts"] - row["KeptCountsDeltaValue"];
                    const prevKeptFeatureRate = Math.round(
                        (prevKept / prevTried) * 100,
                    );
                    row["KeptFeatureRateDeltaValue"] =
                        row["KeptFeatureRate"] - prevKeptFeatureRate;
                }
            }
            if (row.Tried && row["AppTried"]) {
                row["FeatureTriedPercent"] = (row.Tried / row["AppTried"]) * 100;
                if (row["TriedDeltaValue"] && row["AppTriedDeltaValue"]) {
                    const prevTried = row.Tried - row["TriedDeltaValue"];
                    const prevAppTried = row["AppTried"] - row["AppTriedDeltaValue"];
                    const prevFeatureTriedRate = Math.round(
                        (prevTried / prevAppTried) * 100,
                    );
                    row["FeatureTriedPercentDeltaValue"] =
                        row["FeatureTriedPercent"] - prevFeatureTriedRate;
                }
            }
        }
    });

    return flattendData;
};

export const formatScorecardTableData = (
    response: KustoResponseType<string | number>,
) => {
    const scorecardData = response.Tables[0];
    const temp: tempFormatInterface = {};

    //response schema- Feature, Capability, MetricName, MetricValue
    scorecardData.Rows.forEach((dataRow) => {
        const feature = dataRow[0] as string;
        const capability = dataRow[1];
        const metricName = dataRow[2];
        if (!(capability in temp)) temp[capability] = {};
        if (!(feature in temp[capability])) temp[capability][feature] = {};
        temp[capability][feature][metricName] = dataRow[3] ?? "-";
    });

    const flattendData: AIScorecardTableType[] = [];

    Object.keys(temp).forEach((capability) => {
        Object.keys(temp[capability]).forEach((feature) => {
            flattendData.push({
                Capability: capability,
                Feature: feature,
                ...temp[capability][feature],
            });
        });
    });

    flattendData.forEach((row) => {
        if (row.Seen && row.Enabled) {
            row.SeenRate = Math.round((row.Seen / row.Enabled) * 100);
        }
        if (row.Tried && row.Seen) {
            row.TriedRate = Math.round((row.Tried / row.Enabled) * 100);
            row.TriedDropoff = Math.round(((row.Seen - row.Tried) / row.Seen) * 100);
        }
        if (row.Kept && row.Tried) {
            row.KeptRate = Math.round((row.Kept / row.Enabled) * 100);
            row.KeptDropoff = Math.round(((row.Tried - row.Kept) / row.Tried) * 100);
        }
    });

    return flattendData;
};

export const formatSummaryScorecardDataForExport = (
    response: KustoResponseType<string | number>,
) => {
    const scorecardData = response.Tables[0];
    const temp: tempFormatInterface = {};

    //response schema- App, Feature, Capability, MetricValue, MetricName
    scorecardData.Rows.forEach((dataRow) => {
        const app = dataRow[0] as string;
        const feature = dataRow[1] as string;
        const capability = dataRow[2] as string;
        const metricName = dataRow[4] as string;
        if (!(app in temp)) {
            temp[app] = {};
        }
        if (!(capability in temp[app])) {
            temp[app][capability] = {};
        }
        if (!(feature in temp[app][capability])) {
            temp[app][capability][feature] = {};
        }
        temp[app][capability][feature][metricName] = dataRow[3] ?? "-";
    });

    const flattendData: AIScorecardTableType[] = [];
    Object.keys(temp).forEach((app: string) => {
        Object.keys(temp[app]).forEach((capability: string) => {
            Object.keys(temp[app][capability]).forEach((feature: string) => {
                flattendData.push({
                    App: app as string,
                    Verb: capability as string,
                    Feature: feature as string,
                    ...temp[app][capability][feature],
                });
            });
        });
    });

    return flattendData;
};

export const formatChartData = (response: KustoResponseType<string | number>) => {
    const data = response.Tables[0];
    const temp = {};

    //response schema- Date, Enabled, Seen, Tried, Kept
    data.Rows.forEach((dataRow) => {
        const date = dataRow[0];
        const metric = dataRow[1];
        if (!(date in temp)) temp[date] = {};
        temp[date][metric] = dataRow[2];
    });

    const flattendData: CopilotWeeklyChartType[] = [];

    Object.keys(temp).forEach((dt) => {
        flattendData.push({
            Date: dt,
            ...temp[dt],
        });
    });

    return flattendData;
};

export const formatFeatures = (response: KustoResponseType<string | number>) => {
    const data = response.Tables[0];
    const capabilities = {};

    //response schema- Feature, Capability
    data.Rows.forEach((dataRow) => {
        const feature = dataRow[0] as string;
        const capability = dataRow[1] as string;
        if (!(capability in capabilities)) {
            capabilities[capability] = [];
        }
        capabilities[capability].push(feature);
    });

    return capabilities;
};

export const formatTrendData = (response: KustoResponseType<string | number>) => {
    const data: TrendDataType[] = [];
    response.Tables[0].Rows.map((x) =>
        data.push({
            Date: x[0] as string,
            AppName: x[1] as string,
            MetricName: x[2] as string,
            MetricValue: x[3] as number,
        }),
    );
    return data;
};

export const formatCopilotNewTenantData = (
    response: KustoResponseType<string | number>,
) => {
    const data = response.Tables[0];
    const flattendData: NewTenantsType[] = [];

    //response schema- TenantName, OmsTenantId, AvailableUnits
    data.Rows.forEach((dataRow) => {
        const tenantName = dataRow[0] as string;
        const tenantId = dataRow[1] as string;
        const availableUnits = dataRow[2] as number;
        flattendData.push({
            tenantName: tenantName,
            tenantId: tenantId,
            availableUnits: availableUnits,
        });
    });

    return flattendData;
};

export const formatCopilotTopTenantUsageData = (
    response: KustoResponseType<string | number>,
) => {
    const data = response.Tables[0];
    const flattendData: TopTenantUsageType[] = [];

    //response schema- TenantName, AvailableUnits, EnabledUsers, MetricValue, OmsTenantId
    data.Rows.forEach((dataRow) => {
        const tenantName = dataRow[0] as string;
        const availableUnits = dataRow[1] as number;
        const enabledUsers = dataRow[2] as number;
        const mau = dataRow[3] as number;
        const tenantId = dataRow[4] as string;
        flattendData.push({
            tenantName: tenantName,
            availableUnits: availableUnits,
            enabledUsers: enabledUsers,
            mau: mau,
            tenantId: tenantId,
        });
    });

    return flattendData;
};

export const formatCopilotLicenseData = (
    response: KustoResponseType<string | number>,
) => {
    const row = response.Tables[0]?.Rows[0];
    let data: LicenseType = null;
    if (row)
        data = {
            availableUnits: row[0] as number,
            enabledUsers: row[1] as number,
            skuName: row[2] as string,
        };
    return data;
};

export const formatDates = (response: KustoResponseType<string | number>) => {
    const dates: any[] = [];
    response.Tables[0].Rows.map((x) => dates.push(x[0]));
    return dates;
};

export const formatSummaryScorecardData = (
    response: KustoResponseType<string | number>,
) => {
    const data: any[] = [];
    const colMap = {};
    response.Tables[0].Columns.forEach(
        (column, idx) => (colMap[column.ColumnName] = idx),
    );
    response.Tables[0].Rows.forEach((row) => {
        let tmp = { MetricName: row[0] as string };
        config.appsList.forEach((app) => {
            tmp[app] = row[colMap[app]] as number;
        });
        tmp["Postfix"] = row[colMap["Postfix"]];
        data.push(tmp);
    });
    return data;
};

export const formatM365ScorecardData = (
    response: KustoResponseType<string | number>,
) => {
    const scorecardData = response.Tables[0];
    const temp: tempFormatInterface = {};

    //response schema- AppName, MetricName, MetricValue
    scorecardData.Rows.forEach((dataRow) => {
        const appName = dataRow[0] as string;
        const metricName = dataRow[1];
        if (!(appName in temp)) temp[appName] = {};
        temp[appName][metricName] = dataRow[2] ?? "-";
    });

    const flattendData: any[] = [];

    Object.keys(temp).forEach((appName) => {
        flattendData.push({
            AppName: appName,
            ...temp[appName],
        });
    });

    return flattendData;
};

export const formatCapData = (response: KustoResponseType<string | number>) => {
    const data = response.Tables[0];
    const flattenedData: any[] = [];
    let totalTried = 0;

    data.Rows.forEach((dataRow) => {
        let triedCount = dataRow[5] as number;

        totalTried += triedCount;
    });

    data.Rows.forEach((dataRow) => {
        let feature = dataRow[2] as string;
        let label = dataRow[3] as string;
        let distinctUserCount = dataRow[4] as string;
        let triedCount = dataRow[5] as number;
        let keptCount = dataRow[6] as number;

        let triedRate = (triedCount / totalTried) * 100;
        let keptRate = (keptCount / triedCount) * 100;
        if (triedRate > 0) {
            flattenedData.push({
                Name: feature + ":" + label.split(":")[1],
                Intent: label.split(":")[1],
                Feature: feature,
                TriedCount: triedCount,
                TriedRate: Number(triedRate.toFixed(1)),
                DistinctUserCount: distinctUserCount,
                KeptRate: Number(keptRate.toFixed(1)),
                Intents: [label],
                z: 100, // used for bubble sizing
            });
        }
    });

    return flattenedData;
};

export const formatCapTrendsData = (
    response: KustoResponseType<string | number>,
) => {
    const data = response.Tables[0];
    let formatttedTrendData = [];
    data.Rows.forEach((dataRow) => {
        let triedCount = dataRow[1] as number;
        let keptCount = dataRow[2] as number;
        if (triedCount && triedCount > 0) {
            let keptRate = ((keptCount / triedCount) * 100).toFixed(1);
            formatttedTrendData.push({
                Date: dataRow[0],
                TriedCount: triedCount,
                KeptRate: keptRate,
            });
        }
    });
    return formatttedTrendData;
};

export const formatCapFeatures = (response: KustoResponseType<string | number>) => {
    let features = [];

    // Add an "All" option to feature list
    features.push({
        key: "All Features",
        text: "All Features",
    });
    response.Tables[0].Rows.map((x) =>
        features.push({
            key: x[0] as string,
            text: x[0] as string,
        }),
    );
    return features;
};

export const formatM365MTEandTVData = (
    response: KustoResponseType<string | number>,
) => {
    const mteAndTvData = response.Tables[0];
    const flattendData: MTEandTVTableType[] = [];

    const colMap = {};
    mteAndTvData.Columns.forEach((column, idx) => (colMap[column.ColumnName] = idx));

    mteAndTvData.Rows.forEach((dataRow) => {
        const tempObj: MTEandTVTableType = { AppName: "", FeatureVerb: "" };
        Object.entries(colMap).forEach(([col, i]: [string, number]) => {
            switch (col) {
                case "AppName":
                    tempObj.AppName = dataRow[i] as string;
                    break;
                case "Capability":
                    tempObj.FeatureVerb = dataRow[i] as string;
                    break;
                case "ASHA":
                    tempObj.ASHA = dataRow[i] as number;
                    break;
                case "NegativeFeelingsRate":
                    tempObj.NegativeFeelingsRate = dataRow[i] as number;
                    break;
                case "NegativeTimeSavedRate":
                    tempObj.NegativeTimeSavedRate = dataRow[i] as number;
                    break;
                case "TimeSavedResponseCount":
                    tempObj.TimeSavedResponseCount = dataRow[i] as number;
                    break;
                case "ThumbsDownPer100k":
                    tempObj.ThumbsDownPer100k = dataRow[i] as number;
                    break;
                case "Week1RetentionRate":
                    tempObj.Week1RetentionRate = dataRow[i] as number;
                    break;
                case "CopilotNps":
                    tempObj.CopilotNps = dataRow[i] as number;
                    break;
                case "PositiveProductMarketFitRate":
                    tempObj.PositiveProductMarketFitRate = dataRow[i] as number;
                    break;
                case "ExceededExpectationsRate":
                    tempObj.ExceededExpectationsRate = dataRow[i] as number;
                    break;
                case "FeelingsResponseCount":
                    tempObj.NegativeFeelingResponseCount = dataRow[i] as number;
                    break;
                case "ExpectationsResponseCount":
                    tempObj.ExceedExpectationsResponseCount = dataRow[i] as number;
                    break;
                case "ProductMarketFitResponseCount":
                    tempObj.PMFResponseCount = dataRow[i] as number;
                    break;
            }
        });
        flattendData.push(tempObj);
    });

    return flattendData;
};
