import React from "react";
import { Stack } from "@fluentui/react";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { CommonFunnel } from "components/CommonFunnelComponents/CommonFunnel/CommonFunnel";
import CommonPivot from "components/CommonPivot/CommonPivot";
import ERFMScorecard from "pages/ERFM/ERFMScorecard";
import { eRFMSectionTabs } from "components/ERFMComponents/types";
import { eRFMFunnelType } from "config/FunnelConfig";
import { DrilldownPages, getPageConfig } from "config/PagesConfig";
import { commonPageStyle } from "pages/common";
import "pages/ERFM/style.css";

const ERFM = () => {
    const { pageHeader, pageTitle } = getPageConfig(DrilldownPages.ERFM);

    return (
        <Stack styles={commonPageStyle}>
            <Stack.Item>
                <div className="orgHeader">{pageHeader}</div>
            </Stack.Item>
            <Stack.Item>
                <CommonPivot pageTitle={pageTitle} sectionTabs={eRFMSectionTabs}>
                    <PivotItem
                        itemKey={eRFMSectionTabs.Scorecard}
                        headerText={eRFMSectionTabs.Scorecard}
                    >
                        <ERFMScorecard />
                    </PivotItem>
                    <PivotItem
                        itemKey={eRFMSectionTabs.Funnel_Upsell}
                        headerText={`${eRFMSectionTabs.Funnel_Upsell} Funnel`}
                    >
                        <CommonFunnel funnelType={eRFMFunnelType.Upsell} />
                    </PivotItem>
                    <PivotItem
                        itemKey={eRFMSectionTabs.Funnel_Engagement}
                        headerText={`${eRFMSectionTabs.Funnel_Engagement} Funnel`}
                    >
                        <CommonFunnel funnelType={eRFMFunnelType.Engagement} />
                    </PivotItem>
                </CommonPivot>
            </Stack.Item>
        </Stack>
    );
};

export default ERFM;
