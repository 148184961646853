import React from "react";
import { IColumn, Icon, TooltipHost } from "@fluentui/react";
import {
    IntentAnalysisScorecardColumns,
    IntentAnalysisSatMetricColumns,
} from "components/AIScorecardComponents/config";
import { WikiLinks } from "utils/Links";
import { COPILOT_MESSAGES } from "utils/Messages";

export const noDataForFilterMessageWithLink = (
    <div>
        {COPILOT_MESSAGES.INTENT_ANALYSIS_NO_DATA_FOR_FILTER +
            " For more information on our SLAs please see our wiki: "}
        <a href={WikiLinks.Intents} target="_blank">
            Intent Classification Wiki
        </a>
    </div>
);

export const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { Name, TriedCount, TriedRate, KeptRate } = payload[0].payload;
        return (
            <div
                style={{
                    backgroundColor: "white",
                    opacity: 0.9,
                    padding: "8px",
                    border: "1px solid LightGray",
                    fontSize: "1em",
                }}
            >
                <b>{Name}</b>
                <p>Tried Count: {TriedCount}</p>
                <p>% of Total Tries: {TriedRate}%</p>
                <p>KeptRate: {KeptRate}%</p>
            </div>
        );
    }
    return null;
};

export const getScorecardColumns = (
    sortedColumnKey?: string,
    satMetric?: string,
) => {
    const columns: IColumn[] = [];
    IntentAnalysisScorecardColumns.forEach((column) => {
        columns.push({
            key: column.name,
            name: (column.name == "Intent" ? (
                <>
                    {column.displayName}
                    {column.tooltip && (
                        <TooltipHost content={column.tooltip}>
                            <a href={WikiLinks.Intents} target="_blank">
                                <Icon
                                    iconName="info"
                                    className="tableColumnDefintion"
                                />
                            </a>
                        </TooltipHost>
                    )}
                </>
            ) : (
                column.displayName
            )) as any,
            fieldName: column.name,
            minWidth: column.minWidth ?? 120,
            maxWidth: column.minWidth ?? 120,
            ariaLabel: column.displayName,
            isResizable: true,
            headerClassName: `detailsListColumnHeaderWrap ${
                column.className ?? "alignLeftHeader"
            }`,
            isMultiline: false,
            isSorted: sortedColumnKey === column.name,
            isSortedDescending: true,
        });
    });
    let satMetricColumn = IntentAnalysisSatMetricColumns.find(
        (column) => column.name == satMetric,
    );
    if (satMetric && satMetricColumn !== null) {
        columns.push({
            key: satMetricColumn.name,
            name: satMetricColumn.displayName,
            fieldName: satMetricColumn.name,
            minWidth: satMetricColumn.minWidth ?? 120,
            maxWidth: satMetricColumn.minWidth ?? 120,
            ariaLabel: satMetricColumn.displayName,
            isResizable: true,
            headerClassName: `detailsListColumnHeaderWrap ${
                satMetricColumn.className ?? "alignLeftHeader"
            }`,
            isMultiline: false,
            isSorted: sortedColumnKey === satMetricColumn.name,
            isSortedDescending: true,
        });
    }

    columns.push({
        key: "Dummy",
        name: "",
        fieldName: "Dummy",
        minWidth: 10,
    });
    return columns;
};
