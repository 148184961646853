import React from "react";
import { CSVLink } from "react-csv";
import { CommandBarButton } from "@fluentui/react/lib/Button";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import { sendAIScorecardUserActionTelemetryEvent } from "components/AIScorecardComponents/AIScorecardTelemetryHelper";

export const ExportLink = ({ data, filename = "exportedData.csv" }) => {
    return (
        <CSVLink
            data={data}
            filename={filename}
            headers={Object.keys(data[0] ?? {})}
            onClick={() => {
                sendAIScorecardUserActionTelemetryEvent(
                    {
                        userActionName: "ExportButtonClick",
                        commandSurface: UserActionCommandSurface.Canvas,
                    },
                    { ExportedDataFileName: filename },
                );
            }}
        >
            <CommandBarButton
                iconProps={{
                    iconName: "Download",
                }}
                styles={{
                    root: {
                        height: 30,
                        backgroundColor: "unset",
                    },
                }}
                text="Export"
                title="Export data"
            />
        </CSVLink>
    );
};
