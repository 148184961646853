import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { togglingLineChartKind } from "components/CustomComponents/TogglingLineChart/TogglingLineChartKind";
import {
    CommonTenantEnvironment,
    GeographicDistributionWrapper,
    MonthlyTenantEnvironmentChart,
} from "components/TenantDrilldownComponents/TenantEnvironment";
import { Workload } from "config/PlatformConfig";
import {
    commonPageBlock,
    equalStackItemsStyle,
    horizontalStackTokens,
} from "pages/common";
import { getProductDrilldownTenantEnvironmentData } from "pages/Copilot/ApiHandler";
import {
    CopilotProductDrilldownFilterState,
    CopilotProductDrilldownTenantEnvironmentProps,
    CopilotProductDrilldownTenantEnvironmentState,
} from "pages/Copilot/types";
import { logException } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";

const initialState: CopilotProductDrilldownTenantEnvironmentState = {
    licenseDistribution: {},
    dau: {},
    wau: {},
    appCurrency: {},
    geographicDistribution: {
        data: {},
        geoMapping: undefined,
    },
    officeDiagnosticConsentLevel: {},
    loading: true,
};

export const CopilotProductDrilldownTenantEnvironment = ({
    filters,
}: CopilotProductDrilldownTenantEnvironmentProps) => {
    const [state, setState] =
        useState<CopilotProductDrilldownTenantEnvironmentState>(initialState);

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const getPayloadData = async (
            payloadFilters: Partial<CopilotProductDrilldownFilterState>,
        ) => {
            setState((oldState) => ({
                ...oldState,
                loading: true,
            }));

            try {
                const tenantEnvironmentData =
                    await getProductDrilldownTenantEnvironmentData(payloadFilters);

                setState((oldState) => ({
                    ...oldState,
                    ...tenantEnvironmentData,
                    loading: false,
                }));
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotProductDrilldownTenantEnvironmentProcessingFailed",
                    ),
                    {
                        message:
                            "Failure processing response data from Copilot Product drilldown Tenant Environment query",
                    },
                    error,
                );
                setState((oldState) => {
                    return { ...oldState, loading: false };
                });

                setError(error.message);
            }
        };

        if (filters.date !== null) {
            getPayloadData({
                date: filters.date,
                id: filters.id,
                platform: filters.platform,
                level: filters.level,
            });
        }
    }, [filters.date, filters.id, filters.platform, filters.level]);

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <Stack styles={commonPageBlock}>
            <Stack horizontal tokens={horizontalStackTokens}>
                <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                    <MonthlyTenantEnvironmentChart
                        displayName="M365 Licenses"
                        payload={state.licenseDistribution}
                        kind={togglingLineChartKind.percentagesWithValues}
                        yLabel="% Licenses"
                        loading={state.loading}
                    />
                </Stack.Item>
                <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                    <MonthlyTenantEnvironmentChart
                        displayName="App Currency"
                        payload={state.appCurrency}
                        kind={togglingLineChartKind.percentagesWithValues}
                        yLabel="% Devices"
                        loading={state.loading}
                    />
                </Stack.Item>
            </Stack>
            <Stack horizontal tokens={horizontalStackTokens}>
                <Stack.Item grow="initial" styles={equalStackItemsStyle}>
                    <MonthlyTenantEnvironmentChart
                        displayName="Office Diagnostic Consent Level"
                        payload={state.officeDiagnosticConsentLevel}
                        kind={togglingLineChartKind.valuesWithPercentages}
                        yLabel="% Devices"
                        loading={state.loading}
                    />
                </Stack.Item>
            </Stack>
            <CommonTenantEnvironment
                filters={filters}
                selectedDate={filters.date}
                platform={Workload.COPILOT_COMMERCIAL}
            />
            <div className="break" />
            <Stack>
                <GeographicDistributionWrapper
                    geographicDistribution={state.geographicDistribution.data}
                    geoDataMapping={state.geographicDistribution.geoMapping}
                    loading={state.loading}
                    rankOn={filters.application}
                    workload={Workload.COPILOT_COMMERCIAL}
                />
            </Stack>
        </Stack>
    );
};
