export const copilotReadinessScore: string = `
    // Copilot readiness Score
    let dt = iif(isempty("\${date}"), GetLatestDate(), "\${date}");
    let app = "\${application}";
    let levelName = "\${level}";
    let minLicense = \${mau};
    let currentDate = GetLatestDate();
    let PastStatusSet = ReadinessScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | project \${levelColumnName}, Date, Readiness_Color
    | order by Date asc
    | summarize PastStatus = make_list(Readiness_Color) by \${levelColumnName};
    ReadinessScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == dt and CoPilot_Licenses > minLicense
    | join kind=inner
        (database('TenantInsights').StrategicTenants
            | where Date == currentDate
            | extend IsS500_S2500 = IsS500 or IsS2500
            | where Is\${cohort} == true
            | distinct \${levelColumnName}, iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name = Column1
        ) on \${levelColumnName}
    | join kind = leftouter
        (
            PastStatusSet
        ) on \${levelColumnName}
    | project PastStatus, \${level} = \${levelColumnName}, OrgName = Name,
        Readiness_Score = round(Readiness_Score,2), Readiness_Score_Color = Readiness_Color, Status = Readiness_Color,
        EligibleLicenses_Score = Eligible_Licenses,
        CopilotLicenses_Score = CoPilot_Licenses,
        CopilotEnabled_Score = CoPilot_Enabled,
        CopilotEnabledPct_Score = Copilot_Enabled_pct, CopilotEnabledPct_Score_Color = Enabled_Color,
        MIPEnabledPct_Score = round(MIPEnabled_Pct,2), MIPEnabledPct_Score_Color = DataProtec_Color,
        SPO_Labelling_Text = SPO_Labelling, Collab_Protection_Text =  Collab_Protection,
        OneDrivePct_Score = round(OneDrive_Pct,2), OneDrivePct_Score_Color = OneDrive_Color,
        FbkAndLogsONPct_Score = round(FbkAndLogsON_pct,2), FbkAndLogsONPct_Score_Color = FbkAndLogsON_Color,
        FbkSurveyONPct_Score = round(Fdbck_SurveyON_pct,2), FbkSurveyONPct_Score_Color = FdbkSurvey_Color,
        ODDRate_Score = round(ODD_Diagnostics_Rate,2), ODDRate_Score_Color = OffDiagnostic_Color,
        ConnectedExperienceRate_Score = round(ConnectedExperience_Rate,2), ConnectedExperienceRate_Score_Color = ConnectedExp_Color,
        ChannelReadinessRate_Score = round(ChannelReadiness_Rate,2), ChannelReadinessRate_Score_Color = ChannelReadiness_Color
    | sort by Readiness_Score desc
`;

export const copilotDateOptions: string = `
    // Date options
    ReadinessScore
    | distinct Date
    | project Date
    | order by Date desc
    `;

export const copilotHealthScoreM365Chat: string = `
set query_results_cache_max_age = time(12h);
let dt = iif(isempty("\${date}"), GetLatestDate(), "\${date}");
let currentDate = GetLatestDate();
let levelName = "\${level}";
let endpoint = "\${endpoint}";
let minLicense = \${mau};
let HealthScore_M365Chat = HealthScore_M365Chat
| where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Endpoint == "All"
| join kind=inner (
    database('TenantInsights').StrategicTenants
    | where Date == currentDate
    | extend IsS500_S2500 = IsS500 or IsS2500
    | where Is\${cohort} == true
    | distinct \${levelColumnName}, OrgName = iif(levelName == "Tpid", OrgName_Translated, TenantName_Translated)
    | project \${levelColumnName}, OrgName
) on OmsTenantId
| extend OrgName;
let HealthScore_M365ChatUnpivoted = HealthScore_M365Chat
| where todouble(CoPilot_Licenses) > minLicense;
let PastStatusSet = HealthScore_M365ChatUnpivoted
| project OmsTenantId, Date, M365Chat_ColorCode
| order by Date asc
| summarize PastStatus = make_list(M365Chat_ColorCode) by OmsTenantId;
HealthScore_M365ChatUnpivoted
| where Date == dt 
| join kind=leftouter (
    PastStatusSet
) on \${levelColumnName}
| project PastStatus, TenantId = OmsTenantId, OrgName,
    Health_Score = round(todouble(M365Chat_Score), 2), Health_Score_Color = M365Chat_ColorCode, Status = M365Chat_ColorCode,
    MAU_Score = Mau,
    MAURate_Score = iif(MAURate == -1, "NA", strcat(tostring(round(todouble(MAURate), 2)), "%")), MAURate_Score_Color = MAURate_ColorCode,
    Availability_Score = round(todouble(AvailabilityRate), 2), Availability_Score_Color = Availability_ColorCode,
    FCFR_P75_Score = round(todouble(FCFR_P75), 2), FCFR_P75_Score_Color = FCFR_ColorCode,
    LCFR_P75_Score = round(todouble(LCFR_P75), 2), LCFR_P75_Score_Color = LCFR_ColorCode,   
    QPUU_Score = round(todouble(QueryCountPerUniqueUser), 2), QPUU_Score_Color = Qry_pUser_ColorCode,
    M1Retention_Score = round(todouble(RetentionRate), 2), M1Retention_Score_Color = Retention_ColorCode,    
    CitationRate_Score = round(todouble(CitationRate), 2), CitationRate_Score_Color = Citation_ColorCode,
    SATRate_Score = round(todouble(SATRate), 2), SATRate_Score_Color = SATRate_ColorCode    
| sort by Health_Score desc;
`;

export const copilotHealthScore: string = `
    // Health Score
    set query_results_cache_max_age = time(12h);
    let dt = iif(isempty("\${date}"), GetLatestDate(), "\${date}");
    let currentDate = GetLatestDate();
    let app = "\${application}";
    let levelName = "\${level}";
    let plat = "\${platform}";
    let minLicense = \${mau};
    let PastStatusSet = HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Application == app and Platform == plat
    | project \${levelColumnName}, Date, Health_Color
    | order by Date asc
    | summarize PastStatus = make_list(Health_Color) by \${levelColumnName};
    HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Application == app and Platform == plat
    | where CoPilot_Licenses > minLicense and Date == dt
    | join kind=inner
        (database('TenantInsights').StrategicTenants
            | where Date == currentDate
            | extend IsS500_S2500 = IsS500 or IsS2500
            | where Is\${cohort} == true
            | distinct \${levelColumnName}, Name = iif(levelName == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name
        ) on \${levelColumnName}
    | join kind = leftouter
        (
            PastStatusSet
        ) on \${levelColumnName}
    | extend ASHA_Overall = parse_json(ASHA_Overall)
    | extend ASHA_Overall_Color = parse_json(ASHA_Overall_Color)
    | extend ASHA_Performance = parse_json(ASHA_Performance)
    | extend ASHA_Performance_Color = parse_json(ASHA_Performance_Color)
    | extend ThumbsDown_p100k = parse_json(ThumbsDown_p100k)
    | extend ThumbsDown_p100k_Color = parse_json(ThbDw_100k_Color)
    | extend M1Retention_Pct = parse_json(M1Retention_Pct)
    | extend M1Retention_Color = parse_json(M1Retention_Color)
    | extend SeenRate = parse_json(SeenRate)
    | extend SeenRate_Color = parse_json(SeenRate_Color)
    | extend KeepRate = parse_json(KeepRate)
    | extend KeepRate_Color = parse_json(KeepRate_Color)
    | extend TriedRate = parse_json(TriedRate)
    | extend TriedRate_Color = parse_json(TriedRate_Color)
    | extend Intensity = parse_json(Intensity)
    | extend Intensity_Color = parse_json(Intensity_Color)
    | extend MTE_Weighted_Avg = parse_json(MTE_Weighted_Avg)
    | extend MTE_Color = parse_json(MTE_Color)
    | extend Performance_FCFR_P50 = parse_json(Performance_FCFR_P50)
    | extend FCFR_P50_Color = parse_json(FCFR_P50_Color)
    | extend Performance_FCFR_P95 = parse_json(Performance_FCFR_P95)
    | extend FCFR_P95_Color = parse_json(FCFR_P95_Color)
    | extend RAI_Blocked_pct = parse_json(RAI_Blocked_pct)
    | extend RAI_Blocked_Color = parse_json(RAI_Blocked_Color)
    | extend Currency_Pct = parse_json(Currency_Pct)
    | extend Currency_Color = parse_json(Currency_Color)
    | extend NPS_NetPromoterScore = parse_json(NPS_NetPromoterScore)
    | extend NPS_Color = parse_json(NPS_Color)
    | project PastStatus, TenantId = OmsTenantId , OrgName = Name,
        Health_Score = round(Health_Score,2), Health_Score_Color = Health_Color, Status = Health_Color,
        MAU_Score = MAU,
        ASHA_Score = round(100-todouble(ASHA_Overall[app]), 2), ASHA_Score_Color = ASHA_Overall_Color[app],
        Performance_Score = round(todouble(ASHA_Performance[app]), 2), Performance_Score_Color = ASHA_Performance_Color[app],
        FCFRP50_Score = round(todouble(Performance_FCFR_P50[app]), 2), FCFRP50_Score_Color = FCFR_P50_Color[app],
        FCFRP95_Score = round(todouble(Performance_FCFR_P95[app]), 2), FCFRP95_Score_Color = FCFR_P95_Color[app],
        // RAIBlockRate_Score = round(todouble(RAI_Blocked_pct[app]), 2), RAIBlockRate_Score_Color = RAI_Blocked_Color[app],
        Currency_Score = round(todouble(Currency_Pct[app]), 2), Currency_Score_Color = Currency_Color[app],
        SeenRate_Score = round(todouble(SeenRate[app]), 2), SeenRate_Score_Color = SeenRate_Color[app],
        TriedRate_Score = round(todouble(TriedRate[app]), 2), TriedRate_Score_Color = TriedRate_Color[app],
        KeepRate_Score = round(todouble(KeepRate[app]), 2), KeepRate_Score_Color = KeepRate_Color[app],
        M1Retention_Score = round(todouble(M1Retention_Pct[app]), 2), M1Retention_Score_Color = M1Retention_Color[app],
        Intensity_Score = round(todouble(Intensity[app]), 2), Intensity_Score_Color = Intensity_Color[app],
        SATPct_Score = round(todouble(ThumbsDown_p100k[app]), 2), SATPct_Score_Color = ThumbsDown_p100k_Color[app],
        MTE_Score = round(todouble(MTE_Weighted_Avg[app]), 2), MTE_Score_Color = MTE_Color[app],
        NPS_Score = round(todouble(NPS_NetPromoterScore[app]), 2), NPS_Score_Color = NPS_Color[app]
    | sort by Health_Score desc
    `;

export const copilotHealthScoreWXP: string = `
    // Health Score WXP
    set query_results_cache_max_age = time(12h);
    let dt = iif(isempty("\${date}"), GetLatestDate(), "\${date}");
    let currentDate = GetLatestDate();
    let app = "\${application}";
    let levelName = "\${level}";
    let plat = "\${platform}";
    let minLicense = \${mau};
    let wordAppName = "Word";
    let excelAppName = "Excel";
    let pptAppname = "PowerPoint";
    let PastStatusSet = HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Application == app and Platform == plat
    | project \${levelColumnName}, Date, Health_Color
    | order by Date asc
    | summarize PastStatus = make_list(Health_Color) by \${levelColumnName};
    HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Application == app and Platform == plat
    | where CoPilot_Licenses > minLicense and Date == dt 
    | join kind=inner 
        (database('TenantInsights').StrategicTenants
            | where Date == currentDate
            | extend IsS500_S2500 = IsS500 or IsS2500
            | where Is\${cohort} == true
            | distinct \${levelColumnName}, Name = iif(levelName == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name
        ) on \${levelColumnName}
    | join kind = leftouter
        (
            PastStatusSet
        ) on \${levelColumnName}
    | extend ASHA_Overall_Color = parse_json(ASHA_Overall_Color)
    | extend ASHA_Performance_Color = parse_json(ASHA_Performance_Color)
    | extend ThumbsDown_p100k_Color = parse_json(ThbDw_100k_Color)
    | extend M1Retention_Color = parse_json(M1Retention_Color)
    | extend SeenRate_Color = parse_json(SeenRate_Color)
    | extend KeepRate_Color = parse_json(KeepRate_Color)
    | extend TriedRate_Color = parse_json(TriedRate_Color)
    | extend Intensity_Color = parse_json(Intensity_Color)
    | extend MTE_Color = parse_json(MTE_Color)
    | extend FCFR_P50_Color = parse_json(FCFR_P50_Color)
    | extend FCFR_P95_Color = parse_json(FCFR_P95_Color)
    | extend RAI_Blocked_Color = parse_json(RAI_Blocked_Color)
    | extend Currency_Color = parse_json(Currency_Color)
    | extend NPS_Color = parse_json(NPS_Color)
    | project PastStatus, TenantId = OmsTenantId , OrgName = Name, 
        Health_Score = round(Health_Score,2), Health_Score_Color = Health_Color, Status = Health_Color,
        MAU_Score = MAU,
        Word_ASHA_AppColor = ASHA_Overall_Color[wordAppName], Excel_ASHA_AppColor = ASHA_Overall_Color[excelAppName], PowerPoint_ASHA_AppColor =  ASHA_Overall_Color[pptAppname],
        Word_Performance_AppColor = ASHA_Performance_Color[wordAppName], Excel_Performance_AppColor = ASHA_Performance_Color[excelAppName], PowerPoint_Performance_AppColor =  ASHA_Performance_Color[pptAppname],
        Word_FCFR_AppColor = FCFR_P50_Color[wordAppName], Excel_FCFR_AppColor = FCFR_P50_Color[excelAppName], PowerPoint_FCFR_AppColor =  FCFR_P50_Color[pptAppname],
        Word_Currency_AppColor = Currency_Color[wordAppName], Excel_Currency_AppColor = Currency_Color[excelAppName], PowerPoint_Currency_AppColor =  Currency_Color[pptAppname],
        Word_SeenRate_AppColor = SeenRate_Color[wordAppName], Excel_SeenRate_AppColor = SeenRate_Color[excelAppName], PowerPoint_SeenRate_AppColor =  SeenRate_Color[pptAppname],
        Word_TriedRate_AppColor = TriedRate_Color[wordAppName], Excel_TriedRate_AppColor = TriedRate_Color[excelAppName], PowerPoint_TriedRate_AppColor =  TriedRate_Color[pptAppname],
        Word_KeepRate_AppColor = KeepRate_Color[wordAppName], Excel_KeepRate_AppColor = KeepRate_Color[excelAppName], PowerPoint_KeepRate_AppColor =  KeepRate_Color[pptAppname],
        Word_M1Retention_AppColor = M1Retention_Color[wordAppName], Excel_M1Retention_AppColor = M1Retention_Color[excelAppName], PowerPoint_M1Retention_AppColor =  M1Retention_Color[pptAppname],
        Word_Intensity_AppColor = Intensity_Color[wordAppName], Excel_Intensity_AppColor = Intensity_Color[excelAppName], PowerPoint_Intensity_AppColor =  Intensity_Color[pptAppname],
        Word_SATPct_AppColor = ThumbsDown_p100k_Color[wordAppName], Excel_SATPct_AppColor = ThumbsDown_p100k_Color[excelAppName], PowerPoint_SATPct_AppColor =  ThumbsDown_p100k_Color[pptAppname],
        Word_MTE_AppColor = MTE_Color[wordAppName], Excel_MTE_AppColor = MTE_Color[excelAppName], PowerPoint_MTE_AppColor =  MTE_Color[pptAppname],
        Word_NPS_AppColor = NPS_Color[wordAppName], Excel_NPS_AppColor = NPS_Color[excelAppName], PowerPoint_NPS_AppColor =  NPS_Color[pptAppname]
    | sort by Health_Score desc
    `;

export const copilotReadinessHealthDistribution: string = `
    // Copilot Readiness Health Distribution
    let dt = "\${date}";
    let currentDate = GetLatestDate();
    let app = "\${application}";
    let levelName = "\${level}";
    let minLicense = \${mau};
    let cohort = "\${cohort}";
    let platform = "\${platform}";
    ReadinessScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where CoPilot_Licenses > minLicense
    | join kind=inner
        (database('TenantInsights').StrategicTenants
            | where Date == currentDate
            | extend IsS500_S2500 = IsS500 or IsS2500
            | where Is\${cohort} == true
            | distinct \${levelColumnName}, Name = iif( levelName  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name
        ) on \${levelColumnName}
    | summarize Count = count() by Readiness_Color, Date
    | extend Current = (Date == dt)
    | project Date, Status = Readiness_Color, Count, Current
    | order by Date asc, Status asc
`;

export const copilotHealthHealthDistribution: string = `
    // Copilot Health Health Distribution
    let dt = "\${date}";
    let currentDate = GetLatestDate();
    let app = "\${application}";
    let levelName = "\${level}";
    let cohort = "\${cohort}";
    let plat = "\${platform}";
    let minLicense = \${mau};
    HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Application == app and Platform == plat
    | where CoPilot_Licenses > minLicense
    | join kind=inner
        (database('TenantInsights').StrategicTenants
            | where Date == currentDate
            | extend IsS500_S2500 = IsS500 or IsS2500
            | where Is\${cohort} == true
            | distinct \${levelColumnName}, Name = iif(levelName == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName},  Name
        ) on \${levelColumnName}
    | summarize Count = count() by Health_Color, Date
    | extend Current = (Date == dt)
    | project Date, Status = Health_Color, Count, Current
    | order by Date asc, Status asc
`;

export const copilotHealthHealthDistributionM365Chat: string = `
    // Copilot Health Health Distribution
    let dt = "\${date}";
    let currentDate = GetLatestDate();
    let app = "\${application}";
    let levelName = "\${level}";
    let cohort = "\${cohort}";
    let minLicense = \${mau};
    let HealthScore_M365ChatUnpivoted = HealthScore_M365Chat
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Endpoint == "All"
    | where todouble(CoPilot_Licenses) > minLicense;
    HealthScore_M365ChatUnpivoted
    | join kind=inner
        (database('TenantInsights').StrategicTenants
            | where Date == currentDate
            | extend IsS500_S2500 = IsS500 or IsS2500
            | where Is\${cohort} == true
            | distinct \${levelColumnName}, Name = iif(levelName == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName},  Name
        ) on \${levelColumnName}
    | summarize Count = count() by M365Chat_ColorCode, Date
    | extend Current = (Date == dt)
    | project Date, Status = M365Chat_ColorCode, Count, Current
    | order by Date asc, Status asc;
`;

export const copilotReadinessTenantStatus: string = `
    // Copilot readiness movers/shakers
    let dt = iif(isempty("\${date}"), GetLatestDate(), "\${date}");
    let currentDate = GetLatestDate();
    let app = "\${application}";
    let levelName = "\${level}";
    let minLicense = \${mau};
    let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
    let PastStatusSet = ReadinessScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | project \${levelColumnName}, Date, Readiness_Color
    | order by Date asc
    | summarize PastStatus = make_list(Readiness_Color) by \${levelColumnName};
    ReadinessScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Date == dt and CoPilot_Licenses > minLicense
    | extend CurrentStatus = Readiness_Color
    | join kind=leftouter (
        ReadinessScore
        | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Date == prev
        | extend PreviousStatus = Readiness_Color
    ) on \${levelColumnName}
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | extend MoM_Score = round(Readiness_Score-Readiness_Score1,2)
    | extend MoM_CopilotOneDriveScore = round(OneDrive_Pct-OneDrive_Pct1,2)
    | extend MoM_CopilotMIPEnabledScore = round(MIPEnabled_Pct-MIPEnabled_Pct1,2)
    | extend MoM_CopilotEnabledScore = round(Copilot_Enabled_pct-Copilot_Enabled_pct1,2)
    | extend MoM_FbkAndLogsONPctScore = round(FbkAndLogsON_pct-FbkAndLogsON_pct1,2)
    | extend MoM_FbkSurveyONPctScore = round(Fdbck_SurveyON_pct-Fdbck_SurveyON_pct1,2)
    | extend MoM_ODDRateScore = round(ODD_Diagnostics_Rate-ODD_Diagnostics_Rate1,2)
    | extend MoM_ConnectedExperienceRateScore = round(ConnectedExperience_Rate-ConnectedExperience_Rate1,2)
    | extend MoM_ChannelReadinessRateScore = round(ChannelReadiness_Rate-ChannelReadiness_Rate1,2)
    | join kind=inner
        (database('TenantInsights').StrategicTenants
            | where Date == currentDate
            | extend IsS500_S2500 = IsS500 or IsS2500
            | where Is\${cohort} == true
            | distinct \${levelColumnName}, Name = iif(levelName  == "Tpid", OrgName_Translated, TenantName_Translated)
            | project \${levelColumnName}, Name
        ) on \${levelColumnName}
    | join kind = leftouter
        (
            PastStatusSet
        ) on \${levelColumnName}
    | project \${level} = \${levelColumnName}, OrgName = Name,
        Readiness_Score = round(Readiness_Score,2), Readiness_Score_Color = Readiness_Color, Readiness_Score_MoMChange = MoM_Score,
        EligibleLicenses_Score = Eligible_Licenses,
        CopilotEnabled_Score = CoPilot_Enabled,
        CopilotLicenses_Score = CoPilot_Licenses,
        CopilotEnabledPct_Score = Copilot_Enabled_pct, CopilotEnabledPct_Score_Color = Enabled_Color, CopilotEnabledPct_Score_MoMChange = MoM_CopilotEnabledScore,
        MIPEnabledPct_Score = round(MIPEnabled_Pct,2), MIPEnabledPct_Score_Color = DataProtec_Color, MIPEnabledPct_Score_MoMChange = MoM_CopilotMIPEnabledScore,
        SPO_Labelling_Text = SPO_Labelling, Collab_Protection_Text =  Collab_Protection,
        OneDrivePct_Score = round(OneDrive_Pct,2), OneDrivePct_Score_Color = OneDrive_Color, OneDrivePct_Score_MoMChange = MoM_CopilotOneDriveScore,
        FbkAndLogsONPct_Score = round(FbkAndLogsON_pct,2), FbkAndLogsONPct_Score_Color = FbkAndLogsON_Color, FbkAndLogsONPct_Score_MoMChange = MoM_FbkAndLogsONPctScore,
        FbkSurveyONPct_Score = round(Fdbck_SurveyON_pct,2), FbkSurveyONPct_Score_Color = FdbkSurvey_Color, FbkSurveyONPct_Score_MoMChange = MoM_FbkSurveyONPctScore,
        ODDRate_Score = round(ODD_Diagnostics_Rate,2), ODDRate_Score_Color = OffDiagnostic_Color, ODDRate_Score_MoMChange = MoM_ODDRateScore,
        ConnectedExperienceRate_Score = round(ConnectedExperience_Rate,2), ConnectedExperienceRate_Score_Color = ConnectedExp_Color, ConnectedExperienceRate_Score_MoMChange = MoM_ConnectedExperienceRateScore,
        ChannelReadinessRate_Score = round(ChannelReadiness_Rate,2), ChannelReadinessRate_Score_Color = ChannelReadiness_Color, ChannelReadinessRate_Score_MoMChange = MoM_ChannelReadinessRateScore,
        PastStatus, CurrentStatus, PreviousStatus, Parent = Tpid
    | sort by Readiness_Score desc
`;

export const copilotHealthTenantStatusM365Chat: string = `
set query_results_cache_max_age = time(12h);
let dt = iif(isempty("\${date}"), GetLatestDate(), "\${date}");
let currentDate = GetLatestDate();
let levelName = "\${level}";
let endpoint = "\${endpoint}";
let minLicense = \${mau};
let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
let HealthScore_M365Chat = HealthScore_M365Chat
| where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
| where Endpoint == "All"
| join kind=inner (
    database('TenantInsights').StrategicTenants
    | where Date == currentDate
    | extend IsS500_S2500 = IsS500 or IsS2500
    | where Is\${cohort} == true
    | distinct \${levelColumnName}, OrgName = iif(levelName == "Tpid", OrgName_Translated, TenantName_Translated)
    | project \${levelColumnName}, OrgName
) on OmsTenantId
| extend OrgName;
let HealthScore_M365ChatUnpivoted = HealthScore_M365Chat
| where todouble(CoPilot_Licenses) > minLicense;
let PastStatusSet = HealthScore_M365ChatUnpivoted
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | project \${levelColumnName}, Date, M365Chat_ColorCode
    | order by Date asc
    | summarize PastStatus = make_list(M365Chat_ColorCode) by \${levelColumnName};
HealthScore_M365ChatUnpivoted
| where Date == dt
| project CurrentStatus = M365Chat_ColorCode,OmsTenantId, OrgName,
    Health_Score = round(todouble(M365Chat_Score), 2), Health_Score_Color = M365Chat_ColorCode, Status = M365Chat_ColorCode,
    CurrentMonthMAU = round(todouble(Mau), 2),
    MAURate_Score = round(todouble(MAURate), 2), MAURate_Score_Color = MAURate_ColorCode,
    Availability_Score = round(todouble(AvailabilityRate), 2), Availability_Score_Color = Availability_ColorCode,
    FCFR_P75_Score = round(todouble(FCFR_P75), 2), FCFR_P75_Score_Color = FCFR_ColorCode,
    LCFR_P75_Score = round(todouble(LCFR_P75), 2), LCFR_P75_Score_Color = LCFR_ColorCode,   
    QPUU_Score = round(todouble(QueryCountPerUniqueUser), 2), QPUU_Score_Color = Qry_pUser_ColorCode,
    M1Retention_Score = round(todouble(RetentionRate), 2), M1Retention_Score_Color = Retention_ColorCode,    
    CitationRate_Score = round(todouble(CitationRate), 2), CitationRate_Score_Color = Citation_ColorCode,
    SATRate_Score = round(todouble(SATRate), 2), SATRate_Score_Color = SATRate_ColorCode   
| join kind=leftouter (
    HealthScore_M365ChatUnpivoted
| where Date == prev
| project PreviousStatus= M365Chat_ColorCode,OmsTenantId, OrgName,
    Health_Score = round(todouble(M365Chat_Score), 2), Health_Score_Color = M365Chat_ColorCode, Status = M365Chat_ColorCode,
    PreviousMonthMAU= round(todouble(Mau), 2),
    MAURate_Score = round(todouble(MAURate), 2), MAURate_Score_Color = MAURate_ColorCode,
    Availability_Score = round(todouble(AvailabilityRate), 2), Availability_Score_Color = Availability_ColorCode,
    FCFR_P75_Score = round(todouble(FCFR_P75), 2), FCFR_P75_Score_Color = FCFR_ColorCode,
    LCFR_P75_Score = round(todouble(LCFR_P75), 2), LCFR_P75_Score_Color = LCFR_ColorCode,   
    QPUU_Score = round(todouble(QueryCountPerUniqueUser), 2), QPUU_Score_Color = Qry_pUser_ColorCode,
    M1Retention_Score = round(todouble(RetentionRate), 2), M1Retention_Score_Color = Retention_ColorCode,    
    CitationRate_Score = round(todouble(CitationRate), 2), CitationRate_Score_Color = Citation_ColorCode,
    SATRate_Score = round(todouble(SATRate), 2), SATRate_Score_Color = SATRate_ColorCode 
) on \${levelColumnName}
| where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | extend MoM_Score = round(Health_Score-Health_Score1,2)
    | extend MoM_MAUScore = iif(PreviousMonthMAU != 0, round(todouble(CurrentMonthMAU - PreviousMonthMAU) / PreviousMonthMAU * 100, 2), 0.0)
    | extend MoM_MAURate =  round(MAURate_Score-MAURate_Score1,2)
    | extend MoM_Availability = round(Availability_Score-Availability_Score1,2)
    | extend MoM_M1Retention = round(M1Retention_Score-M1Retention_Score1,2)
    | extend MoM_FCFR_P75 = round(FCFR_P75_Score-FCFR_P75_Score1,2)
    | extend MoM_LCFR_P75 = round(LCFR_P75_Score-LCFR_P75_Score1,2)
    | extend MoM_QPUU = round(QPUU_Score-QPUU_Score1,2)
    | extend MoM_CitationRate = round(CitationRate_Score-CitationRate_Score1,2)
    | extend MoM_SATRate = round(SATRate_Score-SATRate_Score1,2)
| join kind = leftouter
        (
            PastStatusSet
        ) on \${levelColumnName}
|project \${level} = \${levelColumnName}, OrgName,
    Health_Score = round(todouble(Health_Score), 2), Health_Score_Color = Status, Health_Score_MoMChange = MoM_Score,
    MAU_Score = CurrentMonthMAU, MAU_Score_MoMChange = MoM_MAUScore,
    MAURate_Score = round(todouble(MAURate_Score), 2), MAURate_Score_Color,MAURate_Score_MoMChange = MoM_MAURate,
    Availability_Score = round(todouble(Availability_Score), 2), Availability_Score_Color,Availability_Score_MoMChange = MoM_Availability,
    FCFR_P75_Score = round(todouble(FCFR_P75_Score), 2), FCFR_P75_Score_Color, FCFR_P75_Score_MoMChange = MoM_FCFR_P75,
    LCFR_P75_Score = round(todouble(LCFR_P75_Score), 2), LCFR_P75_Score_Color,   LCFR_P75_Score_MoMChange = MoM_LCFR_P75,
    QPUU_Score = round(todouble(QPUU_Score), 2), QPUU_Score_Color, QPUU_Score_MoMChange = MoM_QPUU,
    M1Retention_Score = round(todouble(M1Retention_Score), 2), M1Retention_Score_Color,  M1Retention_Score_MoMChange = MoM_M1Retention,  
    CitationRate_Score = round(todouble(CitationRate_Score), 2), CitationRate_Score_Color ,CitationRate_Score_MoMChange = MoM_CitationRate,
    SATRate_Score = round(todouble(SATRate_Score), 2), SATRate_Score_Color, SATRate_Score_MoMChange = MoM_SATRate,
 PastStatus, CurrentStatus, PreviousStatus
    | sort by Health_Score desc 
    `;

export const copilotHealthTenantStatus: string = `
    // Copilot Health movers/shakers
    set query_results_cache_max_age = time(12h);
    let dt = iif(isempty("\${date}"), GetLatestDate(), "\${date}");
    let currentDate = GetLatestDate();
    let app = "\${application}";
    let levelName = "\${level}";
    let plat = "\${platform}";
    let minLicense = \${mau};
    let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
    let PastStatusSet = HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Application == app and Platform == plat
    | project \${levelColumnName}, Date, Health_Color
    | order by Date asc
    | summarize PastStatus = make_list(Health_Color) by \${levelColumnName};
    HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Application == app and Platform == plat
    | where Date == dt and CoPilot_Licenses > minLicense
    | extend CurrentStatus = Health_Color
    | extend ASHA_Overall = parse_json(ASHA_Overall)
    | extend ASHA_Overall_Color = parse_json(ASHA_Overall_Color)
    | extend ASHA_Performance = parse_json(ASHA_Performance)
    | extend ASHA_Performance_Color = parse_json(ASHA_Performance_Color)
    | extend ThumbsDown_p100k = parse_json(ThumbsDown_p100k)
    | extend ThumbsDown_p100k_Color = parse_json(ThbDw_100k_Color)
    | extend M1Retention_Pct = parse_json(M1Retention_Pct)
    | extend M1Retention_Color = parse_json(M1Retention_Color)
    | extend SeenRate = parse_json(SeenRate)
    | extend SeenRate_Color = parse_json(SeenRate_Color)
    | extend KeepRate = parse_json(KeepRate)
    | extend KeepRate_Color = parse_json(KeepRate_Color)
    | extend TriedRate = parse_json(TriedRate)
    | extend TriedRate_Color = parse_json(TriedRate_Color)
    | extend Intensity = parse_json(Intensity)
    | extend Intensity_Color = parse_json(Intensity_Color)
    | extend MTE_Weighted_Avg = parse_json(MTE_Weighted_Avg)
    | extend MTE_Color = parse_json(MTE_Color)
    | extend Performance_FCFR_P50 = parse_json(Performance_FCFR_P50)
    | extend FCFR_P50_Color = parse_json(FCFR_P50_Color)
    | extend Performance_FCFR_P95 = parse_json(Performance_FCFR_P95)
    | extend FCFR_P95_Color = parse_json(FCFR_P95_Color)
    | extend RAI_Blocked_pct = parse_json(RAI_Blocked_pct)
    | extend RAI_Blocked_Color = parse_json(RAI_Blocked_Color)
    | extend Currency_Pct = parse_json(Currency_Pct)
    | extend Currency_Color = parse_json(Currency_Color)
    | extend NPS_NetPromoterScore = parse_json(NPS_NetPromoterScore)
    | extend NPS_Color = parse_json(NPS_Color)
    | project OmsTenantId , CurrentStatus, CurrentMonthMAU = MAU,
            Health_Score = round(Health_Score,2), Health_Score_Color = Health_Color, Status = Health_Color,
            ASHA_Score = round(todouble(ASHA_Overall[app]), 2), ASHA_Score_Color = ASHA_Overall_Color[app],
            Performance_Score = round(todouble(ASHA_Performance[app]), 2), Performance_Score_Color = ASHA_Performance_Color[app],
            SATPct_Score = round(todouble(ThumbsDown_p100k[app]), 2), SATPct_Score_Color = ThumbsDown_p100k_Color[app],
            M1Retention_Score = round(todouble(M1Retention_Pct[app]), 2), M1Retention_Score_Color = M1Retention_Color[app],
            KeepRate_Score = round(todouble(KeepRate[app]), 2), KeepRate_Score_Color = KeepRate_Color[app],
            SeenRate_Score = round(todouble(SeenRate[app]), 2), SeenRate_Score_Color = SeenRate_Color[app],
            TriedRate_Score = round(todouble(TriedRate[app]), 2), TriedRate_Score_Color = TriedRate_Color[app],
            Intensity_Score = round(todouble(Intensity[app]), 2), Intensity_Score_Color = Intensity_Color[app],
            MTE_Score = round(todouble(MTE_Weighted_Avg[app]), 2), MTE_Score_Color = MTE_Color[app],
            FCFRP50_Score = round(todouble(Performance_FCFR_P50[app]), 2), FCFRP50_Score_Color = FCFR_P50_Color[app],
            FCFRP95_Score = round(todouble(Performance_FCFR_P95[app]), 2), FCFRP95_Score_Color = FCFR_P95_Color[app],
            RAIBlockRate_Score = round(todouble(RAI_Blocked_pct[app]), 2), RAIBlockRate_Score_Color = RAI_Blocked_Color[app],
            Currency_Score = round(todouble(Currency_Pct[app]), 2), Currency_Score_Color = Currency_Color[app],
            NPS_Score = round(todouble(NPS_NetPromoterScore[app]), 2), NPS_Score_Color = NPS_Color[app]
    | join kind=leftouter (
        HealthScore
        | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Application == app and Platform == plat
        | where Date == prev
        | extend PreviousStatus = Health_Color
        | extend ASHA_Overall = parse_json(ASHA_Overall)
        | extend ASHA_Performance = parse_json(ASHA_Performance)
        | extend ThumbsDown_p100k = parse_json(ThumbsDown_p100k)
        | extend M1Retention_Pct = parse_json(M1Retention_Pct)
        | extend SeenRate = parse_json(SeenRate)
        | extend KeepRate = parse_json(KeepRate)
        | extend TriedRate = parse_json(TriedRate)
        | extend Intensity = parse_json(Intensity)
        | extend MTE_Weighted_Avg = parse_json(MTE_Weighted_Avg)
        | extend Performance_FCFR_P50 = parse_json(Performance_FCFR_P50)
        | extend Performance_FCFR_P95 = parse_json(Performance_FCFR_P95)
        | extend RAI_Blocked_pct = parse_json(RAI_Blocked_pct)
        | extend Currency_Pct = parse_json(Currency_Pct)
        | extend NPS_NetPromoterScore = parse_json(NPS_NetPromoterScore)
        | project OmsTenantId, PreviousStatus, PreviousMonthMAU = MAU,
            Health_Score = round(Health_Score,2), 
            ASHA_Score = round(todouble(ASHA_Overall[app]), 2), 
            Performance_Score = round(todouble(ASHA_Performance[app]), 2), 
            SATPct_Score = round(todouble(ThumbsDown_p100k[app]), 2), 
            M1Retention_Score = round(todouble(M1Retention_Pct[app]), 2), 
            KeepRate_Score = round(todouble(KeepRate[app]), 2),
            SeenRate_Score = round(todouble(SeenRate[app]), 2),
            TriedRate_Score = round(todouble(TriedRate[app]), 2),
            Intensity_Score = round(todouble(Intensity[app]), 2),
            MTE_Score = round(todouble(MTE_Weighted_Avg[app]), 2),
            FCFRP50_Score = round(todouble(Performance_FCFR_P50[app]), 2),
            FCFRP95_Score = round(todouble(Performance_FCFR_P95[app]), 2), 
            RAIBlockRate_Score = round(todouble(RAI_Blocked_pct[app]), 2),
            Currency_Score = round(todouble(Currency_Pct[app]), 2),
            NPS_Score = round(todouble(NPS_NetPromoterScore[app]), 2)
    ) on \${levelColumnName}
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | extend MoM_Score = round(Health_Score-Health_Score1,2)
    | extend MoM_MAUScore = iif(PreviousMonthMAU != 0, round(todouble(CurrentMonthMAU - PreviousMonthMAU) / PreviousMonthMAU * 100, 2), 0.0)
    | extend MoM_AshaOverall = -1 * round(ASHA_Score-ASHA_Score1,2)
    | extend MoM_AshaPerf = round(Performance_Score-Performance_Score1,2)
    | extend MoM_M1Retention = round(M1Retention_Score-M1Retention_Score1,2)
    | extend MoM_KeepRate = round(KeepRate_Score-KeepRate_Score1,2)
    | extend MoM_SeenRate = round(SeenRate_Score-SeenRate_Score1,2)
    | extend MoM_TriedRate = round(TriedRate_Score-TriedRate_Score1,2)
    | extend MoM_Intensity = round(Intensity_Score-Intensity_Score1,2)
    | extend MoM_MTE = round(MTE_Score-MTE_Score1,2)
    | extend MoM_FCFRP50 = round(FCFRP50_Score-FCFRP50_Score1,2)
    | extend MoM_FCFRP95 = round(FCFRP95_Score-FCFRP95_Score1,2)
    | extend MoM_RAIBlockRate = round(RAIBlockRate_Score-RAIBlockRate_Score1,2)
    | extend MoM_Currency = round(Currency_Score-Currency_Score1,2)
    | extend MoM_NPS = round(NPS_Score-NPS_Score1,2)
    | extend MoM_SATPct = round(SATPct_Score-SATPct_Score1,2)
    | join kind=inner 
        (database('TenantInsights').StrategicTenants
            | where Date == currentDate
            | extend IsS500_S2500 = IsS500 or IsS2500
            | where Is\${cohort} == true
            | distinct \${levelColumnName}, Tpid, Name = iif(levelName  == "Tpid", OrgName_Translated, TenantName_Translated) 
            |  project \${levelColumnName}, Tpid, Name
        ) on \${levelColumnName}
    | join kind = leftouter
        (
            PastStatusSet
        ) on \${levelColumnName}
    | project \${level} = \${levelColumnName}, OrgName = Name,
        Health_Score = round(Health_Score,2), Health_Score_Color = Status, Health_Score_MoMChange = MoM_Score,
        MAU_Score = CurrentMonthMAU, MAU_Score_MoMChange = MoM_MAUScore,
        ASHA_Score = round(100-ASHA_Score,2), ASHA_Score_Color, ASHA_Score_MoMChange = MoM_AshaOverall,
        Performance_Score = round(Performance_Score,2), Performance_Score_Color, Performance_Score_MoMChange = MoM_AshaPerf,
        FCFRP50_Score = round(FCFRP50_Score,2), FCFRP50_Score_Color, FCFRP50_Score_MoMChange = MoM_FCFRP50,
        FCFRP95_Score = round(FCFRP95_Score,2), FCFRP95_Score_Color, FCFRP95_Score_MoMChange = MoM_FCFRP95,
        // RAIBlockRate_Score = round(RAIBlockRate_Score,2), RAIBlockRate_Score_Color, RAIBlockRate_Score_MoMChange = MoM_RAIBlockRate,
        Currency_Score = round(Currency_Score,2), Currency_Score_Color, Currency_Score_MoMChange = MoM_Currency,
        SeenRate_Score = round(SeenRate_Score,2), SeenRate_Score_Color, SeenRate_Score_MoMChange = MoM_SeenRate,
        TriedRate_Score = round(TriedRate_Score,2), TriedRate_Score_Color, TriedRate_Score_MoMChange = MoM_TriedRate,
        KeepRate_Score = round(KeepRate_Score,2), KeepRate_Score_Color , KeepRate_Score_MoMChange = MoM_KeepRate,
        M1Retention_Score = round(M1Retention_Score,2), M1Retention_Score_Color, M1Retention_Score_MoMChange = MoM_M1Retention,
        Intensity_Score = round(Intensity_Score,2), Intensity_Score_Color, Intensity_Score_MoMChange = MoM_Intensity,
        SATPct_Score = round(SATPct_Score,2), SATPct_Score_Color, SATPct_Score_MoMChange = MoM_SATPct,
        MTE_Score = round(MTE_Score,2), MTE_Score_Color, MTE_Score_MoMChange = MoM_MTE,
        NPS_Score = round(NPS_Score,2), NPS_Score_Color, NPS_Score_MoMChange = MoM_NPS,
        PastStatus, CurrentStatus, PreviousStatus, Parent = Tpid
    | sort by Health_Score desc
    `;

export const copilotHealthTenantStatusWXP: string = `
    // Copilot Health movers/shakers
    set query_results_cache_max_age = time(12h);
    let dt = iif(isempty("\${date}"), GetLatestDate(), "\${date}");
    let currentDate = GetLatestDate();
    let app = "\${application}";
    let levelName = "\${level}";
    let plat = "\${platform}";
    let minLicense = \${mau};
    let wordAppName = "Word";
    let excelAppName = "Excel";
    let pptAppname = "PowerPoint";
    let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
    let PastStatusSet = HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Application == app and Platform == plat
    | project \${levelColumnName}, Date, Health_Color
    | order by Date asc
    | summarize PastStatus = make_list(Health_Color) by \${levelColumnName};
    HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where Application == app and Platform == plat
    | where Date == dt and CoPilot_Licenses > minLicense
    | extend CurrentStatus = Health_Color
    | extend ASHA_Overall_Color = parse_json(ASHA_Overall_Color)
    | extend ASHA_Performance_Color = parse_json(ASHA_Performance_Color)
    | extend ThumbsDown_p100k_Color = parse_json(ThbDw_100k_Color)
    | extend M1Retention_Color = parse_json(M1Retention_Color)
    | extend SeenRate_Color = parse_json(SeenRate_Color)
    | extend KeepRate_Color = parse_json(KeepRate_Color)
    | extend TriedRate_Color = parse_json(TriedRate_Color)
    | extend Intensity_Color = parse_json(Intensity_Color)
    | extend MTE_Color = parse_json(MTE_Color)
    | extend FCFR_P50_Color = parse_json(FCFR_P50_Color)
    | extend FCFR_P95_Color = parse_json(FCFR_P95_Color)
    | extend RAI_Blocked_Color = parse_json(RAI_Blocked_Color)
    | extend Currency_Color = parse_json(Currency_Color)
    | extend NPS_Color = parse_json(NPS_Color)
    | project OmsTenantId , CurrentStatus, CurrentMonthMAU = MAU,
            Health_Score = round(Health_Score,2), Health_Score_Color = Health_Color, Status = Health_Color,
            Word_ASHA_AppColor = ASHA_Overall_Color[wordAppName], Excel_ASHA_AppColor = ASHA_Overall_Color[excelAppName], PowerPoint_ASHA_AppColor =  ASHA_Overall_Color[pptAppname],
            Word_Performance_AppColor = ASHA_Performance_Color[wordAppName], Excel_Performance_AppColor = ASHA_Performance_Color[excelAppName], PowerPoint_Performance_AppColor =  ASHA_Performance_Color[pptAppname],
            Word_FCFR_AppColor = FCFR_P50_Color[wordAppName], Excel_FCFR_AppColor = FCFR_P50_Color[excelAppName], PowerPoint_FCFR_AppColor =  FCFR_P50_Color[pptAppname],
            Word_Currency_AppColor = Currency_Color[wordAppName], Excel_Currency_AppColor = Currency_Color[excelAppName], PowerPoint_Currency_AppColor =  Currency_Color[pptAppname],
            Word_SeenRate_AppColor = SeenRate_Color[wordAppName], Excel_SeenRate_AppColor = SeenRate_Color[excelAppName], PowerPoint_SeenRate_AppColor =  SeenRate_Color[pptAppname],
            Word_TriedRate_AppColor = TriedRate_Color[wordAppName], Excel_TriedRate_AppColor = TriedRate_Color[excelAppName], PowerPoint_TriedRate_AppColor =  TriedRate_Color[pptAppname],
            Word_KeepRate_AppColor = KeepRate_Color[wordAppName], Excel_KeepRate_AppColor = KeepRate_Color[excelAppName], PowerPoint_KeepRate_AppColor =  KeepRate_Color[pptAppname],
            Word_M1Retention_AppColor = M1Retention_Color[wordAppName], Excel_M1Retention_AppColor = M1Retention_Color[excelAppName], PowerPoint_M1Retention_AppColor =  M1Retention_Color[pptAppname],
            Word_Intensity_AppColor = Intensity_Color[wordAppName], Excel_Intensity_AppColor = Intensity_Color[excelAppName], PowerPoint_Intensity_AppColor =  Intensity_Color[pptAppname],
            Word_SATPct_AppColor = ThumbsDown_p100k_Color[wordAppName], Excel_SATPct_AppColor = ThumbsDown_p100k_Color[excelAppName], PowerPoint_SATPct_AppColor =  ThumbsDown_p100k_Color[pptAppname],
            Word_MTE_AppColor = MTE_Color[wordAppName], Excel_MTE_AppColor = MTE_Color[excelAppName], PowerPoint_MTE_AppColor =  MTE_Color[pptAppname],
            Word_NPS_AppColor = NPS_Color[wordAppName], Excel_NPS_AppColor = NPS_Color[excelAppName], PowerPoint_NPS_AppColor =  NPS_Color[pptAppname]
    | join kind=leftouter (
        HealthScore
        | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where Application == app and Platform == plat
        | where Date == prev
        | extend PreviousStatus = Health_Color
        | extend ASHA_Overall_Color = parse_json(ASHA_Overall_Color)
        | extend ASHA_Performance_Color = parse_json(ASHA_Performance_Color)
        | extend ThumbsDown_p100k_Color = parse_json(ThbDw_100k_Color)
        | extend M1Retention_Color = parse_json(M1Retention_Color)
        | extend SeenRate_Color = parse_json(SeenRate_Color)
        | extend KeepRate_Color = parse_json(KeepRate_Color)
        | extend TriedRate_Color = parse_json(TriedRate_Color)
        | extend Intensity_Color = parse_json(Intensity_Color)
        | extend MTE_Color = parse_json(MTE_Color)
        | extend FCFR_P50_Color = parse_json(FCFR_P50_Color)
        | extend FCFR_P95_Color = parse_json(FCFR_P95_Color)
        | extend RAI_Blocked_Color = parse_json(RAI_Blocked_Color)
        | extend Currency_Color = parse_json(Currency_Color)
        | extend NPS_Color = parse_json(NPS_Color)
        | project OmsTenantId, PreviousStatus, PreviousMonthMAU = MAU,
            Health_Score = round(Health_Score,2), 
            Word_ASHA_AppColor_Prev = ASHA_Overall_Color[wordAppName], Excel_ASHA_AppColor_Prev = ASHA_Overall_Color[excelAppName], PowerPoint_ASHA_AppColor_Prev =  ASHA_Overall_Color[pptAppname],
            Word_Performance_AppColor_Prev = ASHA_Performance_Color[wordAppName], Excel_Performance_AppColor_Prev = ASHA_Performance_Color[excelAppName], PowerPoint_Performance_AppColor_Prev =  ASHA_Performance_Color[pptAppname],
            Word_FCFR_AppColor_Prev = FCFR_P50_Color[wordAppName], Excel_FCFR_AppColor_Prev = FCFR_P50_Color[excelAppName], PowerPoint_FCFR_AppColor_Prev =  FCFR_P50_Color[pptAppname],
            Word_Currency_AppColor_Prev = Currency_Color[wordAppName], Excel_Currency_AppColor_Prev = Currency_Color[excelAppName], PowerPoint_Currency_AppColor_Prev =  Currency_Color[pptAppname],
            Word_SeenRate_AppColor_Prev = SeenRate_Color[wordAppName], Excel_SeenRate_AppColor_Prev = SeenRate_Color[excelAppName], PowerPoint_SeenRate_AppColor_Prev =  SeenRate_Color[pptAppname],
            Word_TriedRate_AppColor_Prev = TriedRate_Color[wordAppName], Excel_TriedRate_AppColor_Prev = TriedRate_Color[excelAppName], PowerPoint_TriedRate_AppColor_Prev =  TriedRate_Color[pptAppname],
            Word_KeepRate_AppColor_Prev = KeepRate_Color[wordAppName], Excel_KeepRate_AppColor_Prev = KeepRate_Color[excelAppName], PowerPoint_KeepRate_AppColor_Prev =  KeepRate_Color[pptAppname],
            Word_M1Retention_AppColor_Prev = M1Retention_Color[wordAppName], Excel_M1Retention_AppColor_Prev = M1Retention_Color[excelAppName], PowerPoint_M1Retention_AppColor_Prev =  M1Retention_Color[pptAppname],
            Word_Intensity_AppColor_Prev = Intensity_Color[wordAppName], Excel_Intensity_AppColor_Prev = Intensity_Color[excelAppName], PowerPoint_Intensity_AppColor_Prev =  Intensity_Color[pptAppname],
            Word_SATPct_AppColor_Prev = ThumbsDown_p100k_Color[wordAppName], Excel_SATPct_AppColor_Prev = ThumbsDown_p100k_Color[excelAppName], PowerPoint_SATPct_AppColor_Prev =  ThumbsDown_p100k_Color[pptAppname],
            Word_MTE_AppColor_Prev = MTE_Color[wordAppName], Excel_MTE_AppColor_Prev = MTE_Color[excelAppName], PowerPoint_MTE_AppColor_Prev =  MTE_Color[pptAppname],
            Word_NPS_AppColor_Prev= NPS_Color[wordAppName], Excel_NPS_AppColor_Prev = NPS_Color[excelAppName], PowerPoint_NPS_AppColor_Prev =  NPS_Color[pptAppname]
    ) on \${levelColumnName}
    | where CurrentStatus == "Red" or CurrentStatus == "DeepRed" or PreviousStatus =="Red" or PreviousStatus =="DeepRed"
    | extend MoM_Score = round(Health_Score-Health_Score1,2)
    | extend MoM_MAUScore = iif(PreviousMonthMAU != 0, round(todouble(CurrentMonthMAU - PreviousMonthMAU) / PreviousMonthMAU * 100, 2), 0.0)
    | join kind=inner 
        (database('TenantInsights').StrategicTenants
            | where Date == currentDate
            | extend IsS500_S2500 = IsS500 or IsS2500
            | where Is\${cohort} == true
            | distinct \${levelColumnName}, Tpid, Name = iif(levelName  == "Tpid", OrgName_Translated, TenantName_Translated) 
            |  project \${levelColumnName}, Tpid, Name
        ) on \${levelColumnName}
    | join kind = leftouter
        (
            PastStatusSet
        ) on \${levelColumnName}
    | project \${level} = \${levelColumnName}, OrgName = Name, 
        Health_Score = round(Health_Score,2), Health_Score_Color = Status, Health_Score_MoMChange = MoM_Score,
            MAU_Score = CurrentMonthMAU, MAU_Score_MoMChange = MoM_MAUScore,
            Word_ASHA_AppColor, Excel_ASHA_AppColor, PowerPoint_ASHA_AppColor,
            Word_Performance_AppColor, Excel_Performance_AppColor, PowerPoint_Performance_AppColor,
            Word_FCFR_AppColor, Excel_FCFR_AppColor, PowerPoint_FCFR_AppColor,
            Word_Currency_AppColor, Excel_Currency_AppColor, PowerPoint_Currency_AppColor,
            Word_SeenRate_AppColor, Excel_SeenRate_AppColor, PowerPoint_SeenRate_AppColor,
            Word_TriedRate_AppColor, Excel_TriedRate_AppColor, PowerPoint_TriedRate_AppColor,
            Word_KeepRate_AppColor, Excel_KeepRate_AppColor, PowerPoint_KeepRate_AppColor,
            Word_M1Retention_AppColor, Excel_M1Retention_AppColor, PowerPoint_M1Retention_AppColor,
            Word_Intensity_AppColor, Excel_Intensity_AppColor, PowerPoint_Intensity_AppColor,
            Word_SATPct_AppColor, Excel_SATPct_AppColor, PowerPoint_SATPct_AppColor,
            Word_MTE_AppColor, Excel_MTE_AppColor, PowerPoint_MTE_AppColor,
            Word_NPS_AppColor, Excel_NPS_AppColor, PowerPoint_NPS_AppColor,
            Word_ASHA_AppColor_Prev, Excel_ASHA_AppColor_Prev, PowerPoint_ASHA_AppColor_Prev,
            Word_Performance_AppColor_Prev, Excel_Performance_AppColor_Prev, PowerPoint_Performance_AppColor_Prev,
            Word_FCFR_AppColor_Prev, Excel_FCFR_AppColor_Prev, PowerPoint_FCFR_AppColor_Prev,
            Word_Currency_AppColor_Prev, Excel_Currency_AppColor_Prev, PowerPoint_Currency_AppColor_Prev ,
            Word_SeenRate_AppColor_Prev, Excel_SeenRate_AppColor_Prev, PowerPoint_SeenRate_AppColor_Prev,
            Word_TriedRate_AppColor_Prev, Excel_TriedRate_AppColor_Prev, PowerPoint_TriedRate_AppColor_Prev,
            Word_KeepRate_AppColor_Prev, Excel_KeepRate_AppColor_Prev, PowerPoint_KeepRate_AppColor_Prev,
            Word_M1Retention_AppColor_Prev, Excel_M1Retention_AppColor_Prev, PowerPoint_M1Retention_AppColor_Prev,
            Word_Intensity_AppColor_Prev, Excel_Intensity_AppColor_Prev, PowerPoint_Intensity_AppColor_Prev,
            Word_SATPct_AppColor_Prev, Excel_SATPct_AppColor_Prev, PowerPoint_SATPct_AppColor_Prev,
            Word_MTE_AppColor_Prev, Excel_MTE_AppColor_Prev, PowerPoint_MTE_AppColor_Prev,
            Word_NPS_AppColor_Prev, Excel_NPS_AppColor_Prev, PowerPoint_NPS_AppColor_Prev,
            PastStatus, CurrentStatus, PreviousStatus, Parent = Tpid
    | sort by Health_Score desc
    `;

export const copilotTenantSearchBarList: string = `
    // Copilot Tenant Search Bar List
    let currentDate = GetLatestDate();
    let minCoPilotLicenses = 10;
    ReadinessScore
    | where OmsTenantId != "All" and Date == currentDate and CoPilot_Licenses > minCoPilotLicenses
    | project OmsTenantId, Tpid = tolong(Tpid)
    |union(
        HealthScore
        | where OmsTenantId != "All" and Date == currentDate and CoPilot_Licenses > minCoPilotLicenses
        | project OmsTenantId, Tpid = tolong(Tpid)
        )
    | summarize by OmsTenantId, Tpid
    | join kind=inner (
        database('TenantInsights').StrategicTenants
            | where Date == currentDate and isnotempty(OrgName_Translated) and isnotempty(TenantName_Translated)
            | distinct Tpid = tolong(Tpid), OrgName_Translated, OmsTenantId, TenantName_Translated
        ) on OmsTenantId, Tpid
    | project Tpid, OrgName_Translated, OmsTenantId, TenantName_Translated
    `;
