import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import { Panel, PanelType, Stack } from "@fluentui/react";
import { CapHistoricalTrendsPanelProps } from "components/CommonFunnelComponents/CommonHistoricalTrendsPanel/types";
import "components/CommonFunnelComponents/CommonHistoricalTrendsPanel/style.css";

export const CapHistoricalTrendsPanel = ({
    showPanel,
    setShowPanel,
    data,
}: CapHistoricalTrendsPanelProps) => {
    return (
        <Panel
            isLightDismiss
            isOpen={showPanel}
            type={PanelType.custom}
            customWidth="700px"
            onDismiss={() => {
                setShowPanel(false);
            }}
            headerText={`Historical Trends for CAP Intents`}
            headerClassName="flyoutHeader"
        >
            <Stack>
                <Stack.Item grow>
                    <h2 className="commonPageBlockSubTitle">
                        Tried Count and Kept Rate Over Time - {data.Name}
                    </h2>
                    <LineChart
                        width={500}
                        height={300}
                        data={data.Data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" />
                        <YAxis yAxisId="left" tick={{ fill: "#8884d8" }} />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            tick={{ fill: "#82ca9d" }}
                            unit="%"
                            domain={[0, 100]}
                        />
                        <Tooltip />
                        <Legend />
                        <Line yAxisId="left" dataKey="TriedCount" stroke="#8884d8" />
                        <Line
                            yAxisId="right"
                            dataKey="KeptRate"
                            unit="%"
                            stroke="#82ca9d"
                        />
                    </LineChart>
                </Stack.Item>
            </Stack>
        </Panel>
    );
};
