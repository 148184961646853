import { fetchFunnelDataFromKusto } from "components/CommonFunnelComponents/CommonFunnel/ApiHandler";
import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";
import { FunnelQueryType, FunnelType } from "config/FunnelConfig";
import { KustoResponseType, MetricsResponseType } from "pages/commonTypes";

export type StandAloneMetricType = {
    metricName: string;
    count: number;
};

export const getFunnelStandaloneMetrics = async (
    filters: CommonFilterStateType,
    funnelType: FunnelType,
): Promise<MetricsResponseType> => {
    const payload = await fetchFunnelDataFromKusto(
        filters,
        funnelType,
        FunnelQueryType.StandaloneMetrics,
    );
    return payload;
};

// Expected schema: ReceivedDate, MetricName, Count
export const formatStandAloneMetricData = (
    metricData: KustoResponseType<string | number>,
): {} => {
    var standaloneMetrics = {};
    metricData.Tables[0].Rows.forEach((row) => {
        standaloneMetrics[row[1] as string] = row[2] as number;
    });
    return standaloneMetrics;
};
