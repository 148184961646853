import React, { useEffect, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { IStackStyles, Stack } from "@fluentui/react/lib/Stack";
import { CopilotExecOverviewFilterPanel } from "components/CopilotComponents/CopilotExecOverviewFilterPanel";
import CopilotExecOverviewHealthDistribution from "components/CopilotComponents/CopilotExecOverviewHealthDistribution";
import AppOverviewIconPanel from "components/CustomComponents/AppOverviewIconPanel/AppOverviewIconPanel";
import StatusLegend from "components/CustomComponents/StatusLegend/StatusLegend";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import { CompositeOverviewTable } from "components/OverviewComponents/CompositeOverviewTable";
import { TenantStatus } from "components/OverviewComponents/TenantStatus";
import { getConfig, Workload } from "config/PlatformConfig";
import { commonPageBlock, commonPageStyle } from "pages/common";
import { fetchData, getAppsList, getDateOptions } from "pages/Copilot/ApiHandler";
import {
    CopilotExecOverViewScoreType,
    CopilotExecOverviewFilterState,
    CopilotExecOverviewState,
} from "pages/Copilot/types";
import { logException, logFilterUsage } from "utils/AppInsightsHelper";
import "pages/common.css";
import { PivotSize } from "utils/Constants";
import {
    copilotDisabledAppList,
    copilotExecOverViewTableNameMap,
    copilotExecOverviewScoreTypeTabs,
} from "utils/CopilotConstants";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import {
    computeQueryParams,
    extractQueryParams,
    formatDateOptions,
    setUpDocumentUrl,
} from "utils/Helpers";
import { registerOverviewIcons } from "utils/IconRegistration";
import "./styles.css";

const initialData: CopilotExecOverviewState = {
    compositeScore: { headers: [], rows: [] },
    tenantStatus: { headers: [], rows: [] },
    dateOptions: [],
    loading: true,
};

export const statusBlock: IStackStyles = {
    root: {
        marginBottom: "10px",
        border: "solid 2px #f3f2f1",
        padding: "3px",
        borderRadius: "14px",
    },
};

const config = getConfig(Workload.COPILOT_COMMERCIAL);

const CopilotExecOverview = () => {
    registerOverviewIcons();

    const [scoreType, setScoreType] = useState<CopilotExecOverViewScoreType>(() => {
        const filtersFromQueryParams = extractQueryParams();
        if (filtersFromQueryParams["scoreType"]) {
            return filtersFromQueryParams[
                "scoreType"
            ] as CopilotExecOverViewScoreType;
        }

        return "Readiness";
    });

    const [filters, setFilters] = useState<CopilotExecOverviewFilterState>(() => {
        const filtersFromQueryParams = extractQueryParams();
        const initialFilters: CopilotExecOverviewFilterState = {
            date: null,
            platform: "All",
            level: config.defaults.level,
            cohort: config.defaults.cohort,
            application: "All",
            mau: config.defaults.minMau,
            endpoint: "All",
        };

        Object.keys(filtersFromQueryParams).forEach((filterName) => {
            if (filterName in initialFilters) {
                initialFilters[filterName] = filtersFromQueryParams[
                    filterName
                ] as string;
            }
        });

        if (filtersFromQueryParams["scoreType"] === "Readiness") {
            initialFilters.application = "All";
            initialFilters.platform = "All";
        }

        return {
            ...initialFilters,
        };
    });

    const [error, setError] = useState<string>(null);
    const [data, setData] = useState<CopilotExecOverviewState>(initialData);

    useEffect(() => {
        const getDatesData = async () => {
            try {
                const dates = await getDateOptions();
                const responseData = formatDateOptions(dates);
                const currentDate = responseData[0]?.key as string;

                if (filters.date === null) {
                    setFilters((oldFiltersState) => {
                        return { ...oldFiltersState, date: currentDate };
                    });
                }

                setData((oldDataState) => {
                    return { ...oldDataState, dateOptions: responseData };
                });
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotExecOverviewDatesOrConfigFetchFailed",
                    ),
                    {
                        message:
                            "Failure in fetching dates/table meta data for Copilot ExecOverview from Kusto",
                    },
                    error,
                );
                setError(error.message);
                return;
            }
        };

        const getScoreData = async (
            payloadFilters: CopilotExecOverviewFilterState,
        ) => {
            setData((oldDataState) => {
                return { ...oldDataState, loading: true };
            });
            try {
                const dataResponse = await fetchData(
                    payloadFilters,
                    data.dateOptions,
                    scoreType,
                );
                if (typeof dataResponse === "string") {
                    setError(dataResponse);
                } else {
                    setData((oldDataState) => {
                        return { ...oldDataState, ...dataResponse };
                    });
                }
            } catch (error) {
                setData(() => ({ ...initialData, loading: false }));
                setError(error.message);
            }
        };

        const payloadFilters = {
            application: filters.application,
            date: filters.date,
            platform: filters.platform,
            level: filters.level,
            cohort: filters.cohort,
            mau: filters.mau,
            endpoint: filters.endpoint,
        };

        if (data.dateOptions.length === 0) {
            getDatesData();
        } else {
            getScoreData(payloadFilters);
        }
        setUpDocumentUrl(
            {
                ...payloadFilters,
                scoreType: scoreType,
            },
            config.pageTitle,
        );
    }, [
        filters.application,
        filters.date,
        filters.cohort,
        filters.level,
        filters.platform,
        filters.mau,
        filters.endpoint,
        scoreType,
        data.dateOptions,
    ]);

    const onFilterChange = (item: string, value: string) => {
        const updatedFilter = { ...filters, [item]: value };

        logFilterUsage(config.pageTitle, item, value);
        setUpDocumentUrl(
            {
                ...updatedFilter,
                scoreType: scoreType,
            },
            config.pageTitle,
        );

        setFilters(updatedFilter);
    };

    const onScoreTypeChange = (item: PivotItem) => {
        const selectedScoreType = item.props.itemKey as CopilotExecOverViewScoreType;

        if (selectedScoreType === "Readiness") {
            setFilters((oldFiltersState) => {
                return { ...oldFiltersState, application: "All", platform: "All" };
            });
        } else {
            setFilters((oldFiltersState) => {
                return {
                    ...oldFiltersState,
                    application: config.defaults.app,
                    platform: "Win32",
                };
            });
        }
        setScoreType((oldScoreType) => {
            return oldScoreType === selectedScoreType
                ? oldScoreType
                : selectedScoreType;
        });

        logFilterUsage(config.pageTitle, "scoreType", selectedScoreType);
    };

    const queryParamsToSend = computeQueryParams({
        application: filters.application === "WXP" ? "Word" : filters.application,
        date: filters.date,
        platform: filters.platform,
        scoreType: scoreType,
        level: filters.level,
        endpoint: filters.endpoint,
    });

    return error ? (
        <ErrorFallback message={error} />
    ) : (
        <>
            <Stack styles={commonPageStyle}>
                <Stack.Item>
                    <div className="orgHeader" data-testid="orgHeader">
                        {config.pageHeader}
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <Pivot
                        linkSize={PivotSize.large}
                        selectedKey={scoreType}
                        onLinkClick={onScoreTypeChange}
                        styles={{
                            root: { display: "flex", justifyContent: "center" },
                        }}
                    >
                        {Object.keys(copilotExecOverviewScoreTypeTabs).map(
                            (type, idx) => {
                                return (
                                    <PivotItem
                                        headerText={type}
                                        itemKey={type}
                                        key={idx}
                                        itemIcon={
                                            copilotExecOverviewScoreTypeTabs[type]
                                                .icon
                                        }
                                    >
                                        <div className="copilotBlock" key={idx}>
                                            <Stack.Item styles={statusBlock}>
                                                <StatusLegend
                                                    platformConfig={config}
                                                />
                                            </Stack.Item>
                                            <Stack.Item className="copilotFilterPanelSpacer">
                                                <CopilotExecOverviewFilterPanel
                                                    dateOptions={data.dateOptions}
                                                    filters={filters}
                                                    scoreType={scoreType}
                                                    onFilterChange={onFilterChange}
                                                />
                                            </Stack.Item>
                                            <AppOverviewIconPanel
                                                apps={getAppsList(scoreType)}
                                                onAppClick={onFilterChange}
                                                selectedApp={filters.application}
                                                disabledAppList={
                                                    copilotDisabledAppList
                                                }
                                            />
                                            <Stack.Item
                                                styles={commonPageBlock}
                                                className="copilotOverviewPage"
                                            >
                                                <CompositeOverviewTable
                                                    payload={data.compositeScore}
                                                    queryParamsUrl={
                                                        queryParamsToSend
                                                    }
                                                    loading={data.loading}
                                                    tableNameMap={{
                                                        ...copilotExecOverViewTableNameMap[
                                                            scoreType
                                                        ],
                                                    }}
                                                    selectedDate={filters.date}
                                                    rankOn={filters.application}
                                                    platformConfig={config}
                                                    showAllColorTab={true}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <CopilotExecOverviewHealthDistribution
                                                    filters={filters}
                                                    scoreType={scoreType}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack styles={commonPageBlock}>
                                                    <Stack.Item grow={1}>
                                                        <TenantStatus
                                                            payload={
                                                                data.tenantStatus
                                                            }
                                                            queryParamsUrl={
                                                                queryParamsToSend
                                                            }
                                                            selectedDate={
                                                                filters.date
                                                            }
                                                            level={filters.level}
                                                            type="Movers"
                                                            loading={data.loading}
                                                            tableNameMap={{
                                                                ...copilotExecOverViewTableNameMap[
                                                                    scoreType
                                                                ],
                                                            }}
                                                            rankOn={
                                                                filters.application
                                                            }
                                                            platformConfig={config}
                                                            sortKey={`${scoreType}_Score_MoMChange`}
                                                        />
                                                    </Stack.Item>
                                                    <Stack.Item
                                                        grow={1}
                                                        styles={{
                                                            root: {
                                                                marginTop:
                                                                    "50px !important;",
                                                            },
                                                        }}
                                                    >
                                                        <TenantStatus
                                                            payload={
                                                                data.tenantStatus
                                                            }
                                                            queryParamsUrl={
                                                                queryParamsToSend
                                                            }
                                                            selectedDate={
                                                                filters.date
                                                            }
                                                            level={filters.level}
                                                            type="Shakers"
                                                            loading={data.loading}
                                                            tableNameMap={{
                                                                ...copilotExecOverViewTableNameMap[
                                                                    scoreType
                                                                ],
                                                            }}
                                                            rankOn={
                                                                filters.application
                                                            }
                                                            platformConfig={config}
                                                            sortKey={`${scoreType}_Score_MoMChange`}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                        </div>
                                    </PivotItem>
                                );
                            },
                        )}
                    </Pivot>
                </Stack.Item>
            </Stack>
        </>
    );
};

export default CopilotExecOverview;
