import { useReducer } from "react";
import { IDropdownOption } from "@fluentui/react";
import { isTelemetryRelatedQueryStringParam } from "@oga-plg/plg-telemetry/dist";
import { schemePastel2, schemeTableau10 } from "d3-scale-chromatic";
import { addDays, addMonths, format, getTime, parse, subMonths } from "date-fns";
import md5 from "md5";
import { ConsumerCamauTableType } from "components/ConsumerCamauComponents/types";
import { ConsumerGrowthScorecardTableType } from "components/ConsumerGrowthScorecardComponents/types";
import {
    ERFMTableColumnConfig,
    ERFMTableType,
} from "components/ERFMComponents/types";
import { Workload } from "config/PlatformConfig";
import { Cohort, CohortData, KustoResponseType } from "pages/commonTypes";
import { FunnelStage } from "pages/ConsumerDay0Funnel/constants";
import { ValueAndPercentDataType } from "pages/ConsumerDay0Funnel/types";
import { logException } from "utils/AppInsightsHelper";
import { platformExemptSignals } from "utils/Constants";
import { crossPlatformLinks } from "utils/CrossPlatformConstants";
import { tableColumnConfig } from "utils/types";

export function extractQueryParams() {
    const queryParams = {};
    const search = decodeURI(window.location.search);
    const paramsExist = search.indexOf("=") >= 0;
    if (paramsExist) {
        search
            .replace("?", "")
            .split("&")
            .forEach(function getParams(q) {
                const v = q.split("=");

                // Note: Exclude any query parameters related to telemetry referral information.
                // We attempt to clean them after reading them in the core routes code but if a page
                // references the query params on the same render that they were cleaned, that cleaned
                // state will not be available until subsequent renders.
                if (!isTelemetryRelatedQueryStringParam(v[0])) {
                    queryParams[v[0]] = v[1];
                }
            });
    }
    return queryParams;
}

export function checkMissingDrilldownQueryParams(queryParams: {}) {
    if (Object.keys(queryParams).length < 1) {
        const defaultQueryParams = {
            id: "72f988bf-86f1-41af-91ab-2d7cd011db47", // Microsoft
            level: "TenantId",
            date: format(subMonths(new Date(), 2), "yyyy-MM-01"),
        };

        window.history.pushState(
            {},
            "",
            window.location.pathname + computeQueryParams(defaultQueryParams),
        );

        queryParams = defaultQueryParams;
    }
    return queryParams;
}

export const computePlatformUrl = (
    platform: string,
    level: string,
    id: string,
): string => {
    let url = crossPlatformLinks[platform].base;
    url = `${url}?id=${id}&level=${level}`;
    return url;
};

export function computeQueryParams(filters: {}, ignoreTpid = false) {
    let query = "";
    let keys = [];
    if (ignoreTpid)
        keys = Object.keys(filters).filter((x) => x !== "Tpid" && x !== "TenantId");
    else keys = Object.keys(filters);
    let isInitial = true;
    Object.keys(keys).forEach(function appendParams(key) {
        if (!isInitial) query += "&";
        query += `${keys[key]}=${filters[keys[key]]}`;
        isInitial = false;
    });
    return query.length === 0 ? "" : `?${query}`;
}

export const generateColor = () => {
    return `#${Math.random().toString(16).substr(-6)}`;
};

/**
 * Returns one of 10 unique colors (0-9) as documented at
 * https://github.com/d3/d3-scale-chromatic/blob/v3.0.0/README.md#schemeTableau10.
 * Values 10 or above will be modded.
 */
export const getChartColor = (i: number) => {
    return schemeTableau10[i % 10];
};

/**
 * Returns one of 8 unique colors (0-7) as documented at
 * https://github.com/d3/d3-scale-chromatic/blob/v3.0.0/README.md#schemePastel2.
 * Values 8 or above will be modded.
 */
export const getChartColorPastel2 = (i: number) => {
    return schemePastel2[i % 8];
};

export const isNullOrEmpty = (value) => {
    return value === "" || value === null || value === undefined;
};

export function getPostfixValue(columnName: string) {
    switch (columnName) {
        case "EUPL_P95_Score":
        case "EUPL_P50_Score":
        case "PerfP95_Score":
        case "P50ChatSwitchV2_Score":
        case "P95ChatSwitchV2_Score":
        case "FCFRP50_Score":
        case "FCFRP95_Score":
        case "LCFR_P75_Score":
        case "FCFR_P75_Score":
            return "s";

        case "MAU_Score":
        case "ActiveUsers_Score":
        case "Watson_Score":
        case "UAE_Score":
        case "OCV_Score":
        case "Composite_Score":
        case "Win32_Score_Platform":
        case "Web_Score_Platform":
        case "Web_Score_MoMChange":
        case "Win32_Score_MoMChange":
        case "Composite_MoMChange":
        case "NPS_Score":
        case "Web_MAU_Score":
        case "Win32_MAU_Score":
        case "TeamsMAU_Score":
        case "EligibleLicenses_Score":
        case "CopilotLicenses_Score":
        case "CopilotEnabled_Score":
        case "Readiness_Score":
        case "SATPct_Score":
        case "Intensity_Score":
        case "MTE_Score":
        case "QPUU_Score":
            return "";

        default:
            return "%";
    }
}

export function getNegateValue(columnName: string) {
    switch (columnName) {
        case "EUPL_P95_MoMChange":
        case "EUPL_P50_MoMChange":
        case "OpenInClient_MoMChange":
        case "PerfP95_MoMChange":
        case "UAE_MoMChange":
        case "Watson_MoMChange":
        case "FQ_Score_MoMChange":
        case "CH_Score_MoMChange":
            return true;
        default:
            return false;
    }
}
export const setBlankIfEmpty = (item) => {
    return item === "" ? "(Blank)" : item;
};

export const charsInIsoDate = 10; // "yyyy-MM-dd" is 10 characters.

export const formatDate = (dateString: string) => {
    const date = new Date(`${dateString}T00:00:00`);
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
};

/**
 * Translates a dateTime string into yyyy-MM-dd format.
 * Avoids problems that can be introduced by timezones.
 */
export const dateTimeToDate = (dateTimeString: string) =>
    new Date(dateTimeString).toISOString().substring(0, charsInIsoDate);

export const formatDisplayDate = (dateString: string): string => {
    if (!dateString) {
        return "";
    }

    try {
        if (!dateString.includes("T00:00:00")) {
            dateString = dateString.concat("T00:00:00");
        }
        const date = new Date(dateString);
        const formattedDate = format(date, "MMM yyyy");
        return formattedDate;
    } catch (error) {
        logException(
            new Error("DateFormatFailed"),
            {
                message: `Failure formatting dates for ${dateString}`,
            },
            error,
        );
    }
};

export const dateMonthlyFormatHandler = (dateOptions) => {
    return dateOptions.map((element) => {
        return {
            key: element.key,
            text: formatDisplayDate(element.key),
        };
    });
};

export const addMonth = (dateString: string, n: number) => {
    const date = parse(dateString, "yyyy-MM-dd", new Date());
    const newMonth = addMonths(date, n);
    return format(newMonth, "yyyy-MM-dd");
};

export const addMonthsToDate = (date: Date, n: number) => {
    return addMonths(date, n);
};

export const getDateTime = (date: Date) => {
    return getTime(date);
};

export const getDay = (dateString: string) => {
    const date = parse(dateString, "yyyy-MM-dd", new Date());
    const day = date.getDate();
    return day;
};

export const addDay = (dateString: string, n: number) => {
    const date = parse(dateString, "yyyy-MM-dd", new Date());
    const newDate = addDays(date, n);
    return format(newDate, "yyyy-MM-dd");
};

export const convertUtcDate = (utcDateString: string) => {
    const convertedUTCdate = utcDateString.slice(0, -1);
    return convertedUTCdate;
};

export const formatLastUpdated = (dateString: string) => {
    const date = addDays(addMonths(new Date(convertUtcDate(dateString)), 1), 5);
    const formattedDate = `Last updated: ${format(date, "MMM do yyyy")}`;
    return formattedDate;
};

export const getExemptedFields = (note) => {
    const exemptedFields = [];
    if (note.ExemptMAU) exemptedFields.push("MAU");
    if (note.ExemptMEU) exemptedFields.push("MEU");
    if (note.ExemptReturningMAU) exemptedFields.push("ReturningMAU");
    if (note.ExemptEngagedEditors) exemptedFields.push("EngagedEditors");
    if (note.ExemptPerformance) exemptedFields.push("Performance");
    if (note.ExemptReliability) exemptedFields.push("Reliability");
    if (note.ExemptFrown || note.ExemptFeedback) exemptedFields.push("Feedback");
    if (note.ExemptOpenInClient) exemptedFields.push("OpenInClient");
    if (note.ExemptCurrency) exemptedFields.push("Currency");

    return exemptedFields.join(", ");
};

export const hashTenantInfo = (state) => {
    state.data = state.data.map((iD) => {
        iD.OrgName = md5(iD.OrgName);
        if (state.queryParams["current"]["level"] === "TenantId")
            iD.TenantId = md5(iD.TenantId);
        else iD.Tpid = md5(iD.Tpid);
        return iD;
    });
};

export function transformUserFrequencyData(data: {} = {}) {
    if (!data) return [];

    const order: any[] = [
        "1",
        "2",
        "3-4",
        "5-7",
        "8-12",
        "13-20",
        "21-50",
        "51-100",
        "101-200",
        "201+",
    ];

    // creating buckets in order so that they render in order.
    const userfrequencyOrdered = [];
    order.forEach((order) => {
        if (data[order])
            userfrequencyOrdered.push({ SessionBucket: order, ...data[order] });
    });

    return userfrequencyOrdered;
}

const appendUnknownMonths = (monthsToAppend, string) => {
    return ";".repeat(monthsToAppend).concat(string);
};

export const calculateOffset = (dateFilter: string, dateOptions: string[]) => {
    return dateOptions.map((dOpt) => dOpt["key"]).indexOf(dateFilter);
};

export const formatStatusColors = (pastStatus: string, offset: number) => {
    let monthsToAppend = 11 - (pastStatus.match(/;/g) || []).length;
    if (offset > 0) {
        monthsToAppend += offset;
    }
    if (monthsToAppend > 0) {
        pastStatus = appendUnknownMonths(monthsToAppend, pastStatus);
    }
    pastStatus = String(pastStatus)
        .replace(/5/g, "Blue")
        .replace(/4/g, "Yellow")
        .replace(/3/g, "Red")
        .replace(/2/g, "DeepRed")
        .replace(/0/g, "Gray")
        .replace(/1/g, "Regression");

    const pastStatusList = pastStatus.split(";");
    const filteredPastStatusList = pastStatusList.slice(
        0,
        pastStatusList.length - offset,
    );
    const result = filteredPastStatusList.slice(
        filteredPastStatusList.length - 12,
        filteredPastStatusList.length,
    );
    return result;
};

export const getTenantInsightsRedirectUrl = (
    queryParamsUrl: string,
    id: string,
    { selectedDate, platformConfig },
) => {
    switch (platformConfig.platform) {
        case Workload.WIN32:
        case Workload.MAC:
        case Workload.TEAMS:
        case Workload.WEB:
            return `/${platformConfig.productInsightsPagePath}?${queryParamsUrl}&id=${id}&date=${selectedDate}`;
        case Workload.WEB_CONSUMER:
            return `/${platformConfig.productInsightsPagePath}?${queryParamsUrl}&cohortName=${id}`;
        case Workload.COPILOT_COMMERCIAL:
            return `/${platformConfig.productInsightsPagePath}?${queryParamsUrl}&id=${id}`;
        default:
            return "";
    }
};

export const isTenantExempt = (note, platform) => {
    if ("ExemptedUntil" in note) {
        const today = new Date();
        const exemptedUntil = new Date(note.ExemptedUntil);
        if (today <= exemptedUntil) {
            let isExempt = false;
            const exemptSignals =
                platform in platformExemptSignals
                    ? platformExemptSignals[platform]
                    : [];
            exemptSignals.push("IsExempted");

            exemptSignals.forEach((signal) => {
                isExempt = isExempt || (signal in note ? note[signal] : false);
            });

            return isExempt;
        }
    }
    return false;
};

export function getQueryParamsForLevel(level: string) {
    return {
        levelColumnName: level === "Tpid" ? "Tpid" : "OmsTenantId",
        level,
    };
}

export const getDomainForYAxis = (
    YAxisDataSet: number[],
    startAtZero: boolean = false,
): number[] => {
    let maxValue = 0;
    YAxisDataSet.forEach((datapoint) => {
        const currentValue = Math.abs(datapoint);
        if (!maxValue || maxValue < currentValue) {
            maxValue = currentValue;
        }
    });

    maxValue += 1;

    return [Math.floor(startAtZero ? 0 : -1 * maxValue), Math.ceil(maxValue)];
};

export const getTicksForChart = (
    YAxisDataSet: number[],
    startAtZero: boolean = false,
    numberOfTicks: number = 5,
): number[] => {
    let maxValue: number = getDomainForYAxis(YAxisDataSet)[1];
    const tickIntervalDenominator = startAtZero
        ? numberOfTicks
        : Number(((numberOfTicks - 1) / 2).toFixed(2));
    const tickInterval = maxValue / tickIntervalDenominator;
    const calculatedTicks: number[] = [];

    for (let i = 0; i < numberOfTicks; i++) {
        calculatedTicks[i] = Number(maxValue.toFixed(2));
        maxValue -= tickInterval;
    }

    return calculatedTicks;
};

/* The order of priority of the Cohorts is of the order S500 > S2500 > GoogleMove > EPA > Gov */
export const getPriorityCohort = (cohorts: CohortData): Cohort => {
    const { isS2500, isS500, isGoogleMove, isEpa, isGov } = cohorts;
    const cohort: Cohort = isS500
        ? "S500"
        : isS2500
        ? "S2500"
        : isGoogleMove
        ? "GoogleMove"
        : isEpa
        ? "EPA"
        : isGov
        ? "Gov"
        : "";
    return cohort;
};

export const shouldTranslate = (tenantName: string) => {
    // Regex pattern to match the list of unicode characters - https://www.obliquity.com/computer/html/unicode0000.html
    // eslint-disable-next-line no-control-regex
    const english = /^[\x00-\xFF]*$/;
    return !english.test(tenantName);
};

/**
 * Transforms a response from Kusto into a more usable array of objects.
 * For example, if you query Kusto and get back a table with Columns Col1 and Col2,
 * and a couple rows with values 42,3 and 9,27, this would transform it into:
 * [{Col1: 42, Col2: 3}, {Col1: 9, Col2: 27}]
 */
export const createObjectArrayFromKustoResponse = <KustoDataType, OutputObjectType>(
    kustoResponse: KustoResponseType<KustoDataType>,
): OutputObjectType[] => {
    const dataTable = kustoResponse.Tables[0];
    return dataTable.Rows.map(
        (row) =>
            row.reduce(
                (acc, current, index) => ({
                    ...acc,
                    [dataTable.Columns[index].ColumnName]: current,
                }),
                {},
            ) as OutputObjectType,
    );
};

export const isPositiveIntegerString = (input: any) => {
    if (typeof input !== "string") return false;
    const int = parseInt(input);
    if (int != (input as unknown as number)) return false;
    return int > 0;
};

export const setUpDocumentUrl = (updatedFilter: {}, title: string) => {
    const requiredFilters = { ...updatedFilter };

    document.title = title;
    window.history.pushState(
        "",
        "",
        window.location.pathname + computeQueryParams(requiredFilters),
    );
};

export const formatDateOptions = (data): IDropdownOption[] => {
    const dates: IDropdownOption[] = [];
    data.Tables[0].Rows.map((x) => dates.push({ key: x[0], text: x[0] }));
    return dates;
};

export const getAliasFromEmail = (email: string) => {
    if (typeof email !== "string") return email;
    if (email === null || email === undefined) return email;

    const emailSplit = email.split("@");
    const alias = emailSplit.length > 1 ? emailSplit[0] : email;

    return alias;
};

export const formatNumberCompact = (
    value: number,
    fractionDigits: number = 2,
): string => {
    let abbrValue = "";
    if (value) {
        abbrValue = Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: fractionDigits,
        }).format(value);
    }
    return abbrValue;
};

export const getStageIndex = (stage: string) => {
    return Object.values(FunnelStage).indexOf(stage as FunnelStage);
};

export const getStageDropOff = (
    currentStage: ValueAndPercentDataType,
    nextStage: ValueAndPercentDataType,
): ValueAndPercentDataType => {
    const rawDelta: number = nextStage["value"] - currentStage["value"];
    const percentDelta: number = parseFloat(
        ((rawDelta * 100.0) / currentStage["value"]).toFixed(2),
    );

    return {
        value: rawDelta,
        percentage: percentDelta,
    };
};

export const getExportCsvHeaders = (
    data: ConsumerCamauTableType | ConsumerGrowthScorecardTableType | ERFMTableType,
    application: string,
    tableColumnConfig: tableColumnConfig | ERFMTableColumnConfig,
): { label: string; key: string }[] => {
    if (!data || Object.keys(data).length == 0) return [];

    const perAppData = data[application];
    if (!perAppData || perAppData.length === 0) return [];

    return Object.keys(perAppData[0]).map((value) => {
        return {
            label: tableColumnConfig[value]?.exportCSVHeaderName ?? value,
            key: value,
        };
    });
};

export const useOnDismissMessageBar = (onDismiss?: () => void) => {
    const [isOpen, close] = useReducer(() => {
        return false;
    }, true);

    const handleDismissMessageBar: () => void = onDismiss ?? close;

    return { isOpen, handleDismissMessageBar };
};

export const isDev = () => {
    return process.env.NODE_ENV === "development";
};
