import React from "react";
import { IColumn, Icon, TooltipHost } from "@fluentui/react";
import { ERFMColumns } from "components/ERFMComponents/config";
import { ERFMRowType } from "components/ERFMComponents/types";

export const getERFMColumns = (): IColumn[] => {
    const columns: IColumn[] = [];

    ERFMColumns.forEach((column) => {
        columns.push({
            key: column.name,
            name: (
                <>
                    {column.displayName}
                    {column.tooltip && (
                        <TooltipHost content={column.tooltip}>
                            <Icon iconName="info" className="tableColumnDefintion" />
                        </TooltipHost>
                    )}
                </>
            ) as any,
            fieldName: column.displayName,
            minWidth: column.minWidth ?? 120,
            maxWidth: column.minWidth ?? 120,
            ariaLabel: column.displayName,
            isResizable: true,
            headerClassName: `detailsListColumnHeaderWrap ${column.className}`,
        });
    });

    columns.push({ key: "Dummy", name: "", fieldName: "Dummy", minWidth: 10 });

    return columns;
};

// Some cells in the eRFM table are hard-coded to display "NA"
export const isERFMTableCellInvalid = (
    colKey: string,
    item: ERFMRowType,
    country: string,
): boolean => {
    return (
        ((colKey === "PercentConvertedUsers" ||
            colKey === "UAECrashRate" ||
            colKey === "WatsonCrashRate") &&
            (item.Segment === "WebAllFree" || item.Segment === "WebPlusERFM")) ||
        // VSO 8424177: Remove once UAE and Watson RFM data shows up
        ((colKey === "UAECrashRate" || colKey === "WatsonCrashRate") &&
            item.Segment === "RFM") ||
        (country !== "All" &&
            (colKey === "UAECrashRate" || colKey === "WatsonCrashRate"))
    );
};
