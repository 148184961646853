import React, { useEffect, useState } from "react";
import { PercentageStandAloneMetric } from "components/CommonFunnelComponents/CommonFunnelStandaloneMetrics/PercentageStandaloneMetric";
import {
    formatStandAloneMetricData,
    getFunnelStandaloneMetrics,
} from "components/CommonFunnelComponents/CommonFunnelStandaloneMetrics/ApiHandler";
import { getFunnelConfig } from "config/FunnelConfig";

export const StandaloneMetrics = ({ filters, funnelType }) => {
    const funnelConfig = getFunnelConfig(funnelType);
    const [loading, setLoading] = useState<boolean>(true);
    const [metrics, setMetrics] = useState<{}>();

    useEffect(() => {
        const getStandaloneMetrics = async () => {
            setLoading(true);
            try {
                const result = await getFunnelStandaloneMetrics(
                    { ...filters },
                    funnelType,
                );
                setMetrics(formatStandAloneMetricData(result));
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        getStandaloneMetrics();
    }, [filters, funnelType]);

    return (
        <>
            {!loading &&
                funnelConfig.standaloneMetrics?.map((metric) => (
                    <PercentageStandAloneMetric
                        key={metric.metricName}
                        metricName={metric.metricName}
                        numeratorText={metric.numeratorText}
                        denominatorText={metric.denominatorText}
                        numerator={metrics[metric.numeratorKey]}
                        denominator={metrics[metric.denominatorKey]}
                    />
                ))}
        </>
    );
};
