export const copilotProductDrilldownMetadata: string = `
    // Copilot Product Drilldown Metadata
    let currentDate = GetLatestDate();
    database('TenantInsights').StrategicTenants
    | where Date == currentDate
    | where \${levelColumnName}=="\${id}"
    | extend OrgName=iif("\${level}"=="Tpid",OrgName_Translated, TenantName_Translated), Parent=Tpid
    | sort by IsECAP, IsS2500, IsS500, IsEPA, IsGoogleMove, IsGov, IsEO, IsCopilot
    | take 1
    | project Parent = Tpid, \${levelColumnName}, OrgName, IsS500, IsEPA, IsS2500, IsGoogleMove, IsGov, IsEO, IsCopilot, IsECAP
    `;

export const copilotCoreReadinessMetrics: string = `
    // Copilot Core Readiness Metrics
    let dt = "\${date}";
    let levelName = "\${level}";
    let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
    let PastStatusSet = ReadinessScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}"
    | project \${levelColumnName}, Date, Readiness_Color
    | order by Date asc
    | summarize PastStatus = make_list(Readiness_Color) by \${levelColumnName};
    ReadinessScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}" and Date == dt
    | join kind=leftouter
        (
            ReadinessScore
            | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | where \${levelColumnName} == "\${id}" and Date == prev
        ) on \${levelColumnName}
    | join kind = inner (PastStatusSet) on \${levelColumnName}
    | project PastStatus, MoMScore = round(Readiness_Score - Readiness_Score1, 2),
        OneDrivePctRate = round(OneDrive_Pct, 2), Color_OneDrivePctRate = OneDrive_Color, MoM_OneDrivePctRate = round(OneDrive_Pct - OneDrive_Pct1 ,2),
        MIPEnabledPctRate = round(MIPEnabled_Pct, 2), Color_MIPEnabledPctRate = DataProtec_Color, MoM_MIPEnabledPctRate = round(MIPEnabled_Pct - MIPEnabled_Pct1 ,2),
        CopilotEnabledPctRate = round(Copilot_Enabled_pct, 2), Color_CopilotEnabledPctRate= Enabled_Color, MoM_CopilotEnabledPctRate = round(Copilot_Enabled_pct - Copilot_Enabled_pct1 ,2),
        SPOLabelling = SPO_Labelling, Color_SPOLabelling = DataProtec_Color, MoM_SPOLabelling = "N/A",
        CollabProtection = Collab_Protection, Color_CollabProtection = DataProtec_Color, MoM_CollabProtection = "N/A",
        FbkAndLogsONPctScore = round(FbkAndLogsON_pct, 2), Color_FbkAndLogsONPctScore = FbkAndLogsON_Color, MoM_FbkAndLogsONPctScore = round(FbkAndLogsON_pct - FbkAndLogsON_pct1 ,2),
        FbkSurveyONPctScore = round(Fdbck_SurveyON_pct, 2), Color_FbkSurveyONPctScore = FdbkSurvey_Color, MoM_FbkSurveyONPctScore = round(Fdbck_SurveyON_pct - Fdbck_SurveyON_pct1 ,2),
        ODDRate = round(ODD_Diagnostics_Rate, 2), Color_ODDRate = OffDiagnostic_Color, MoM_ODDRate = round(ODD_Diagnostics_Rate - ODD_Diagnostics_Rate1 ,2),
        ConnectedExperienceRate = round(ConnectedExperience_Rate, 2), Color_ConnectedExperienceRate= ConnectedExp_Color, MoM_ConnectedExperienceRate = round(ConnectedExperience_Rate - ConnectedExperience_Rate1 ,2),
        ChannelReadinessRate = round(ChannelReadiness_Rate, 2), Color_ChannelReadinessRate = ChannelReadiness_Color, MoM_ChannelReadinessRate = round(ChannelReadiness_Rate - ChannelReadiness_Rate1 ,2),
        HealthColor = Readiness_Color, Score = round(Readiness_Score, 2), Application = "All"
    `;

export const copilotCoreHealthMetricsM365Chat: string = `
// Copilot Core Health Metrics
    let dt = "\${date}";
    let app = "\${application}";
    let levelName = "\${level}";
    let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
    let HealthScore_M365ChatUnpivoted = HealthScore_M365Chat
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}" and Endpoint == "All";
    let PastStatusSet = HealthScore_M365ChatUnpivoted
    | project \${levelColumnName}, Date, M365Chat_ColorCode
    | order by Date asc
    | summarize PastStatus = make_list(M365Chat_ColorCode) by \${levelColumnName};
    HealthScore_M365ChatUnpivoted
    | where Date == dt 
    | project OmsTenantId,
        Health_Score = round(todouble(M365Chat_Score), 2), Health_Score_Color = M365Chat_ColorCode, Status = M365Chat_ColorCode,
        CurrentMonthMAU = round(todouble(Mau), 2),
        MAURate_Score = round(todouble(MAURate), 2), MAURate_Score_Color = MAURate_ColorCode,
        Availability_Score = round(todouble(AvailabilityRate), 2), Availability_Score_Color = Availability_ColorCode,
        FCFR_P75_Score = round(todouble(FCFR_P75), 2), FCFR_P75_Score_Color = FCFR_ColorCode,
        LCFR_P75_Score = round(todouble(LCFR_P75), 2), LCFR_P75_Score_Color = LCFR_ColorCode,   
        QPUU_Score = round(todouble(QueryCountPerUniqueUser), 2), QPUU_Score_Color = Qry_pUser_ColorCode,
        M1Retention_Score = round(todouble(RetentionRate), 2), M1Retention_Score_Color = Retention_ColorCode,    
        CitationRate_Score = round(todouble(CitationRate), 2), CitationRate_Score_Color = Citation_ColorCode,
        SATRate_Score = round(todouble(SATRate), 2), SATRate_Score_Color = SATRate_ColorCode  
    | join kind=leftouter 
        (
            HealthScore_M365ChatUnpivoted
            | where Date == prev
            | project OmsTenantId,
                    Health_Score = round(todouble(M365Chat_Score), 2),
                    PreviousMonthMAU= round(todouble(Mau), 2),
                    MAURate_Score = round(todouble(MAURate), 2),
                    Availability_Score = round(todouble(AvailabilityRate), 2),
                    FCFR_P75_Score = round(todouble(FCFR_P75), 2),
                    LCFR_P75_Score = round(todouble(LCFR_P75), 2),   
                    QPUU_Score = round(todouble(QueryCountPerUniqueUser), 2),
                    M1Retention_Score = round(todouble(RetentionRate), 2),   
                    CitationRate_Score = round(todouble(CitationRate), 2),
                    SATRate_Score = round(todouble(SATRate), 2)  
        ) on \${levelColumnName}
    | join kind = inner (PastStatusSet) on \${levelColumnName}
    | project PastStatus, MoMScore = round(Health_Score-Health_Score1,2),                           
              MAURate = round(MAURate_Score,2), Color_MAURate = MAURate_Score_Color, MoM_MAURate = round(MAURate_Score-MAURate_Score1,2),
              Availability = round(Availability_Score,2), Color_Availability = Availability_Score_Color, MoM_Availability = round(Availability_Score-Availability_Score1,2),
              FCFRP75 = round(FCFR_P75_Score,2), Color_FCFRP75 = FCFR_P75_Score_Color, MoM_FCFRP75 = round(FCFR_P75_Score-FCFR_P75_Score1,2),
              LCFRP75 = round(LCFR_P75_Score,2), Color_LCFRP75 = LCFR_P75_Score_Color, MoM_LCFRP75 = round(LCFR_P75_Score-LCFR_P75_Score1,2),
              QPUU = round(QPUU_Score,2), Color_QPUU = QPUU_Score_Color, MoM_QPUU = round(QPUU_Score-QPUU_Score1,2),
              M1Retention = round(M1Retention_Score,2), Color_M1Retention = M1Retention_Score_Color, MoM_M1Retention = round(M1Retention_Score-M1Retention_Score1,2),
              CitationRate = round(CitationRate_Score,2), Color_CitationRate = CitationRate_Score_Color, MoM_CitationRate = round(CitationRate_Score-CitationRate_Score1,2),
              SATRate = round(SATRate_Score,2), Color_SATRate = SATRate_Score_Color, MoM_SATRate = round(SATRate_Score-SATRate_Score1,2),
              HealthColor = Health_Score_Color, Score = round(Health_Score,2), Application = app;
    `;

export const copilotCoreHealthMetrics: string = `
    // Copilot Core Health Metrics
    let dt = "\${date}";
    let app = "\${application}";
    let plat = "\${platform}";
    let levelName = "\${level}";
    let prev = format_datetime(datetime_add('Month', -1, todatetime(dt)), "yyyy-MM-dd");
    let PastStatusSet = HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}" and Application == app and Platform == plat
    | project \${levelColumnName}, Date, Health_Color
    | order by Date asc
    | summarize PastStatus = make_list(Health_Color) by \${levelColumnName};
    HealthScore
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}" and Date == dt and Application == app and Platform == plat
    | extend ASHA_Overall = parse_json(ASHA_Overall)
    | extend ASHA_Overall_Color = parse_json(ASHA_Overall_Color)
    | extend ASHA_Performance = parse_json(ASHA_Performance)
    | extend ASHA_Performance_Color = parse_json(ASHA_Performance_Color)
    | extend ThumbsDown_p100k = parse_json(ThumbsDown_p100k)
    | extend ThumbsDown_p100k_Color = parse_json(ThbDw_100k_Color)
    | extend M1Retention_Pct = parse_json(M1Retention_Pct)
    | extend M1Retention_Color = parse_json(M1Retention_Color)
    | extend SeenRate = parse_json(SeenRate)
    | extend SeenRate_Color = parse_json(SeenRate_Color)
    | extend KeepRate = parse_json(KeepRate)
    | extend KeepRate_Color = parse_json(KeepRate_Color)
    | extend TriedRate = parse_json(TriedRate)
    | extend TriedRate_Color = parse_json(TriedRate_Color)
    | extend Intensity = parse_json(Intensity)
    | extend Intensity_Color = parse_json(Intensity_Color)
    | extend MTE_Weighted_Avg = parse_json(MTE_Weighted_Avg)
    | extend MTE_Color = parse_json(MTE_Color)
    | extend Performance_FCFR_P50 = parse_json(Performance_FCFR_P50)
    | extend FCFR_P50_Color = parse_json(FCFR_P50_Color)
    | extend Performance_FCFR_P95 = parse_json(Performance_FCFR_P95)
    | extend FCFR_P95_Color = parse_json(FCFR_P95_Color)
    | extend RAI_Blocked_pct = parse_json(RAI_Blocked_pct)
    | extend RAI_Blocked_Color = parse_json(RAI_Blocked_Color)
    | extend Currency_Pct = parse_json(Currency_Pct)
    | extend Currency_Color = parse_json(Currency_Color)
    | extend NPS_NetPromoterScore = parse_json(NPS_NetPromoterScore)
    | extend NPS_Color = parse_json(NPS_Color)
    | project OmsTenantId,
        Health_Score = round(Health_Score,2), Health_Score_Color = Health_Color, Status = Health_Color,
        ASHA_Score = round(todouble(ASHA_Overall[app]), 2), ASHA_Score_Color = ASHA_Overall_Color[app],
        Performance_Score = round(todouble(ASHA_Performance[app]), 2), Performance_Score_Color = ASHA_Performance_Color[app],
        SATPct_Score = round(todouble(ThumbsDown_p100k[app]), 2), SATPct_Score_Color = ThumbsDown_p100k_Color[app],
        M1Retention_Score = round(todouble(M1Retention_Pct[app]), 2), M1Retention_Score_Color = M1Retention_Color[app],
        KeepRate_Score = round(todouble(KeepRate[app]), 2), KeepRate_Score_Color = KeepRate_Color[app],
        SeenRate_Score = round(todouble(SeenRate[app]), 2), SeenRate_Score_Color = SeenRate_Color[app],
        TriedRate_Score = round(todouble(TriedRate[app]), 2), TriedRate_Score_Color = TriedRate_Color[app],
        Intensity_Score = round(todouble(Intensity[app]), 2), Intensity_Score_Color = Intensity_Color[app],
        MTE_Score = round(todouble(MTE_Weighted_Avg[app]), 2), MTE_Score_Color = MTE_Color[app],
        FCFRP50_Score = round(todouble(Performance_FCFR_P50[app]), 2), FCFRP50_Score_Color = FCFR_P50_Color[app],
        FCFRP95_Score = round(todouble(Performance_FCFR_P95[app]), 2), FCFRP95_Score_Color = FCFR_P95_Color[app],
        RAIBlockRate_Score = round(todouble(RAI_Blocked_pct[app]), 2), RAIBlockRate_Score_Color = RAI_Blocked_Color[app],
        Currency_Score = round(todouble(Currency_Pct[app]), 2), Currency_Score_Color = Currency_Color[app],
        NPS_Score = round(todouble(NPS_NetPromoterScore[app]), 2), NPS_Score_Color = NPS_Color[app]
    | join kind=leftouter 
        (
            HealthScore
            | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | where \${levelColumnName} == "\${id}" and Date == prev and Application == app and Platform == plat
                | extend ASHA_Overall = parse_json(ASHA_Overall)
                | extend ASHA_Performance = parse_json(ASHA_Performance)
                | extend ThumbsDown_p100k = parse_json(ThumbsDown_p100k)
                | extend M1Retention_Pct = parse_json(M1Retention_Pct)
                | extend SeenRate = parse_json(SeenRate)
                | extend KeepRate = parse_json(KeepRate)
                | extend TriedRate = parse_json(TriedRate)
                | extend Intensity = parse_json(Intensity)
                | extend MTE_Weighted_Avg = parse_json(MTE_Weighted_Avg)
                | extend Performance_FCFR_P50 = parse_json(Performance_FCFR_P50)
                | extend Performance_FCFR_P95 = parse_json(Performance_FCFR_P95)
                | extend RAI_Blocked_pct = parse_json(RAI_Blocked_pct)
                | extend Currency_Pct = parse_json(Currency_Pct)
                | extend NPS_NetPromoterScore = parse_json(NPS_NetPromoterScore)
                | project OmsTenantId,
                    Health_Score = round(Health_Score,2),
                    ASHA_Score = round(todouble(ASHA_Overall[app]), 2),
                    Performance_Score = round(todouble(ASHA_Performance[app]), 2),
                    SATPct_Score = round(todouble(ThumbsDown_p100k[app]), 2),
                    M1Retention_Score = round(todouble(M1Retention_Pct[app]), 2), 
                    KeepRate_Score = round(todouble(KeepRate[app]), 2),
                    SeenRate_Score = round(todouble(SeenRate[app]), 2), 
                    TriedRate_Score = round(todouble(TriedRate[app]), 2),
                    Intensity_Score = round(todouble(Intensity[app]), 2),
                    MTE_Score = round(todouble(MTE_Weighted_Avg[app]), 2),
                    FCFRP50_Score = round(todouble(Performance_FCFR_P50[app]), 2),
                    FCFRP95_Score = round(todouble(Performance_FCFR_P95[app]), 2),
                    RAIBlockRate_Score = round(todouble(RAI_Blocked_pct[app]), 2),
                    Currency_Score = round(todouble(Currency_Pct[app]), 2),
                    NPS_Score = round(todouble(NPS_NetPromoterScore[app]), 2)
        ) on \${levelColumnName}
    | join kind = inner (PastStatusSet) on \${levelColumnName}
    | project PastStatus, MoMScore = round(Health_Score - Health_Score1 ,2), 
        ASHARate = round(100-ASHA_Score,2), Color_ASHARate = ASHA_Score_Color, MoM_ASHARate = -1 * round(ASHA_Score - ASHA_Score1,2),
        PerformanceRate = round(Performance_Score,2), Color_PerformanceRate = Performance_Score_Color, MoM_PerformanceRate = round(Performance_Score - Performance_Score1,2),
        FCFRP50 = round(FCFRP50_Score,2), Color_FCFRP50 = FCFRP50_Score_Color, MoM_FCFRP50 = round(FCFRP50_Score-FCFRP50_Score1,2),
        FCFRP95 = round(FCFRP95_Score,2), Color_FCFRP95 = SATPct_Score_Color, MoM_FCFRP95 = round(FCFRP95_Score-FCFRP95_Score1,2),
        // RAIBlockRate = round(RAIBlockRate_Score,2), Color_RAIBlockRate = RAIBlockRate_Score_Color, MoM_RAIBlockRate = round(RAIBlockRate_Score-RAIBlockRate_Score1,2),
        Currency = round(Currency_Score,2), Color_Currency = Currency_Score_Color, MoM_Currency = round(Currency_Score-Currency_Score1,2),
        SATPctRate = round(SATPct_Score,2), Color_SATPctRate = SATPct_Score_Color, MoM_SATPctRate = round(SATPct_Score-SATPct_Score1,2),
        M1Retention = round(M1Retention_Score,2), Color_M1Retention = M1Retention_Score_Color, MoM_M1Retention = round(M1Retention_Score-M1Retention_Score1,2),
        Intensity = round(Intensity_Score,2), Color_Intensity = Intensity_Score_Color, MoM_Intensity = round(Intensity_Score-Intensity_Score1,2),
        SeenRate = round(SeenRate_Score,2), Color_SeenRate = SeenRate_Score_Color, MoM_SeenRate = round(SeenRate_Score-SeenRate_Score1,2),
        TriedRate = round(TriedRate_Score,2), Color_TriedRate = TriedRate_Score_Color, MoM_TriedRate = round(TriedRate_Score-TriedRate_Score1,2),
        KeepRate = round(KeepRate_Score,2), Color_KeepRate = KeepRate_Score_Color, MoM_KeepRate = round(KeepRate_Score-KeepRate_Score1,2),
        MTE = round(MTE_Score,2), Color_MTE = MTE_Score_Color, MoM_MTE = round(MTE_Score-MTE_Score1,2),
        NPS = round(NPS_Score,2), Color_NPS = NPS_Score_Color, MoM_NPS = round(NPS_Score-NPS_Score1,2),
        HealthColor = Health_Score_Color, Score = round(Health_Score,2), Application = app
    `;

export const copilotReadinessSparkLines: string = `
    // Copilot Readiness Sparklines
    let currDate = todatetime("\${date}");
    let levelName = "\${level}";
    let oldestDate = datetime_add('month',-17,currDate);
    ReadinessScore
    | where \${levelColumnName} == "\${id}"
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where todatetime(Date) between (oldestDate .. currDate)
    | project Date, OneDrivePctRate = round(OneDrive_Pct, 2), OneDrivePctRate_Color = OneDrive_Color,
        MIPEnabledPctRate = round(MIPEnabled_Pct, 2), MIPEnabledPctRate_Color = DataProtec_Color,
        CopilotEnabledPctRate = round(Copilot_Enabled_pct, 2), CopilotEnabledPctRate_Color = Enabled_Color,
        SPOLabelling = SPO_Labelling, SPOLabelling_Color = DataProtec_Color, 
        CollabProtection = Collab_Protection, CollabProtection_Color = DataProtec_Color, 
        FbkAndLogsONPctScore = round(FbkAndLogsON_pct, 2), FbkAndLogsONPctScore_Color = FbkAndLogsON_Color,
        FbkSurveyONPctScore = round(Fdbck_SurveyON_pct, 2), FbkSurveyONPctScore_Color = FdbkSurvey_Color,
        ODDRate = round(ODD_Diagnostics_Rate, 2), ODDRate_Color = OffDiagnostic_Color,
        ConnectedExperienceRate = round(ConnectedExperience_Rate, 2), ConnectedExperienceRate_Color = ConnectedExp_Color,
        ChannelReadinessRate = round(ChannelReadiness_Rate, 2), ChannelReadinessRate_Color = ChannelReadiness_Color
    | sort by Date asc
    `;

export const copilotHealthSparkLines: string = `
    // Copilot Health Sparklines
    let currDate = todatetime("\${date}");
    let levelName = "\${level}";
    let app = "\${application}";
    let plat = "\${platform}";
    let oldestDate = datetime_add('month',-17,currDate);
    HealthScore
    | where \${levelColumnName} == "\${id}" and Application == app and Platform == plat
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where todatetime(Date) between (oldestDate .. currDate)
    | extend ASHA_Overall = parse_json(ASHA_Overall)
    | extend ASHA_Overall_Color = parse_json(ASHA_Overall_Color)
    | extend ASHA_Performance = parse_json(ASHA_Performance)
    | extend ASHA_Performance_Color = parse_json(ASHA_Performance_Color)
    | extend ThumbsDown_p100k = parse_json(ThumbsDown_p100k)
    | extend ThumbsDown_p100k_Color = parse_json(ThbDw_100k_Color)
    | extend M1Retention_Pct = parse_json(M1Retention_Pct)
    | extend M1Retention_Color = parse_json(M1Retention_Color)
    | extend SeenRate = parse_json(SeenRate)
    | extend SeenRate_Color = parse_json(SeenRate_Color)
    | extend KeepRate = parse_json(KeepRate)
    | extend KeepRate_Color = parse_json(KeepRate_Color)
    | extend TriedRate = parse_json(TriedRate)
    | extend TriedRate_Color = parse_json(TriedRate_Color)
    | extend Intensity = parse_json(Intensity)
    | extend Intensity_Color = parse_json(Intensity_Color)
    | extend MTE_Weighted_Avg = parse_json(MTE_Weighted_Avg)
    | extend MTE_Color = parse_json(MTE_Color)
    | extend Performance_FCFR_P50 = parse_json(Performance_FCFR_P50)
    | extend FCFR_P50_Color = parse_json(FCFR_P50_Color)
    | extend Performance_FCFR_P95 = parse_json(Performance_FCFR_P95)
    | extend FCFR_P95_Color = parse_json(FCFR_P95_Color)
    | extend RAI_Blocked_pct = parse_json(RAI_Blocked_pct)
    | extend RAI_Blocked_Color = parse_json(RAI_Blocked_Color)
    | extend Currency_Pct = parse_json(Currency_Pct)
    | extend Currency_Color = parse_json(Currency_Color)
    | extend NPS_NetPromoterScore = parse_json(NPS_NetPromoterScore)
    | extend NPS_Color = parse_json(NPS_Color)
    | project Date,
        ASHA_Score = round(todouble(ASHA_Overall[app]), 2), ASHA_Score_Color = ASHA_Overall_Color[app],
        Performance_Score = round(todouble(ASHA_Performance[app]), 2), Performance_Score_Color = ASHA_Performance_Color[app],
        FCFRP50_Score = round(todouble(Performance_FCFR_P50[app]), 2), FCFRP50_Score_Color = FCFR_P50_Color[app],
        FCFRP95_Score = round(todouble(Performance_FCFR_P95[app]), 2), FCFRP95_Score_Color = FCFR_P95_Color[app],
        RAIBlockRate_Score = round(todouble(RAI_Blocked_pct[app]), 2), RAIBlockRate_Score_Color = RAI_Blocked_Color[app],
        Currency_Score = round(todouble(Currency_Pct[app]), 2), Currency_Score_Color = Currency_Color[app],
        SATPct_Score = round(todouble(ThumbsDown_p100k[app]), 2), SATPct_Score_Color = ThumbsDown_p100k_Color[app],
        M1Retention_Score = round(todouble(M1Retention_Pct[app]), 2), M1Retention_Score_Color = M1Retention_Color[app],
        KeepRate_Score = round(todouble(KeepRate[app]), 2), KeepRate_Score_Color = KeepRate_Color[app],
        SeenRate_Score = round(todouble(SeenRate[app]), 2), SeenRate_Score_Color = SeenRate_Color[app],
        TriedRate_Score = round(todouble(TriedRate[app]), 2), TriedRate_Score_Color = TriedRate_Color[app],
        Intensity_Score = round(todouble(Intensity[app]), 2), Intensity_Score_Color = Intensity_Color[app],
        MTE_Score = round(todouble(MTE_Weighted_Avg[app]), 2), MTE_Score_Color = MTE_Color[app],
        NPS_Score = round(todouble(NPS_NetPromoterScore[app]), 2), NPS_Score_Color = NPS_Color[app]
    | project Date, ASHARate = round(100-ASHA_Score,2), ASHARate_Color = ASHA_Score_Color,
        PerformanceRate = round(Performance_Score,2), PerformanceRate_Color  = Performance_Score_Color,
        FCFRP50 = round(FCFRP50_Score,2), FCFRP50_Color  = FCFRP50_Score_Color,
        FCFRP95 = round(FCFRP95_Score,2), FCFRP95_Color  = FCFRP95_Score_Color,
        // RAIBlockRate = round(RAIBlockRate_Score,2), RAIBlockRate_Color  = RAIBlockRate_Score_Color,
        Currency = round(Currency_Score,2), Currency_Color  = Currency_Score_Color,
        SATPctRate = round(SATPct_Score,2), SATPctRate_Color = SATPct_Score_Color,
        M1Retention = round(M1Retention_Score,2), M1Retention_Color  = M1Retention_Score_Color,
        SeenRate = round(SeenRate_Score,2), SeenRate_Color  = SeenRate_Score_Color,
        KeepRate = round(KeepRate_Score,2), KeepRate_Color  = KeepRate_Score_Color,
        TriedRate = round(TriedRate_Score,2), TriedRate_Color  = TriedRate_Score_Color,
        Intensity = round(Intensity_Score,2), Intensity_Color  = Intensity_Score_Color,
        MTE = round(MTE_Score,2), MTE_Color  = MTE_Score_Color,
        NPS = round(NPS_Score,2), NPS_Color  = NPS_Score_Color
    | sort by Date asc
    `;

export const copilotHealthSparkLinesM365Chat: string = `
    // Copilot Health Sparklines
    let currDate = todatetime("\${date}");
    let levelName = "\${level}";
    let app = "\${application}";
    let oldestDate = datetime_add('month',-17,currDate);
    let HealthScore_M365ChatUnpivoted = HealthScore_M365Chat
    | where \${levelColumnName} == "\${id}"
    | where iff(levelName == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where todatetime(Date) between (oldestDate .. currDate);
    HealthScore_M365ChatUnpivoted  
    | project Date,
        MAURate_Score = round(todouble(MAURate), 2), MAURate_Score_Color = MAURate_ColorCode,
        Availability_Score = round(todouble(AvailabilityRate), 2), Availability_Score_Color = Availability_ColorCode,
        FCFR_P75_Score = round(todouble(FCFR_P75), 2), FCFR_P75_Score_Color = FCFR_ColorCode,
        LCFR_P75_Score = round(todouble(LCFR_P75), 2), LCFR_P75_Score_Color = LCFR_ColorCode,   
        QPUU_Score = round(todouble(QueryCountPerUniqueUser), 2), QPUU_Score_Color = Qry_pUser_ColorCode,
        M1Retention_Score = round(todouble(RetentionRate), 2), M1Retention_Score_Color = Retention_ColorCode,    
        CitationRate_Score = round(todouble(CitationRate), 2), CitationRate_Score_Color = Citation_ColorCode ,
        SATRate_Score = round(todouble(SATRate), 2), SATRate_Score_Color = SATRate_ColorCode
    | project Date, MAURate = round(todouble(MAURate_Score), 2), MAURate_Color = MAURate_Score_Color,
        Availability = round(todouble(Availability_Score), 2), Availability_Color = Availability_Score_Color,
        FCFR_P75 = round(todouble(FCFR_P75_Score), 2), FCFR_P75_Color = FCFR_P75_Score_Color,
        LCFR_P75 = round(todouble(LCFR_P75_Score), 2), LCFR_P75_Color = LCFR_P75_Score_Color,   
        QPUU = round(todouble(QPUU_Score), 2), QPUU_Color = QPUU_Score_Color,
        M1Retention = round(todouble(M1Retention_Score), 2), M1Retention_Color = M1Retention_Score_Color,    
        CitationRate = round(todouble(CitationRate_Score), 2), CitationRate_Color = CitationRate_Score_Color,
        SATRate = round(todouble(SATRate_Score), 2), SATRate_Color = SATRate_Score_Color
    | sort by Date asc;
    `;

export const copilotTenantAppCurrencyAndLicenseData: string = `
    // Copilot Tenant App Currency And License Data
    let win32Date = datetime_add('month', 1, todatetime("\${date}")); // because win32 date format is yyyy-MM-28
    database('TenantInsights').License
    | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where \${levelColumnName} == "\${id}" and todatetime(Date) <= win32Date
    | where Value > 0
    | extend Tpid = tolong(Tpid)
    | union (
            database('TenantInsights').TenantInsights_Win32MiscellaneousMetrics
            | where iff("\${levelColumnName}" == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
            | where \${levelColumnName} == "\${id}" and todatetime(Date) <= win32Date
            | where Metric in ("Currency", "OfficeDiagnosticConsentLevel")
            | where Value > 0
            | extend Value = iff(Metric == "Currency", round(Value * Total), Value)
            | extend Metric = iff(Metric == "Currency", "AppCurrency", Metric)
        )
    | order by Date asc, Metric, Application, Value
    `;
export const copilotAppMetrics: string = `
    // Copilot App Metrics
    // If WXP rollup is available for a metric, use that instead of All rollup.
    let applicationArray = dynamic(["Word", "Excel", "PowerPoint", "All"]);
    let plat = "\${platform}";
    let lvl = "\${level}";
    let procesDt = "\${date}";
    let id = "\${id}";
    let AppMetrics = CopilotDrilldownAppMetrics
        | where iff(lvl == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where \${levelColumnName} == id and Date == procesDt
        | where Platform == plat and Application in (applicationArray) and Feature == "All" and Verb == "All" and DateFrequency == "Monthly"       
        | project Date, Application, MetricName, MetricValue
            | join kind=inner CopilotAppMetricsGroupMapping on MetricName
            | where (IsTenantLevel and Application == "All") or (Application != "All" and not(IsTenantLevel))
            | extend MetricValue = iff(MetricName == "ASHA", 100 - MetricValue, MetricValue)
            | extend MetricValue = iff(MetricName in ("ISSPOSensitivityEnabled", "IsCollabSensitivityEnabled"), MetricValue * 100, MetricValue)
            | project Date, Metric = MetricName, DisplayName, Group, Application, Value = tostring(MetricValue), IsTenantLevel, GroupOrder, MetricOrder;
    let copilotAppMetrics = AppMetrics
    | union (AppMetrics
        | where IsTenantLevel == false
        | distinct Date, Metric, DisplayName, Group, Application = "All", Value = "NA", GroupOrder, MetricOrder)        
        | order by GroupOrder asc, MetricOrder asc, DisplayName asc
        | project Date, Metric, DisplayName, Group, Application, Value, GroupOrder , MetricOrder
        ;
    
        
    let bizChatSATMetrics = BizchatMetrics
        | where Date == procesDt and \${levelColumnName} == id
        | where Endpoint == "All" and Scenario == "All" and SubScenario == "All"
        | where MetricName == "SATRate"
        | project Date, MetricName, MetricValue
        | join kind=inner CopilotAppMetricsGroupMapping on MetricName
        | extend Value = iff(isnull(MetricValue), "NA", tostring(MetricValue))
        | extend Application = "M365 Chat"
        | order by GroupOrder asc, MetricOrder asc, DisplayName asc
        | project Date = format_datetime(Date,'yyyy-MM-dd'), Metric = MetricName, DisplayName, Group, Application, Value = tostring(MetricValue), GroupOrder , MetricOrder;

        let bizChatMetrics = BizchatMetrics
        | where Date == procesDt and \${levelColumnName} == id
        | where Endpoint == "All" and Scenario == "Paid" and SubScenario == "Work"
        | project Date, MetricName, MetricValue
        | join kind=inner CopilotAppMetricsGroupMapping on MetricName
        | extend MetricValue = iff(MetricName in ("CitationRate", "M1Retention", "Availability"), 100 * MetricValue, MetricValue)
        | extend Value = iff(isnull(MetricValue), "NA", tostring(MetricValue))
        | extend Application = "M365 Chat"
        | order by GroupOrder asc, MetricOrder asc, DisplayName asc
        | project Date = format_datetime(Date,'yyyy-MM-dd'), Metric = MetricName, DisplayName, Group, Application, Value = tostring(MetricValue), GroupOrder , MetricOrder;

    let unionedMetrics = copilotAppMetrics
    | union (bizChatMetrics)
    | union (bizChatSATMetrics);

    unionedMetrics
    | project Date, Metric , DisplayName, Group, Application, Value, GroupOrder , MetricOrder  
    | order by GroupOrder asc, MetricOrder asc, DisplayName asc
    | project-away GroupOrder, MetricOrder;
`;

export const copilotAppMetricsChartData: string = `
    // Copilot App Metrics Chart Data
    let applicationArray = dynamic(["Word", "Excel", "PowerPoint", "All"]);
    let plat = "\${platform}";
    let lvl = "\${level}";
    let id = "\${id}";
    let metric = "\${metric}";
    let copilotMetrics = CopilotDrilldownAppMetrics
        | where iff(lvl == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where \${levelColumnName} == id
        | where Platform == plat and Application in (applicationArray) and Feature == "All" and Verb == "All" and DateFrequency == "Monthly" and MetricName == metric
        | join kind=inner CopilotAppMetricsGroupMapping on MetricName
            | where (IsTenantLevel and Application == "All") or (Application != "All" and not(IsTenantLevel))
            | extend MetricValue = iff(MetricName == "ASHA", 100 - MetricValue, MetricValue)
            | project Date, Application, Value = round(MetricValue,2)
            | order by Date asc;
            
     let bizChatSATMetrics = BizchatMetrics
        | where iff(lvl == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where \${levelColumnName} == id
        | where Endpoint == "All" and Scenario == "All" and SubScenario == "All"
        | where MetricName == "SATRate"
        | where MetricName == metric 
        | extend Application = "M365 Chat"
        | project Date = format_datetime(Date,'yyyy-MM-dd'), Application, Value = round(MetricValue,2)
        | order by Date asc;

    let bizChatMetrics = BizchatMetrics
        | where iff(lvl == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where \${levelColumnName} == id
        | where Endpoint == "All" and Scenario == "Paid" and SubScenario == "Work"
        | where MetricName == metric 
        | extend Application = "M365 Chat"
        | extend MetricValue = iff(MetricName in ("CitationRate", "M1Retention", "Availability"), 100 * MetricValue, MetricValue)
        | project Date = format_datetime(Date,'yyyy-MM-dd'), Application, Value = round(MetricValue,2)
        | order by Date asc;

    let unionedMetrics = copilotMetrics
    | union (bizChatMetrics)
    | union (bizChatSATMetrics);

    unionedMetrics | order by Date asc;   
    `;

export const copilotTenantEnvironmentData: string = `
    // Copilot Tenant Environment Data
    let plat = "\${platform}";
    let processDt = "\${date}";
    let lvl = "\${level}";
    let startDt = todatetime(processDt);
    let endDt =  datetime_add('month',1, todatetime(processDt));
    CopilotEnvironmentMetrics
    | where iff(lvl == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
    | where MetricName == "Location" and \${levelColumnName} == "\${id}" and Platform == plat and Feature == "All" and Verb == "All" and Date == processDt and isnotempty(UnitName)
    | project Date, Application, MetricName, UnitName, Value, Total
    | union (
        CopilotEnvironmentMetrics
        | where iff(lvl == "Tpid", OmsTenantId == "All", OmsTenantId != "All")
        | where MetricName in ("DAU", "WAU") and \${levelColumnName} == "\${id}" and Platform == plat and Feature == "All" and Verb == "All"
        | where todatetime(Date) between (startDt .. endDt)
        | project Date, Application, MetricName, UnitName, Value, Total
        )
    `;

export const copilotHealthScoreTenantList: string = `
    // Copilot Health Score Tenant List
    let plat = "\${platform}";
    let currDate = "\${date}";
    let currentDate = GetLatestDate();
    let minCoPilotLicenses = 10;
    HealthScore | where Date == currDate and Platform == plat and Health_Color != "Gray"  and Tpid != 0 and OmsTenantId != "All" and CoPilot_Licenses > minCoPilotLicenses
        | project Color = iff(isempty(Health_Color), "Gray" , Health_Color), MAU = iff(isempty(CoPilot_Licenses), tolong(0), tolong(CoPilot_Licenses)), OmsTenantId, Tpid, Score = iff(isempty(Health_Score), 0.0, Health_Score)
        | sort by Tpid asc, OmsTenantId asc, Score desc
        | extend Rank = row_number(1, prev(OmsTenantId) != OmsTenantId)
        | where Rank  == 1 // Pick only the entry/color where score is highest
        | project Color, MAU, OmsTenantId, Tpid, Score
        | join kind = inner (
            database('TenantInsights').StrategicTenants
            | where Date == currentDate and isnotempty(OrgName_Translated) and isnotempty(TenantName_Translated)
            | distinct Tpid, OmsTenantId, OrgName_Translated,TenantName_Translated, IsS500, IsS2500, IsEPA, IsGoogleMove, IsECAP, IsSMB, IsSMC, IsGov, IsOther
            ) on OmsTenantId
        | order by Tpid asc, Score desc , MAU desc
        | project Tpid, OrgName_Translated, TenantId = OmsTenantId, TenantName_Translated, TpidStatus= "", OmsStatus = Color, IsS500, IsS2500, IsEPA, IsGoogleMove, IsECAP, IsSMB, IsSMC, IsGov, IsOther
    `;

export const copilotReadinessScoreTenantList: string = `
    // Copilot Readiness Score Tenant List
    let plat = "\${platform}";
    let currDate = "\${date}";
    let currentDate = GetLatestDate();
    let minCoPilotLicenses = 10;
    ReadinessScore | where Date == currDate and Readiness_Color != "Gray"  and Tpid != 0 and OmsTenantId != "All" and CoPilot_Licenses > minCoPilotLicenses
        | project Color = iff(isempty(Readiness_Color), "Gray" , Readiness_Color), MAU = iff(isempty(CoPilot_Licenses), tolong(0), tolong(CoPilot_Licenses)), OmsTenantId, Tpid, Score = iff(isempty(Readiness_Score), 0.0, Readiness_Score)
        | sort by Tpid asc, OmsTenantId asc, Score desc
        | extend Rank = row_number(1, prev(OmsTenantId) != OmsTenantId)
        | where Rank  == 1 // Pick only the entry/color where score is highest
        | project Color, MAU, OmsTenantId, Tpid, Score
        | join kind = inner (
            database('TenantInsights').StrategicTenants
            | where Date == currentDate and isnotempty(OrgName_Translated) and isnotempty(TenantName_Translated)
            | distinct Tpid, OmsTenantId, OrgName_Translated,TenantName_Translated, IsS500, IsS2500, IsEPA, IsGoogleMove, IsECAP, IsSMB, IsSMC, IsGov, IsOther
            ) on OmsTenantId
        | order by Tpid asc, Score desc , MAU desc
        | project Tpid, OrgName_Translated, TenantId = OmsTenantId, TenantName_Translated, TpidStatus= "", OmsStatus = Color, IsS500, IsS2500, IsEPA, IsGoogleMove, IsECAP, IsSMB, IsSMC, IsGov, IsOther
    `;

export const copilotHealthCohortAvg: string = `
    // Health score cohort avg
    let currentDate = GetLatestDate();
    database('TenantInsights').StrategicTenants
    | where Date == currentDate
    | where \${levelColumnName} == "\${id}"
    | sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsECAP
    | take 1
    | project Cohorts = iff(IsS2500 and IsS500 , replace_string(Cohorts, "S2500;",""), Cohorts) // if tenant is both S500 and S2500 then only show avg for S500
    | project allcohorts = split(Cohorts,';')
    | project iff(array_length(allcohorts) == 1, pack_array("S500"), allcohorts) // case when does not belong to any other (just OXOPriority)
    | mv-expand Cohort = Column1
    | project Cohort = tostring(Cohort)
    | join kind=inner(
        CopilotHealthCohortAvg | where Platform == "\${platform}" and AppName == "\${application}" and Level == "\${level}" and Date == "\${date}"
            | where Cohort !in ("M365 Copilot Program", "All", "OXOPriorityTenants")  // We don't want to show these cohorts
    ) on Cohort
    | project
            Cohort,
            ASHARate = round(100 - ASHA_Overall,2), 
            SATPctRate = round(ThumbsDown_p100k, 2), 
            PerformanceRate = round(ASHA_Performance, 2),
            M1Retention = round(M1Retention_Pct,2),
            SeenRate = round(SeenRate,2),
            KeepRate = round(KeepRate,2), 
            TriedRate = round(TriedRate,2), 
            Intensity = round(Intensity,2), 
            MTE = round(MTE_Weighted_Avg,2),
            FCFRP50 = round(Performance_FCFR_P50,2),
            FCFRP95 = round(Performance_FCFR_P95,2), 
            // RAIBlockRate = round(RAI_Blocked_pct,2),
            Currency = round(Currency_Pct,2),
            NPS = round(NPS_NetPromoterScore,2)
    `;

export const copilotHealthCohortAvgM365Chat: string = `
    // Health score cohort avg
    let currentDate = GetLatestDate();
    let HealthScoreTestUnpivoted = CopilotHealthCohortAvg_M365Chat
    | where Endpoint == "All" and Date == "\${date}" and Level == "\${level}";
    database('TenantInsights').StrategicTenants
    | where Date == currentDate
    | where \${levelColumnName} == "\${id}"
    | sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsECAP
    | take 1
    | project Cohorts = iff(IsS2500 and IsS500 , replace_string(Cohorts, "S2500;",""), Cohorts) // if tenant is both S500 and S2500 then only show avg for S500
    | project allcohorts = split(Cohorts,';')
    | project iff(array_length(allcohorts) == 1, pack_array("S500"), allcohorts) // case when does not belong to any other (just OXOPriority)
    | mv-expand Cohort = Column1
    | project Cohort = tostring(Cohort)
    | join kind=inner(
        HealthScoreTestUnpivoted 
        | where Cohort !in ("M365 Copilot Program", "All", "OXOPriorityTenants")  // We don't want to show these cohorts
    ) on Cohort
    | project
            Cohort,
            Availability = round(AvailabilityRate, 2),
            CitationRate = round(CitationRate, 2),
            SatRate = round(SATRate, 2),
            FCFR_P75 = round(FCFR_P75, 2),
            LCFR_P75 = round(LCFR_P75, 2),
            MAURate = round(MAURate, 2),
            QPUU = round(QueryCountPerUniqueUser, 2),
            M1Retention = round(RetentionRate, 2);
    `;

export const copilotReadinessCohortAvg: string = `
    // Readiness score cohort avg
    let currentDate = GetLatestDate();
    database('TenantInsights').StrategicTenants
    | where Date == currentDate
    | where \${levelColumnName} == "\${id}"
    | sort by IsS2500, IsS500, IsEPA, IsGoogleMove, IsECAP
    | take 1
    | project Cohorts = iff(IsS2500 and IsS500 , replace_string(Cohorts, "S2500;",""), Cohorts) // if tenant is both S500 and S2500 then only show avg for S500
    | project allcohorts = split(Cohorts,';')
    | project iff(array_length(allcohorts) == 1, pack_array("S500"), allcohorts) // case when does not belong to any other (just OXOPriority)
    | mv-expand Cohort = Column1
    | project Cohort = tostring(Cohort)
    | join kind=inner(
        CopilotReadinessCohortAvg | where Level == "\${level}" and Date == "\${date}"
            | where Cohort !in ("M365 Copilot Program", "All", "OXOPriorityTenants")  // We don't want to show these cohorts
        ) on Cohort
    | project
        Cohort,
        OneDrivePctRate = round(OneDrive_Pct,2),
        MIPEnabledPctRate = round(MIPEnabled_Pct,2),
        CopilotEnabledPctRate = round(Copilot_Enabled_pct, 2),
        SPOLabelling = SPO_Labelling, 
        CollabProtection = Collab_Protection, 
        FbkAndLogsONPctScore = round(FbkAndLogsON_pct, 2),
        FbkSurveyONPctScore = round(Fdbck_SurveyON_pct, 2),
        ODDRate = round(ODD_Diagnostics_Rate, 2),
        ConnectedExperienceRate = round(ConnectedExperience_Rate, 2),
        ChannelReadinessRate = round(ChannelReadiness_Rate, 2)
    `;
