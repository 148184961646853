import React from "react";
import { Stack } from "@fluentui/react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderTitle,
} from "components/CustomComponents/Card/Card";
import "components/CommonFunnelComponents/CommonFunnelStandaloneMetrics/styles.css";
import { getCompactValue } from "pages/ConsumerDay0Funnel/ConsumerDay0FunnelHelper";

export const PercentageStandAloneMetric = ({
    metricName,
    numeratorText,
    denominatorText,
    numerator,
    denominator,
}) => {
    const formattedNumerator = numerator > 0 ? getCompactValue(numerator) : 0;
    const formattedDenominator = denominator > 0 ? getCompactValue(denominator) : 0;
    const formattedPercentage =
        numerator > 0 && denominator > 0
            ? ((numerator / denominator) * 100).toFixed(2)
            : 0;

    return (
        <Card width={350}>
            <CardHeader>
                <CardHeaderTitle>{metricName}</CardHeaderTitle>
            </CardHeader>
            <CardBody>
                <Stack
                    horizontal
                    horizontalAlign="center"
                    verticalAlign="center"
                    tokens={{ childrenGap: 15 }}
                >
                    <Stack.Item>
                        <h1>{`${formattedPercentage}%`}</h1>
                    </Stack.Item>
                    <Stack.Item className="fraction">
                        <Stack horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                            <Stack.Item title={`${numerator} ${numeratorText}`}>
                                {`${formattedNumerator} ${numeratorText}`}
                            </Stack.Item>
                            <div className="vinculum"></div>
                            <Stack.Item title={`${denominator} ${denominatorText}`}>
                                {`${formattedDenominator} ${denominatorText}`}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </CardBody>
        </Card>
    );
};
