export const erfmDates = `
// ERFM Dates
database('ConsumerInsights').eRFMMAUCAMAU
| summarize by format_datetime(Date, "yyyy-MM-dd")
| order by Date desc
    `;

export const erfmTable = `
// ERFM Table
let validPlatforms = dynamic(["Win32", "All", "Web"]);
let retentiontableM1 = database('ConsumerInsights').eRFMRetentionRates 
| where CohortEndDate == datetime(\${date})
| where Metric == 'M1' and Country == "\${country}" and Platform  in (validPlatforms)
| extend M1 = Value 
| project Date, CohortEndDate, CohortStartDate, Application, Country, M1, Platform, Segment;
let retentiontableM2 = database('ConsumerInsights').eRFMRetentionRates 
| where CohortEndDate == datetime(\${date})
| where Metric == 'M2' and Country == "\${country}" and Platform  in (validPlatforms)
| extend M2 = Value 
| project Date, CohortEndDate, CohortStartDate, Application, Country, M2, Platform, Segment;
let retentiontableM3 = database('ConsumerInsights').eRFMRetentionRates 
| where CohortEndDate == datetime(\${date})
| where Metric == 'M3' and Country == "\${country}" and Platform  in (validPlatforms)
| extend M3 = Value 
| project Date, CohortEndDate, CohortStartDate, Application, Country, M3, Platform, Segment;
let retentiontable_agg = retentiontableM1
| join kind= leftouter  retentiontableM2 on Application, CohortEndDate, Country, Platform, Segment;
let retentiontable_all = retentiontable_agg
| join kind= leftouter retentiontableM3 on Application, CohortEndDate, Country, Platform, Segment
| project Date, CohortEndDate, CohortStartDate, Application, Country, M1, M2, M3, Platform, Segment
| extend Application = iff(Application == 'All', 'WXP', Application);
database('ConsumerInsights').eRFMMAUCAMAU
| where Date == datetime(\${date})  and Country == "\${country}" and Platform in (validPlatforms)
| extend Application = iff(Application == 'All', 'WXP', Application)
| extend Value = round(Value, 6)
| evaluate pivot(Metric, sum(Value))
| join kind=leftouter retentiontable_all on Application, Country, Platform, Segment
| project Date = format_datetime(Date, "yyyy-MM-dd"), Application, Segment,
column_ifexists("MAU", ""), column_ifexists("CAMAU", ""), column_ifexists("PercentCAMAU", ""), 
column_ifexists("M1", ""), 
column_ifexists("M2", ""), 
column_ifexists("M3", ""), 
column_ifexists("PercentConvertedUsers", ""), 
column_ifexists("UAECrashRate", ""), 
column_ifexists("WatsonCrashRate", "");
`;

export const erfmAppChart = `
// ERFM App Chart
let validPlatforms = dynamic(["Win32", "All", "Web"]);
let endDate = toscalar(database('ConsumerInsights').eRFMMAUCAMAU
| summarize max(Date));
let startDate = datetime_add('month', -6, endDate);
let validApplication = dynamic(['Excel', 'PowerPoint', 'Word', 'All']);
let mau_camau = database('ConsumerInsights').eRFMMAUCAMAU
| where Date between (startDate .. endDate) and Application in (validApplication) and Country == 'All'
| extend Application = iff(Application == 'All', 'WXP', Application)
| extend Value = round(Value, 2)
| evaluate pivot(Application, sum(Value))
| project-away Platform, Country
| extend Date = format_datetime(Date, "yyyy-MM-dd")
| sort by Segment asc, Metric asc, Date asc;
let retentiontableM1 = database('ConsumerInsights').eRFMRetentionRates 
| where Metric == 'M1' and Country == 'All' and Platform  in (validPlatforms) and todatetime( CohortEndDate) between (startDate .. endDate)
| project Date, CohortEndDate, Application, Country, Metric, Platform, Segment, Value
| project Date = CohortEndDate, Application, Country, Metric, Platform, Segment,Value;
let retentiontableM2 = database('ConsumerInsights').eRFMRetentionRates 
| where Metric == 'M2' and Country == 'All' and Platform  in (validPlatforms) and todatetime( CohortEndDate) between (startDate .. endDate)
| project Date, CohortEndDate, Application, Country, Metric, Platform, Segment,Value
| project Date = CohortEndDate, Application, Country, Metric, Platform, Segment,Value;
let retentiontableM3 = database('ConsumerInsights').eRFMRetentionRates 
| where Metric == 'M3' and Country == 'All' and Platform  in (validPlatforms) and todatetime( CohortEndDate) between (startDate .. endDate)
| project Date, CohortEndDate, Application, Country, Metric, Platform, Segment,Value
| project Date = CohortEndDate, Application, Country, Metric, Platform, Segment,Value;
let erfm_retention = union retentiontableM1, retentiontableM2, retentiontableM3 
| extend Application = iff(Application == 'All', 'WXP', Application)
| extend Value = round(Value, 2)
| evaluate pivot(Application, sum(Value))
| project-away Platform, Country
| extend Date = format_datetime(Date, "yyyy-MM-dd")
| sort by Segment asc, Metric asc, Date asc;
union mau_camau, erfm_retention;
`;

export const erfmCountryChart = `
// ERFM Country Chart
let endDate = toscalar(database('ConsumerInsights').eRFMMAUCAMAU
| summarize max(Date));
let startDate = datetime_add('month', -6, endDate);
let validCountry = dynamic(["US", "GB", "MX", "KR", "IN", "NZ"]);
let retentiontableM1 = database('ConsumerInsights').eRFMRetentionRates 
| where Metric == 'M1' and Country  in (validCountry) and todatetime( CohortEndDate) between (startDate .. endDate)
| project Date, CohortEndDate, Application, Country, Metric, Platform, Segment, Value
| project Date = CohortEndDate, Application, Country, Metric, Platform, Segment,Value;
let retentiontableM2 = database('ConsumerInsights').eRFMRetentionRates 
| where Metric == 'M2' and Country  in (validCountry) and todatetime( CohortEndDate) between (startDate .. endDate)
| project Date, CohortEndDate, Application, Country, Metric, Platform, Segment, Value
| project Date = CohortEndDate, Application, Country, Metric, Platform, Segment,Value;
let retentiontableM3 = database('ConsumerInsights').eRFMRetentionRates 
| where Metric == 'M3' and Country  in (validCountry) and todatetime( CohortEndDate) between (startDate .. endDate)
| project Date, CohortEndDate, Application, Country, Metric, Platform, Segment, Value
| project Date = CohortEndDate, Application, Country, Metric, Platform, Segment,Value;
let all_retention = union retentiontableM1, retentiontableM2, retentiontableM3 
| extend Application = iff(Application == "All", "WXP", Application)
| extend Value = round(Value, 2)
| evaluate pivot(Country, sum(Value))
| project-away Platform
| extend Date = format_datetime(Date, "yyyy-MM-dd")
| sort by Segment asc, Metric asc, Date asc;
let appcountry = database('ConsumerInsights').eRFMMAUCAMAU
| where Date between (startDate .. endDate) and Country in (validCountry)
| extend Application = iff(Application == "All", "WXP", Application)
| extend Value = round(Value, 2)
| evaluate pivot(Country, sum(Value))
| project-away Platform
| extend Date = format_datetime(Date, "yyyy-MM-dd")
| sort by Segment asc, Metric asc, Date asc;
union appcountry, all_retention;
`;
