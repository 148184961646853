import React, { useState, useEffect } from "react";
import {
    ScatterChart,
    CartesianGrid,
    XAxis,
    YAxis,
    ZAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    LabelList,
    Label,
    Cell,
    Scatter,
} from "recharts";
import {
    IColumn,
    Stack,
    Slider,
    DetailsList,
    ChoiceGroup,
    MessageBar,
    MessageBarType,
} from "@fluentui/react";
import {
    CustomTooltip,
    getScorecardColumns,
    noDataForFilterMessageWithLink,
} from "components/AIScorecardComponents/CopilotCapAnalysisHelper";
import { CapHistoricalTrendsPanel } from "components/AIScorecardComponents/CopilotViews/CopilotCapTrends";
import { ExportLink } from "components/AIScorecardComponents/ExportLink";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import {
    fetchCopilotData,
    formatCapData,
    formatCapTrendsData,
    formatCapFeatures,
} from "components/AIScorecardComponents/ApiHandler";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { COPILOT_MESSAGES } from "utils/Messages";

const CheckAppDataAvailable = (cadence: string, application: string) => {
    return (
        cadence === "Weekly" && ["Word", "Excel", "PowerPoint"].includes(application)
    );
};

const CheckSatMetricAvailable = (application: string, feature: string) => {
    return feature && ["Word", "Excel", "PowerPoint"].includes(application);
};

const formatForExport = (data: any[], satMetric?: string) => {
    return data.map((row) => {
        let item = {
            Intent: row.Intent,
            Feature: row.Feature,
            TriedCount: row.TriedCount,
            TriedRate: row.TriedRate,
            DistinctUserCount: row.DistinctUserCount,
        };
        if (satMetric) {
            item[satMetric] = row[satMetric];
        }
        return item;
    });
};

export const CopilotCapAnalysis = ({ filters }) => {
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState(true);
    const [capData, setCapData] = useState([]);
    const [capDataForTable, setCapDataForTable] = useState([]);
    const [feature, setFeature] = useState("All Features");
    const [features, setFeatures] = useState([]);
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [capTrendsData, setCapTrendsData] = useState({});
    const [numberOfPointsToShow, setNumberOfPointsToShow] = useState(10);
    const [xMin, setxMin] = useState(0);
    const [xMax, setxMax] = useState(30);
    const [columns, setColumns] = useState(getScorecardColumns());
    const [dataForExport, setDataForExport] = useState([]);

    useEffect(() => {
        const getFeatures = async () => {
            setError(null);
            try {
                const featuresJson = await fetchCopilotData(
                    filters,
                    "copilotCapFeatures",
                );
                const capabilities = formatCapFeatures(featuresJson);
                if (capabilities && Object.keys(capabilities).length > 0) {
                    setFeatures(capabilities);
                    setFeature(capabilities[0].key as string);
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "AIScorecardFeatureProcessingFailed",
                    ),
                    {
                        message: "Failed to fetch or format CAP feature options",
                    },
                    error,
                );

                setError(error.message);
                return;
            }
        };

        getFeatures();
    }, [filters]);

    useEffect(() => {
        const getCapData = async () => {
            const queryParams = { ...filters, feature };
            setLoading(true);
            setCapData([]);
            setCapDataForTable([]);
            setDataForExport([]);
            setError(null);
            try {
                const capKusto = await fetchCopilotData(
                    queryParams,
                    "copilotCapData",
                );
                let formattedData = formatCapData(capKusto).sort(function (a, b) {
                    return b.TriedCount - a.TriedCount;
                });
                setCapData(formattedData);
                setCapDataForTable(formattedData);
                if (formattedData.length > 0) {
                    setxMax(Math.round(3 + formattedData[0].TriedRate));
                    setNumberOfPointsToShow(Math.min(formattedData.length, 10));
                }
                if (CheckSatMetricAvailable(filters.application, feature)) {
                    setColumns(getScorecardColumns("TriedCount", "KeptRate"));
                    setDataForExport(formatForExport(formattedData, "KeptRate"));
                } else {
                    setDataForExport(formatForExport(formattedData));
                }
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "copilotCapDataProcessingFailed",
                    ),
                    {
                        message: "Failed to fetch or format CAP data",
                    },
                    error,
                );

                setError(error.message);
                setCapData([]);
                setCapDataForTable([]);
                setDataForExport([]);
                return;
            }
            setLoading(false);
        };
        getCapData();
    }, [feature, filters]);

    const getCapTrendsData = async (name, intents, feature) => {
        const queryParams = { ...filters, feature, intents: intents };
        setError(null);
        try {
            const capTrendsKusto = await fetchCopilotData(
                queryParams,
                "copilotCapTrendsData",
            );
            setCapTrendsData({
                Name: name,
                Data: formatCapTrendsData(capTrendsKusto),
            });
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "copilotCapTrendsDataProcessingFailed",
                ),
                {
                    message: "Failed to fetch or format CAP Trends data",
                },
                error,
            );
            setError(error.message);
            setCapTrendsData([]);
            return;
        }
    };

    const _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn,
    ): void => {
        if (column.key !== "Intent" && column.key !== "Feature") {
            const result = DetailsListHelper.sortItems(columns, column, capData);
            setCapDataForTable(result.items);
            setColumns(result.columns);
        }
    };

    return loading ? (
        <ShimmeredCardBody />
    ) : error ? (
        <ErrorFallback message={error} />
    ) : !loading && !CheckAppDataAvailable(filters.cadence, filters.application) ? (
        <EmptyTableContainer
            message={COPILOT_MESSAGES.INTENT_ANALYSIS_NO_DATA_FOR_APP}
        />
    ) : !loading && capData.length == 0 ? (
        <EmptyTableContainer message={noDataForFilterMessageWithLink} />
    ) : (
        <>
            <MessageBar messageBarType={MessageBarType.warning}>
                This report currently includes only Commercial data for North
                American region.
            </MessageBar>
            <Stack>
                {CheckSatMetricAvailable(filters.application, feature) ? (
                    <Stack.Item>
                        <Stack horizontal horizontalAlign="start">
                            <Stack.Item grow={2}>
                                <ResponsiveContainer width="100%" height={600}>
                                    <ScatterChart
                                        margin={{
                                            top: 20,
                                            right: 50,
                                            bottom: 20,
                                            left: 15,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="TriedRate"
                                            type="number"
                                            name="TriedRate"
                                            unit="%"
                                            domain={[xMin, xMax]}
                                            allowDataOverflow
                                        >
                                            <Label
                                                value="% of Total Tries"
                                                offset={0}
                                                position="insideBottom"
                                            />
                                        </XAxis>
                                        <YAxis
                                            dataKey="KeptRate"
                                            type="number"
                                            name="KeptRate"
                                            unit="%"
                                            domain={["dataMin", "dataMax"]}
                                            allowDecimals={false}
                                            padding={{ top: 10, bottom: 10 }}
                                        >
                                            <Label
                                                value="Kept Rate"
                                                angle={-90}
                                                position="left"
                                            />
                                        </YAxis>
                                        <ZAxis
                                            type="number"
                                            range={[1, 100]}
                                            dataKey="z"
                                        />
                                        <ReferenceLine
                                            y="60"
                                            stroke="green"
                                            label={
                                                <Label
                                                    value="60%"
                                                    position="insideTopRight"
                                                />
                                            }
                                        />
                                        <Tooltip
                                            content={
                                                <CustomTooltip active payload />
                                            }
                                        />
                                        <Scatter
                                            name="Intents"
                                            data={capData.slice(
                                                0,
                                                numberOfPointsToShow,
                                            )}
                                        >
                                            <LabelList
                                                dataKey="Name"
                                                position="right"
                                                width={200}
                                                fill="black"
                                            />
                                            {capData
                                                .slice(0, numberOfPointsToShow)
                                                .map((entry, index) => {
                                                    return (
                                                        <Cell
                                                            key={`cell-${index}-${entry.Name}`}
                                                            fill={
                                                                // all bubbles are blue for now
                                                                // keeping the logic for POST-MVP
                                                                entry.TriedRate >= 10
                                                                    ? entry.KeptRate >=
                                                                      60
                                                                        ? "blue"
                                                                        : "blue"
                                                                    : "blue"
                                                            }
                                                            onMouseOver={() => {
                                                                return CustomTooltip(
                                                                    entry,
                                                                );
                                                            }}
                                                            onClick={() => {
                                                                getCapTrendsData(
                                                                    entry.Name,
                                                                    entry.Intents,
                                                                    entry.Feature,
                                                                );
                                                                setShowPanel(true);
                                                            }}
                                                        />
                                                    );
                                                })}
                                        </Scatter>
                                    </ScatterChart>
                                </ResponsiveContainer>
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <Stack tokens={{ childrenGap: 20 }}>
                                    <Stack.Item>
                                        <ChoiceGroup
                                            className="inlineflex"
                                            defaultSelectedKey={feature}
                                            options={features}
                                            onChange={(event, option) =>
                                                setFeature(option["key"] as string)
                                            }
                                            label="Features"
                                        />
                                    </Stack.Item>
                                    <Stack.Item
                                        styles={{ root: { minWidth: "200px" } }}
                                    >
                                        <Slider
                                            label="Number of Intents to Show"
                                            min={1}
                                            max={capData.length}
                                            step={1}
                                            value={numberOfPointsToShow}
                                            onChange={(value: number) =>
                                                setNumberOfPointsToShow(value)
                                            }
                                            showValue={true}
                                            className="numberToDisplaySlider"
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Slider
                                            label="X-axis"
                                            ranged
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={xMax}
                                            lowerValue={xMin}
                                            onChange={(value, range) => {
                                                setxMin(range[0]);
                                                setxMax(range[1]);
                                            }}
                                            showValue={true}
                                            className="XAxisSlider"
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <CapHistoricalTrendsPanel
                                    showPanel={showPanel}
                                    setShowPanel={setShowPanel}
                                    data={capTrendsData}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                ) : null}
                <Stack.Item align="end">
                    <ExportLink
                        filename={
                            "CopilotIntentAnalysis-" +
                            filters.application +
                            "-" +
                            filters.cadence +
                            "-" +
                            filters.date +
                            ".csv"
                        }
                        data={dataForExport}
                    />
                </Stack.Item>
                <Stack.Item>
                    <DetailsList
                        columns={columns}
                        onColumnHeaderClick={_onColumnClick}
                        items={capDataForTable.map((item) => {
                            return {
                                Intent: item.Intent,
                                Feature: item.Feature,
                                TriedCount: item.TriedCount,
                                TriedRate: item.TriedRate + "%",
                                KeptRate: item.KeptRate + "%",
                                DistinctUserCount: item.DistinctUserCount,
                            };
                        })}
                        selectionMode={0}
                        onItemInvoked={(selectedItem) => {
                            let index = capDataForTable.findIndex(
                                (item) => item.Intent == selectedItem.Intent,
                            );
                            getCapTrendsData(
                                selectedItem.Feature + ": " + selectedItem.Intent,
                                capDataForTable[index].Intents,
                                selectedItem.Feature,
                            );
                            setShowPanel(true);
                        }}
                        compact
                    />
                </Stack.Item>
            </Stack>
        </>
    );
};
