import React, { useState } from "react";
import {
    ConstrainMode,
    DetailsHeader,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsColumnFieldProps,
    IDetailsHeaderProps,
    IDetailsRowStyles,
    IRenderFunction,
    Icon,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
} from "@fluentui/react";
import * as DetailsListHelper from "components/CustomComponents/DetailsListHelper/DetailsListHelper";
import { ShimmeredDetailsListWrapper } from "components/CustomComponents/ShimmeredWrapper/ShimmeredDetailsListWrapper";
import { ERFMChartFlyout } from "components/ERFMComponents/ERFMChartFlyout";
import { isERFMTableCellInvalid } from "components/ERFMComponents/ERFMTableHelper";
import { ERFMColumnConfig } from "components/ERFMComponents/ERFMHelper";
import { ERFMTablePropsType } from "components/ERFMComponents/types";
import { LOOKBACK_SUBSCRIPT, NO_DATA_MESSAGES } from "utils/Messages";

const onRenderField = (
    props?: IDetailsColumnFieldProps,
    defaultRender?: IRenderFunction<IDetailsColumnFieldProps>,
) => {
    return defaultRender({ ...props });
};

const onRenderRow = (props?: IDetailsColumnFieldProps) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
        if (props.itemIndex % 2 === 0) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "#fcf5ed" };
        }
        return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
};

const ERFMTable = ({
    items,
    columns,
    loading,
    application,
    dataByApp,
    dataByCountry,
    country,
}: ERFMTablePropsType) => {
    const [selectedSegment, setSelectedSegment] = useState<string>("");
    const [showPanel, setShow] = useState<boolean>(false);
    const togglePanel = () => {
        setShow(!showPanel);
    };
    const showChartFlyout = (segment: string) => {
        setSelectedSegment(segment);
        togglePanel();
    };

    const renderDetailsHeader = (props: IDetailsHeaderProps): JSX.Element => {
        return <DetailsHeader {...props} />;
    };

    const renderItemColumn = (item, _index: number, column: IColumn) => {
        const fieldContent = item[column.key];
        const colKey = column.key;

        if (isERFMTableCellInvalid(colKey, item, country)) {
            return (
                <div className="erfmTableCell erfmTableCellGrey">
                    <DetailsListHelper.TextValue value={"NA"} />
                </div>
            );
        } else if (colKey === "Segment") {
            return (
                <div
                    onClick={() => showChartFlyout(fieldContent)}
                    style={{ cursor: "pointer" }}
                >
                    {/* Left padding for indented/nested rows */}
                    <span
                        style={{
                            marginLeft: ERFMColumnConfig[fieldContent].level * 10,
                        }}
                    >
                        {ERFMColumnConfig[fieldContent].level > 1 && " >> "}
                    </span>
                    <DetailsListHelper.TextValue
                        value={ERFMColumnConfig[fieldContent].displayName}
                        title={ERFMColumnConfig[fieldContent].title}
                    />
                </div>
            );
        } else if (colKey === "MAU") {
            return (
                <div className="erfmTableCell">
                    <DetailsListHelper.NumericalAbbreviatedValue
                        value={fieldContent ?? "NA"}
                    />
                </div>
            );
        } else if (colKey === "Dummy") {
            return (
                <div className="erfmTableCell">
                    <DetailsListHelper.TextValue value={fieldContent} />
                </div>
            );
        }

        return (
            <div className="erfmTableCell">
                <DetailsListHelper.NumericalValue
                    value={
                        fieldContent == null
                            ? "NA"
                            : fieldContent <= 0.004 && fieldContent > 0
                            ? "<0.00%"
                            : fieldContent
                    }
                    postfix={fieldContent ? "%" : ""}
                    decimalPrecision={2}
                />
            </div>
        );
    };

    return (
        <>
            <div
                className="exploreButton"
                onClick={() => {
                    togglePanel();
                }}
                aria-label="Metric Historical Trends"
                title="Metric Historical Trends"
            >
                Metric Historical Trends <Icon iconName="Chart" />
            </div>

            <div data-is-scrollable className="erfmTable">
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <ShimmeredDetailsListWrapper
                        items={items[application] || []}
                        message={NO_DATA_MESSAGES.NO_DATA_CATEGORY}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onRenderDetailsHeader={renderDetailsHeader}
                        enableShimmer={loading}
                        onRenderItemColumn={renderItemColumn}
                        onRenderField={onRenderField}
                        onRenderRow={onRenderRow}
                        styles={DetailsListHelper.tenantTableStyle}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                    />
                </ScrollablePane>
            </div>

            <span className="subscriptStyleBottom">{LOOKBACK_SUBSCRIPT}</span>

            {!loading && showPanel && (
                <ERFMChartFlyout
                    isPanelOpen={showPanel}
                    togglePanel={togglePanel}
                    segment={selectedSegment}
                    application={application}
                    dataByApp={dataByApp}
                    dataByCountry={dataByCountry}
                />
            )}
        </>
    );
};

export default ERFMTable;
