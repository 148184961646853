export const aiScorecardDates = `
// AI Scorecard Dates
database('Copilot').MetricsWithTenants_Staging
    | where DateFrequency =="\${cadence}"
    | distinct Date
    | order by Date desc
    `;

const fixAppNames = `
let mapAppName = (AppName : string) {iff(AppName == "Loop App", "Loop", iff(AppName == "Microsoft Planner", "Planner", AppName))};
let reverseMapAppName = (AppName : string) {iff(AppName == "Loop", "Loop App", iff(AppName == "Planner", "Microsoft Planner", AppName))};
`;

const scorecardDeclarations = `
${fixAppNames}
let app = reverseMapAppName("\${application}");
let dt = "\${date}";
let cadence = "\${cadence}";
let platform = "\${platform}";
let office365Category = "\${office365Category}";
let audienceGroup = "\${audienceGroup}";
let cohort = "\${cohort}";
let tenantId = "\${tenantId}";`;

export const aiMBRFeatureGoals = `
// AI Scorecard Metrics
${scorecardDeclarations}
database('Copilot').MBRFeatureGoalConfig(app)`;

export const aiMBRAppGoals = `
// AI Scorecard Metrics
${scorecardDeclarations}
database('Copilot').MBRAppGoalConfig(app)`;

export const aiMBRScorecardMetrics = `
// AI Scorecard Metrics
${scorecardDeclarations}
let negateTable = datatable(MetricName: string, Negate: real)
[
    "RAIBlockRate", 1,
    "AuthBlockRate", 1,
    "UnsupportedFeaturesRate", 1,
    "ServiceBlockRate", 1,
    "UserSuccessRate", 1,
    "E2ELatency", 1,
    "SkillFirstResultLatency", 1,
    "SkillFinalResultLatency", 1,    
    "CapabilityNegativeFeelingsRate", 1,
    "CapabilityNegativeTimeSavedRate", 1,
    "ASHA", 0,
    "KeptFeatureRate", 0,
    "TriedRate", 0,
    "FeatureTriedPercent", 0,
];
let dtPrev = format_datetime(iff(cadence == "Weekly", datetime_add("day", -7, todatetime(dt)), endofmonth(todatetime(dt),-1)), "yyyy-MM-dd");
let currentDtMetrics = database('Copilot').MBRFeatureMetrics(app, dt, cadence, platform, office365Category, audienceGroup, cohort, tenantId);
let prevDtMetrics = database('Copilot').MBRFeatureMetrics(app, dtPrev, cadence, platform, office365Category, audienceGroup, cohort, tenantId);
let negateData = (currentDtMetrics 
    | distinct Feature, Capability
    | extend dummy=1
    | join kind=fullouter  (negateTable | extend dummy=1) on dummy)
    | project Feature, Capability, MetricName, Negate;
    currentDtMetrics
    | join kind=inner (prevDtMetrics | project Feature, Capability, MetricName, PrevMetricValue=MetricValue) 
        on Feature, Capability, MetricName
    | extend MetricValue = MetricValue-PrevMetricValue
    | extend MetricName = strcat(MetricName, "DeltaValue"), MetricValue 
    | union currentDtMetrics
    | project Feature, Capability, MetricName, MetricValue
    | union (negateData
        | project Feature, Capability,
            MetricName = strcat(MetricName,"DeltaNegate"),
            MetricValue = Negate)`;

export const aiMBROverviewScorecardMetrics = `
// AI Scorecard Metrics
${scorecardDeclarations}
let negateTable = datatable(MetricName: string, Negate: real)
[
    "ExceededExpectationsRate", 0,
    "RetentionRate", 0,
    "CopilotNps", 0,
    "PositiveProductMarketFitRate", 0,
    "Tried", 0,
    "TriedByEnabledRate", 0,
    "EngagedUsersPercent", 0,
    "ASHA", 0
];
let dtPrev = format_datetime(iff(cadence == "Weekly", datetime_add("day", -7, todatetime(dt)), endofmonth(todatetime(dt),-1)), "yyyy-MM-dd");
let currentDtMetrics = database('Copilot').MBROverviewMetrics(app, dt, cadence, platform, office365Category, audienceGroup, cohort, tenantId);
let prevDtMetrics = database('Copilot').MBROverviewMetrics(app, dtPrev, cadence, platform, office365Category, audienceGroup, cohort, tenantId);
currentDtMetrics
| join kind=inner (prevDtMetrics | project RowLabel, MetricName, PrevMetricValue=MetricValue) 
    on MetricName
| extend MetricValue = MetricValue-PrevMetricValue
| extend MetricName = strcat(MetricName, "DeltaValue"), MetricValue=iff(MetricName in ("EnabledUnits", "Tried"), MetricValue = MetricValue/PrevMetricValue * 100, MetricValue) 
| union currentDtMetrics
| project RowLabel, MetricName, MetricValue
| union (negateTable
    | project RowLabel = "Result",
        MetricName = strcat(MetricName,"DeltaNegate"),
        MetricValue = Negate)`;

export const aiScorecardMetrics = `
// AI Scorecard Metrics
${scorecardDeclarations}
database('Copilot').MetricsWithTenants_Staging
| extend CohortType = iff(CohortType == "", "AllUsers", CohortType)
| where Date == dt and DateFrequency == cadence and AppName == app and Platform == platform and Office365Category == office365Category and AudienceGroup == audienceGroup
    and CohortType == cohort
    and OMSTenantId == tenantId
| extend MetricValue = todouble(MetricValue)
| union
    (
    database("Copilot").ThumbsDownPer100k(dt, cadence, platform, audienceGroup, office365Category, cohort, tenantId)
    | where AppName == app
)
| union
    (
    database("Copilot").RAIBlockRates
    | where Date == dt
        and DateFrequency == cadence
        and AppName == app
        and AudienceGroup == audienceGroup
        and Office365Category == office365Category
        and Platform == platform 
        and MetricName == "Block Rate"
        and CohortType == cohort
    )
| union 
    (
        database("Asha").ASHA_Copilot_Scorecard_Rates(dt, cadence, app, platform, audienceGroup, office365Category, cohort, tenantId)
    )
| project
    Feature,
    MetricName,
    MetricValue,
    Capability = iff(isempty(Capability), "Unclear Intent", Capability)
| order  by Capability asc, Feature asc
| extend
    rn = iff(Capability == prev(Capability), 0, 1),
    FeatureOrder = row_cumsum(1, Capability != prev(Capability))
| extend
    CapabilityOrder = iff(Capability == "All", 0, row_cumsum(rn)),
    FeatureOrder = iff(Feature == "All", 0, FeatureOrder)
| order by CapabilityOrder asc, FeatureOrder asc
| project Feature, Capability, MetricName, MetricValue
`;

export const aiScorecardChart = `
// AI Scorecard Trend Chart
${scorecardDeclarations}
let feature = "\${feature}";
let capability = "\${capability}";
database("Copilot").MetricsWithTenants_Staging
| where OMSTenantId == tenantId and AppName == app and AudienceGroup == audienceGroup and Office365Category == office365Category and DateFrequency == cadence and Platform == platform
    and Feature == feature and Capability == capability
    and CohortType == cohort
| project Date, MetricName, MetricValue
| order by Date asc`;

export const copilotFeatures = `
// AI Scorecard Features
${scorecardDeclarations}
// Only include features released after this date.
let featureFilterDate = datetime('2023-09-01');
database('Copilot').AIRetentionWithTenants
| where Platform == platform and AppName == app and OMSTenantId == tenantId and todatetime(Date) > featureFilterDate
| distinct Feature, Capability
| sort by Capability asc, Feature asc`;

export const copilotRetention = `
// AI Scorecard Retention
${scorecardDeclarations}
let feature = "\${feature}";
let capability = "\${capability}";
let mostRecent = toscalar(database('Copilot').AIRetentionWithTenants | where DateFrequency == cadence and CohortType == cohort | summarize max(ProcessDate));
database('Copilot').AIRetentionWithTenants
| where AppName == app and DateFrequency == cadence and Platform == platform and Office365Category == office365Category and AudienceGroup == audienceGroup and Feature == feature
    and Capability == capability and CohortType == cohort and ProcessDate == mostRecent and OMSTenantId == tenantId
| project
    CohortName,
    UserCount,
    RetentionRate_1stPeriod = RetentionRate_1stPeriod/100,
    RetentionRate_2ndPeriod = RetentionRate_2ndPeriod/100,
    RetentionRate_3rdPeriod = RetentionRate_3rdPeriod/100,
    RetentionRate_4thPeriod = RetentionRate_4thPeriod/100,
    RetentionRate_5thPeriod = RetentionRate_5thPeriod/100,
    RetentionRate_6thPeriod = RetentionRate_6thPeriod/100,
    RetentionRate_7thPeriod = RetentionRate_7thPeriod/100,
    RetentionRate_8thPeriod= RetentionRate_8thPeriod/100,
    RetentionRate_9thPeriod = RetentionRate_9thPeriod/100,
    RetentionRate_10thPeriod = RetentionRate_10thPeriod/100,
    RetentionRate_11thPeriod = RetentionRate_11thPeriod/100,
    RetentionRate_12thPeriod = RetentionRate_12thPeriod/100
| order by CohortName asc
`;

const appTrendsQueryDeclarations = `
${fixAppNames}
let cadence = "\${cadence}";
let platform = "\${platform}";
let office365Category = "\${office365Category}";
let audienceGroup = "\${audienceGroup}";
let cohort = "\${cohort}";
let dt = todatetime("\${date}");
let lookback = 12;
let Dates = range offset from (-lookback) to 0 step 1
| project Date = format_datetime(iff(cadence == "Monthly", endofmonth(dt, offset), endofweek(dt, offset)), 'yyyy-MM-dd');
let Apps = datatable (AppName: string)["Word", "Excel", "PowerPoint", "OneNote", "Outlook", "Loop App", "Whiteboard", "Teams",
     "M365 Chat", "WXPN", "Stream", "Forms", "Microsoft Planner"];`;

export const copilotUsageTrends = `
// Copilot Usage Trends
${appTrendsQueryDeclarations}
Dates
| extend placeholder=1 
| join kind = fullouter   (
    Apps
    | extend placeholder=1)
    on placeholder
| join kind = fullouter   (
    datatable (MetricName:string )["Seen", "Tried", "Kept"]
    | extend placeholder=1)
    on placeholder
| join kind = leftouter  (
    database('Copilot').MetricsWithTenants_Staging
    | where DateFrequency == cadence and Feature == "All" and Capability == "All"
        and Platform == platform and AudienceGroup == audienceGroup and OMSTenantId == "All" and Office365Category == office365Category
        and CohortType == cohort 
    | project Date, AppName, MetricName, MetricValue
    )
    on $left.Date == $right.Date and $left.AppName == $right.AppName and $left.MetricName == $right.MetricName
| extend AppName = mapAppName(AppName)
| project Date, AppName, MetricName, MetricValue
| order by Date asc
`;

export const copilotTriedCountsPerUserTrends = `
// Copilot Tried Trends
${appTrendsQueryDeclarations}
Dates
| extend placeholder=1 
| join kind = fullouter   (
    Apps
    | extend placeholder=1)
    on placeholder
| join kind = leftouter  (
    database('Copilot').MetricsWithTenants_Staging
    | where DateFrequency == cadence and Feature == "All" and Capability == "All"
        and Platform == platform and AudienceGroup == audienceGroup and OMSTenantId == "All" and Office365Category == office365Category
        and CohortType == cohort and MetricName == "TriedCountsPerUser"
    | project Date, AppName, MetricName, MetricValue
    )
    on $left.Date == $right.Date and $left.AppName == $right.AppName
| extend AppName = mapAppName(AppName)
| project Date, AppName, MetricName, MetricValue = round(MetricValue, 1)
| order by Date asc`;

export const copilotRAITrends = `
// Copilot RAI Trends
${appTrendsQueryDeclarations}
Dates
| extend placeholder=1 
| join kind = fullouter   (
    Apps
    | extend placeholder=1)
    on placeholder
    | join kind = leftouter  (
        database('Copilot').RAIBlockRates
        | where DateFrequency == cadence and Feature == "All" and Capability == "All"
            and Platform == platform and AudienceGroup == audienceGroup and Office365Category == office365Category
            and CohortType == cohort and MetricName == "Block Rate"
        | extend AppName = mapAppName(AppName)
        )
        on $left.Date == $right.Date and $left.AppName == $right.AppName
| project Date, AppName, MetricName, MetricValue = round(MetricValue, 2)
| order by Date asc`;

export const copilotRetentionTrends = `
// Copilot Retention Trends
${appTrendsQueryDeclarations}
let mostRecent = toscalar (database('Copilot').AIRetentionWithTenants | where DateFrequency == cadence and OMSTenantId=="All" and todatetime( ProcessDate)<=dt | summarize max(ProcessDate));
Dates
| extend placeholder=1 
| join kind = fullouter   (
    Apps
    | extend placeholder=1)
    on placeholder
    | join kind = leftouter  (
        database('Copilot').AIRetentionWithTenants	
        | where ProcessDate == mostRecent and DateFrequency == cadence and Feature == "All" and Capability == "All"
            and Platform == platform and AudienceGroup == audienceGroup and Office365Category == office365Category
            and CohortType == cohort and Feature == "All" and Capability == "All" and OMSTenantId == "All"
        | extend Date = format_datetime(iff(cadence == "Weekly", datetime_add('day', 7, todatetime(Date)), endofmonth(todatetime(Date),1)), "yyyy-MM-dd")
        )
        on $left.Date == $right.Date and $left.AppName == $right.AppName
| project
    Date, 
    AppName,
    MetricName = "Retention 1st Period",
    MetricValue = round(RetentionRate_1stPeriod, 2)
| extend AppName = mapAppName(AppName)
| where isnotempty( MetricValue)
| order by Date asc`;

export const newTenants = `
//Tenants who bought Copilot Licenses in the last 30 days
let endDate =  now();
let startDate = datetime_add('day', -30, endDate);
let office365Category = iff("\${office365Category}" == "Commercial - External", "Commercial", "\${office365Category}") ;
database('Copilot').CopilotTenants
    | where OmsTenantId != "72f988bf-86f1-41af-91ab-2d7cd011db47" and ProcessDate between (startDate .. endDate ) and isnotnull(CandidateCreatedDate) and iff(office365Category contains "Commercial -", Office365Category == office365Category, iff(office365Category contains "Consumer", false, true))
    | summarize AvailableUnits = sum(AvailableUnits), CandidateCreatedDate = max(CandidateCreatedDate) by OmsTenantId, TenantName, ProcessDate
    | extend PrevProcessDate = ProcessDate, PrevAvailableUnits = AvailableUnits
    | summarize arg_max(ProcessDate, AvailableUnits, TenantName, CandidateCreatedDate),
        arg_min(PrevProcessDate, PrevAvailableUnits) by OmsTenantId      
    | where AvailableUnits > PrevAvailableUnits 
        or (AvailableUnits == PrevAvailableUnits and CandidateCreatedDate between ( startDate .. endDate))
    | project TenantName, OmsTenantId, AvailableUnits, Date = CandidateCreatedDate
    | where AvailableUnits >= 50
    | order by AvailableUnits desc 
`;

export const topTenantsUsage = `
//Top Tenants Usage
${fixAppNames}
let app = reverseMapAppName("\${application}");
let cadence = "\${cadence}";
let office365Category = iff("\${office365Category}" == "Commercial - External", "Commercial", "\${office365Category}") ;
let maxDate = toscalar(database('Copilot').MetricsWithTenants_Staging
    | where DateFrequency == cadence
    | summarize max(Date));
let maxCopilotTenantsDate = toscalar(database('Copilot').CopilotTenants
    | summarize max(ProcessDate));
database('Copilot').MetricsWithTenants_Staging
| where AppName == app and Feature == "All" and Capability == "All" and Date == maxDate and DateFrequency == cadence and Platform == "All" and MetricName == "Tried"
    and Office365Category == "Commercial" and AudienceGroup == "Production"
    and CohortType == "AllUsers"
    and OMSTenantId != "All" 
    and OMSTenantId != "72f988bf-86f1-41af-91ab-2d7cd011db47"
| project OMSTenantId, MetricValue
| join kind = inner (database('Copilot').CopilotTenants
    | where ProcessDate == maxCopilotTenantsDate and iff(office365Category contains "Commercial -", 
        Office365Category == office365Category, 
        iff(office365Category contains "Consumer", false, true))
    | summarize AvailableUnits = sum(AvailableUnits), EnabledUsers = sum(EnabledUsers) by OmsTenantId, TenantName)
    on $left.OMSTenantId == $right.OmsTenantId
| project TenantName, AvailableUnits, EnabledUsers, MetricValue, OmsTenantId
| order by MetricValue desc 
| limit 10
    `;

export const copilotTenantLicenses = `
// License data for tenant
let tenantId = "\${tenantId}";
database('Copilot').CopilotTenants
| where OmsTenantId  == tenantId
| order by AvailableUnits desc
| summarize arg_max(ProcessDate, AvailableUnits, SkuName, EnabledUsers) by OmsTenantId
| project AvailableUnits, EnabledUsers, SkuName
`;

export const copilotSummaryScorecard = `
// Copilot Summary Scorecard
${fixAppNames}
let cadence = "\${cadence}";
let platform = "\${platform}";
let office365Category = "\${office365Category}";
let audienceGroup = "\${audienceGroup}";
let cohort = "\${cohort}";
let dt = "\${date}";
let periodsAgo = 1;
let retentionMetric = iff(cadence == "Weekly", "W1 Retention", "M1 Retention");
let mostRecent = toscalar (database('Copilot').AIRetentionWithTenants | where DateFrequency == cadence | summarize max(ProcessDate));
let retentionDt = format_datetime(iff(cadence == "Weekly", datetime_add('day', -7, todatetime(dt)), endofmonth(todatetime(dt),-1)), "yyyy-MM-dd");
let orderTable = datatable(MetricName: string, Sequence: int, Postfix: string)
[
    "Usage", 1, "",
    "Tries per User", 2, "",
    "M1 Retention", 3, "%",
    "W1 Retention", 3, "%",
    "RAI Block Rate", 4, "%",
    "Thumbs Down / 100k Tries", 5, "",
];
database('Copilot').MetricsWithTenants_Staging
| where Date == dt and DateFrequency == cadence and MetricName in ("Tried", "TriedCountsPerUser") and OMSTenantId == "All" and Platform == platform and Office365Category == office365Category
    and AudienceGroup == audienceGroup and CohortType == cohort and Feature == "All" and Capability == "All"
| project AppName, MetricValue, MetricName = iff(MetricName == "Tried", "Usage", "Tries per User")
| union 
    (
    database('Copilot').RAIBlockRates
    | where Date == dt and DateFrequency == cadence and MetricName == "Block Rate" and Platform == platform and Office365Category == office365Category
        and AudienceGroup == audienceGroup and CohortType == cohort and Feature == "All" and Capability == "All"
    | project AppName, MetricValue, MetricName = "RAI Block Rate"
    )
| union
    (
    database("Copilot").ThumbsDownPer100k(dt, cadence, platform, audienceGroup, office365Category, cohort, "All")
    | where Feature == "All" and Capability == "All"
    | project AppName, MetricValue, MetricName = "Thumbs Down / 100k Tries"
    )
| union (
    database('Copilot').AIRetentionWithTenants	
    | where ProcessDate == mostRecent and Date == retentionDt and DateFrequency == cadence and Feature == "All" and Capability == "All"
        and Platform == platform and AudienceGroup == audienceGroup and Office365Category == office365Category
        and CohortType == cohort and Feature == "All" and Capability == "All" and OMSTenantId == "All"
    | project
        AppName,
        MetricValue  = RetentionRate_1stPeriod,
        MetricName = retentionMetric
    )
| where AppName != "WXPN"
| extend
    MetricValue = round(MetricValue, 2),
    AppName = mapAppName(AppName)
| evaluate pivot(AppName, max(MetricValue))
| join kind = inner (orderTable) on $left.MetricName == $right.MetricName
| order by Sequence asc
| project-away Sequence, MetricName1
`;
export const copilotSummaryScorecardForExport = `
// Copilot Summary Scorecard For Export
${fixAppNames}
let cadence = "\${cadence}";
let platform = "\${platform}";
let office365Category = "\${office365Category}";
let audienceGroup = "\${audienceGroup}";
let cohort = "\${cohort}";
let dt = "\${date}";
let retentionMetric = iff(cadence == "Weekly", "W1 Retention", "M1 Retention");
let mostRecent = toscalar (database('Copilot').AIRetentionWithTenants | where DateFrequency == cadence | summarize max(ProcessDate));
let retentionDt = format_datetime(iff(cadence == "Weekly", datetime_add('day', -7, todatetime(dt)), endofmonth(todatetime(dt),-1)), "yyyy-MM-dd");
let orderTable = datatable(MetricName: string, Sequence: int, Postfix: string)
[
    "Usage", 1, "",
    "Tries per User", 2, "",
    "M1 Retention", 3, "%",
    "W1 Retention", 3, "%",
    "RAI Block Rate", 4, "%",
    "Thumbs Down / 100k Tries", 5, "",
];
database('Copilot').MetricsWithTenants_Staging
| where Date == dt and DateFrequency == cadence and MetricName in ("Tried", "TriedCountsPerUser") and OMSTenantId == "All" and Platform == platform and Office365Category == office365Category
    and AudienceGroup == audienceGroup and CohortType == cohort
| project AppName, Feature, Capability, MetricValue, MetricName = iff(MetricName == "Tried", "Usage", "Tries per User")
| union 
    (
    database('Copilot').RAIBlockRates
    | where Date == dt and DateFrequency == cadence and MetricName == "Block Rate" and Platform == platform and Office365Category == office365Category
        and AudienceGroup == audienceGroup and CohortType == cohort
    | project AppName, Feature, Capability, MetricValue, MetricName = "RAI Block Rate"
    )
| union
    (
    database("Copilot").ThumbsDownPer100k(dt, cadence, platform, audienceGroup, office365Category, cohort, "All")
    | project AppName, Feature, Capability, MetricValue, MetricName = "Thumbs Down / 100k Tries"
    )
| union (
    database('Copilot').AIRetentionWithTenants	
    | where ProcessDate == mostRecent and Date == retentionDt and DateFrequency == cadence
        and Platform == platform and AudienceGroup == audienceGroup and Office365Category == office365Category
        and CohortType == cohort and OMSTenantId == "All"
    | project
        AppName, Feature, Capability,
        MetricValue  = RetentionRate_1stPeriod,
        MetricName = retentionMetric
    )
| where AppName != "WXPN"
| extend
    MetricValue = round(MetricValue, 2),
    AppName = mapAppName(AppName)
| join kind = inner (orderTable) on $left.MetricName == $right.MetricName
| order by Sequence asc
| project-away Sequence, MetricName1
`;

export const copilotM365Scorecard = `
// M365 Scorecard Metrics
${fixAppNames}
let dt = "\${date}";
let cadence = "\${cadence}";
let platform = "\${platform}";
let office365Category = "\${office365Category}";
let audienceGroup = "\${audienceGroup}";
let cohort = "\${cohort}";
let tenantId = "All";
let feature = "All";
let capability = "All";
let orderTable = datatable(AppName: string, Sequence: int)
[
    "All", 1,
    "WXPN", 2, 
    "Word", 3, 
    "Excel", 4, 
    "PowerPoint", 5, 
    "OneNote", 6, 
    "Loop", 7,  
    "Whiteboard", 8,  
    "Teams", 9,  
    "Outlook", 10, 
    "M365 Chat", 11,
    "Stream", 12,
    "Forms", 13,
    "Planner", 14,
];
database('Copilot').MetricsWithTenants_Staging
| extend CohortType = iff(CohortType == "", "AllUsers", CohortType)
| extend Platform = iff(Platform == "Windows Desktop", "Win32", Platform)
| where Date == dt and DateFrequency == cadence and Platform == platform and Office365Category == office365Category and AudienceGroup == audienceGroup
    and CohortType == cohort
    and OMSTenantId == tenantId
    and Capability == capability
    and Feature == feature
    and MetricName in ("TriedCountsPerUser", "Tried", "CopilotNps")
| extend MetricValue = todouble(MetricValue)
| union
(
database('Copilot').MetricsWithTenants_Staging
| where Date == dt and DateFrequency == cadence and Platform == platform and Office365Category == office365Category and AudienceGroup == audienceGroup
    and OMSTenantId == tenantId
    and Capability == capability
    and Feature == feature
    and MetricName == "QuickRatio"
| extend MetricValue = todouble(MetricValue)
)
| union
    (
    database("Copilot").ThumbsDownPer100k(dt, cadence, platform, audienceGroup, office365Category, cohort, tenantId)
    | where Feature == feature and Capability == capability
)
| union
    (
    database("Copilot").RAIBlockRates
    | where Date == dt
        and DateFrequency == cadence
        and AudienceGroup == audienceGroup
        and Office365Category == office365Category
        and Platform == platform 
        and MetricName == "Block Rate"
        and CohortType == cohort
    )
| union 
    (
        (
        database("Asha").ASHA_Copilot_Scorecard_Rates_AllApps(dt, cadence, platform, audienceGroup, office365Category, cohort, tenantId)
        )  
        | extend AppName = Application                
        | union
            (
            database("Asha").ASHA_Copilot_Scorecard_Rates(dt, cadence, "All", platform, audienceGroup, office365Category, cohort, tenantId)
            | extend AppName = "All"
            )             
        | union
            (
            database("Asha").ASHA_Copilot_Scorecard_Rates(dt, cadence, "WXPN", platform, audienceGroup, office365Category, cohort, tenantId)
            | extend AppName = "WXPN"
            )      
        | where Capability == capability
            and Feature == feature
    )
| union
    (
    database("Copilot").TVPassFail(dt, cadence, platform, audienceGroup, office365Category, cohort, tenantId)
    )
| union
    (
    database("Copilot").MTEPassFail(dt, cadence, platform, audienceGroup, office365Category, cohort, tenantId)
    )
| union
    (
    cluster("Metrical").database("CogswellCopilotData").GetCopilotM365ScorecardData(dt, cadence, cohort, office365Category, platform, audienceGroup)
    )
| extend AppName = mapAppName(AppName)
| join kind = inner (orderTable) on AppName
| order by Sequence asc
| project
    AppName,
    MetricName,
    MetricValue
`;

export const copilotQuickRatioTrends = `
// Copilot Tried Trends
${appTrendsQueryDeclarations}
Dates
| extend placeholder=1 
| join kind = fullouter   (    
    Apps
    | extend placeholder=1)
    on placeholder
| join kind = leftouter  (
    database('Copilot').MetricsWithTenants_Staging
    | where DateFrequency == cadence and Feature == "All" and Capability == "All"
        and Platform == platform and AudienceGroup == audienceGroup and OMSTenantId == "All" and Office365Category == office365Category
        and MetricName == "QuickRatio"
    | project Date, AppName, MetricName, MetricValue
    )
    on $left.Date == $right.Date and $left.AppName == $right.AppName
| extend AppName = mapAppName(AppName)
| project Date, AppName, MetricName, MetricValue = round(MetricValue, 1)
| order by Date asc`;

export const copilotTriedTrends = `
// Copilot Tried Trends
${appTrendsQueryDeclarations}
Dates
| extend placeholder=1 
| join kind = fullouter   (
    Apps
    | extend placeholder=1)
    on placeholder
| join kind = leftouter  (
    database('Copilot').MetricsWithTenants_Staging
    | where DateFrequency == cadence and Feature == "All" and Capability == "All"
        and Platform == platform and AudienceGroup == audienceGroup and OMSTenantId == "All" and Office365Category == office365Category
        and CohortType == cohort and MetricName == "Tried"
    | project Date, AppName, MetricName, MetricValue
    )
    on $left.Date == $right.Date and $left.AppName == $right.AppName
| extend AppName = mapAppName(AppName)
| project Date, AppName, MetricName, MetricValue
| order by Date asc`;

export const copilotuserjourneyFunnelStages = `
// Copilot User Journey Funnel stages
${scorecardDeclarations}
let funneldata =
MetricsWithTenants_Staging
// PurchasedUnits and EnabledUnits are platform agnostic
| where MetricName in ("PurchasedUnits","EnabledUnits") and DateFrequency == cadence and Office365Category == office365Category and CohortType == cohort
    and AppName == "All" and Platform == "All" and AudienceGroup == "All" and Feature == "All" and Capability == "All" and OMSTenantId == "All"
| union (MetricsWithTenants_Staging
| where MetricName in ("ActiveUsers","EngagedUsers","RetainedEngagedUsers","EnabledActiveUsers") and DateFrequency == cadence and Office365Category == iff(office365Category == "Commercial", "Commercial - External", office365Category) and CohortType == cohort
    and AppName == app and Platform == platform and AudienceGroup == "Production" and Feature == "All" and Capability == "All" and OMSTenantId == "All")
| extend StageName = MetricName, Value = MetricValue;
funneldata
| join kind=inner UserJourneyFunnelConfig() 
    on StageName
| join kind=leftouter UserJourneyAppFunnelConfig 
    on StageName, AppName, $left.DateFrequency == $right.Cadence
| extend ToolTipMessage=iff(isempty(AppToolTipMessage),ToolTipMessage,AppToolTipMessage)
| join kind=inner (funneldata
    | project Date, DenominatorColumn=StageName, Total=Value)
    on Date, DenominatorColumn
| project 
    Date,
    StageIndex,
    StageName,
    StageFriendlyName,
    DropOffName,
    DropOffFriendlyName,
    CanBeNull,
    Value,
    Total, 
    Percentage = round(100.0 * todouble(Value) / todouble(Total), 2), 
    ToolTipMessage
| order by Date desc, StageIndex asc`;

export const copilotMTEandTVTable = `
// Copilot MTE and TV Table
${scorecardDeclarations}
let orderTable = datatable(AppName: string, Sequence: int)
[
    "All", 1,
    "WXPN", 2, 
    "Word", 3, 
    "Excel", 4, 
    "PowerPoint", 5, 
    "OneNote", 6, 
    "Loop", 7,  
    "Whiteboard", 8,  
    "Teams", 9,  
    "Outlook", 10, 
    "M365 Chat", 11,
    "Stream", 12,
    "Forms", 13,
    "Planner", 14,
];
database('Copilot').MetricsWithTenants_Staging
| where Date == dt and DateFrequency == cadence and Platform == platform and AudienceGroup == audienceGroup and Office365Category == office365Category and CohortType == cohort and OMSTenantId == tenantId
    and Capability != "Unclear Intent" and Capability != "UNAPPROVED" and Capability != "UnknownIntent" and Feature == "All" | extend Capability = iif(Capability == "Generate" and AppName == "Excel", "Create", Capability)
| union
    (
    database("Copilot").ThumbsDownPer100k(dt, cadence, platform, audienceGroup, office365Category, cohort, tenantId)
    | where Capability != "All" and Capability != "Unclear Intent" and Capability != "UNAPPROVED" and Capability != "UnknownIntent" and Feature == "All" | extend Capability = iif(Capability == "Generate" and AppName == "Excel", "Create", Capability)
)
| union
    (
    database("Asha").ASHA_Copilot_Scorecard_Rates_AllApps(dt, cadence, platform, audienceGroup, office365Category, cohort, tenantId)
    | where Capability != "All" and Capability != "Unclear Intent" and Capability != "UNAPPROVED" and Capability != "UnknownIntent" and Feature == "All"
    | extend AppName = Application
    | extend Capability = iif(Capability == "Generate" and AppName == "Excel", "Create", Capability)
    )
| union ( 
    database('Copilot').AIRetentionWithTenants
    | where DateFrequency == cadence and Platform == platform and Office365Category == office365Category and AudienceGroup == audienceGroup 
        and CohortType == cohort and OMSTenantId == tenantId
    | where ProcessDate == (toscalar(
        database('Copilot').AIRetentionWithTenants
        | where DateFrequency == cadence
        | summarize max(ProcessDate)))
    | where Capability != "All" and Capability != "Unclear Intent" and Capability != "UNAPPROVED" and Capability != "UnknownIntent" and Feature == "All"
    | extend Capability = iif(Capability == "Generate" and AppName == "Excel", "Create", Capability)
    | project
        MetricName = "Week1RetentionRate",
        AppName,
        Capability,
        MetricValue = RetentionRate_1stPeriod
    )
| where MetricName == 'NegativeFeelingsRate' 
    or MetricName == 'NegativeTimeSavedRate'
    or MetricName == 'ThumbsDownPer100k'
    or MetricName == 'ASHA'
    or MetricName == 'PositiveProductMarketFitRate'
    or MetricName == 'ExceededExpectationsRate'
    or MetricName == 'FeelingsResponseCount'
    or MetricName == 'ExpectationsResponseCount'
    or MetricName == 'ProductMarketFitResponseCount'
    or MetricName == 'Week1RetentionRate'
    or MetricName == "CopilotNps"
    or MetricName == "TimeSavedResponseCount"
| where Capability != "Command"
| project AppName, Capability, MetricName, MetricValue
| evaluate pivot(MetricName, max(MetricValue))
| join kind = inner (orderTable) on AppName
| order by Capability asc
| order by Sequence asc`;

export const copilotCapTrendsData = `
// Copilot CapDataTrends
${appTrendsQueryDeclarations}
let feature = "\${feature}";
let app = reverseMapAppName("\${application}");
let intents = split("\${intents}",",");
Dates
| extend placeholder=1
| join kind = leftouter  (
    database('Copilot').CopilotCapMetrics
    | where Cadence == cadence
        and AppName == app
        and (AppPlatform == platform or platform == "All")
        and (AudienceGroup == audienceGroup or audienceGroup == "All")
        and (Data_Copilot_FeatureName == feature or feature == "All Features")
        and LabelValue in (intents)
        and (MetricName == "IsTried" or MetricName == "IsKept")
    | project Date, Scenario, MetricName, MetricValue
    | extend Date = format_datetime(todatetime(Date), "yyyy-MM-dd")
    )
    on $left.Date == $right.Date
| summarize sum(MetricValue) by Date, MetricName
| project Date, MetricName, MetricValue=sum_MetricValue
| evaluate pivot(MetricName, take_any(MetricValue))
| project Date, IsTried, IsKept
| order by Date asc`;

export const copilotCapData = `
// Copilot CapData
${scorecardDeclarations}
let feature = "\${feature}";

database('Copilot').CopilotCapMetrics
| extend Date = format_datetime(todatetime(Date), "yyyy-MM-dd")
| where Cadence == cadence
    and AppName == app
    and (AppPlatform == platform or platform == "All")
    and (AudienceGroup == audienceGroup or audienceGroup == "All")
    and (Data_Copilot_FeatureName == feature or feature == "All Features")
    and office365Category in ("Commercial", "All") // for MVP hardcode the value to Commercial only. There is no userSegment (ie office365category) column in the schema for MVP
    and MetricName in ("IsTried", "IsKept", "DistinctUserCount")
    and Date == dt
| where LabelValue != "Other: OtherK"
| extend AppName = mapAppName(AppName)
| evaluate pivot(MetricName, take_any(MetricValue))
| summarize DistinctUserCount = sum(column_ifexists("DistinctUserCount", int(null))),
            IsKept = sum(column_ifexists("IsKept", int(null))),
            IsTried = sum(column_ifexists("IsTried", int(null)))
        by Date, AppName, Data_Copilot_FeatureName, LabelValue
| project Date, AppName, Data_Copilot_FeatureName, LabelValue, DistinctUserCount, IsTried, IsKept
| order by Date asc`;

export const copilotCapFeatures = `
// Copilot Features for CAP
${scorecardDeclarations}
database('Copilot').CopilotCapMetrics
| extend Date = format_datetime(todatetime(Date), "yyyy-MM-dd")
| where Cadence == cadence
    and AppName == app
    and (AppPlatform == platform or platform == "All")
    and (AudienceGroup == audienceGroup or audienceGroup == "All")
    and office365Category in ("Commercial", "All")
    and Date == dt
| where LabelValue != "Other: OtherK"
| distinct Data_Copilot_FeatureName
| project Data_Copilot_FeatureName`;