import React from "react";
import { Dropdown } from "@fluentui/react";
import { defaultExtraLargeDropDownWidth } from "utils/Constants";

interface DropdownFilterProps {
    dropdownOptions: string[];
    onChange: (key: string) => void;
    selectedOption: string;
}

const DropdownFilter = ({
    dropdownOptions,
    onChange,
    selectedOption,
}: DropdownFilterProps) => {
    const options = dropdownOptions.map((option: string) => ({
        key: option,
        text: option,
    }));

    return (
        <Dropdown
            placeholder="Select one option"
            options={options}
            selectedKey={selectedOption}
            onChange={(_event, option) => onChange(option.key as string)}
            className="filterDropdown"
            dropdownWidth={defaultExtraLargeDropDownWidth}
        />
    );
};

export default DropdownFilter;
