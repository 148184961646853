export const macTenantList: string = `
//MacTenantList (To display the list of tenants)
let currDate = GetCurrentDate(false);
let GetColor = (status:long = 5) { case(status == 5, "Blue", status == 4, "Yellow", status == 3, "Red", status == 2, "DeepRed", status == 1, "Regression", status == 0, "Gray", "") };
let wxpoTenants = materialize(HealthyTenantScore_MacCrossAppView | where ProcessDate == "\${date}" | project Color, MAU, OmsTenantId, TopParentId = Tpid, Score);
// Take all tenants where atleast one of the app color is not gray, except for Linkedin
let allTenants = materialize (HealthyTenantScore_MacAppView  | where isnotempty(Tpid) and ProcessDate == "\${date}" | where Color != 0 or OmsTenantId == "-100" | distinct OmsTenantId, Tpid | project OmsTenantId, TopParentId = Tpid);
// Even though all tenants are taken, ranking and color on tenant list is done by WXPO score + color, others will be there in list but marked as gray
let Overview = allTenants | join kind = inner (wxpoTenants) on OmsTenantId, TopParentId
 | project Color = iff(isempty(Color), 0, Color), MAU = iff(isempty(MAU), 0, MAU), OmsTenantId, Tpid = TopParentId, Score = iff(isempty(Score), 0.0, Score);
 StrategicTenants
| where Date == currDate
| distinct Tpid, OrgName_Translated, IsS500, IsS2500, IsEPA, IsGoogleMove, IsGov, IsEO, IsCopilot, IsECAP
| summarize IsTpidS2500=max(IsS2500), IsTpidS500=max(IsS500), IsTpidEPA=max(IsEPA), IsTpidGoogleMove=max(IsGoogleMove), IsTpidGov=max(IsGov), IsTpidEO=max(IsEO), IsTpidCopilot=max(IsCopilot), IsTpidECAP=max(IsECAP) by Tpid, OrgName_Translated = iff(Tpid == 23321296, toupper(OrgName_Translated), OrgName_Translated)
| extend IsTpidOther = not (IsTpidS2500 or IsTpidS500 or IsTpidEPA or IsTpidGoogleMove or IsTpidGov or IsTpidEO or IsTpidCopilot)
| join kind = inner
(
    Overview
    | where OmsTenantId == "All"
)
on Tpid
| extend Mau = iff(isempty(MAU), 0, MAU)
| project Tpid, OrgName_Translated, Mau,
    TpidStatus = Color, 
    TpidOrder = case( Mau <= 500, 5,
        Score <= 25 , 4, 
        Score <= 50, 3,
        Score <= 75, 2, 1),
    TpidScore = Score,
    IsTpidS500, IsTpidS2500, IsTpidEPA, IsTpidGoogleMove, IsTpidGov, IsTpidEO, IsTpidCopilot, IsTpidOther, IsTpidECAP
| join kind = leftouter
(
    Overview
    | where OmsTenantId != "All"
    | project Tpid, OmsTenantId, 
        OmsStatus = Color,
        OmsMAU = MAU
    | join kind=inner (StrategicTenants
        | where Date == currDate ) on OmsTenantId
)
on Tpid
| order by TpidStatus desc, TpidOrder asc, Tpid asc, OmsMAU desc
| project Tpid, OrgName_Translated, TenantId = OmsTenantId, TenantName, TpidStatus=GetColor(TpidStatus), OmsStatus=GetColor(OmsStatus),
    IsS500 = iif(isempty(IsS500), IsTpidS500, IsS500), 
    IsS2500 = iif(isempty(IsS2500), IsTpidS2500, IsS2500),
    IsEPA = iif(isempty(IsEPA), IsTpidEPA, IsEPA),
    IsGoogleMove = iif(isempty(IsGoogleMove), IsTpidGoogleMove, IsGoogleMove),
    IsGov = iif(isempty(IsGov), IsTpidGov, IsGov),
    IsEO = iif(isempty(IsEO), IsTpidEO, IsEO),
    IsOther = iif(isempty(IsOther), IsTpidOther, IsOther),
    IsCopilot = iif(isempty(IsCopilot), IsTpidCopilot, IsCopilot),
    IsECAP = iif(isempty(IsECAP), IsTpidECAP, IsECAP),
    TenantName_Translated
`;
