import { IColumn } from "@fluentui/react";
import { CommonFilterStateType } from "components/CommonFunnelComponents/CommonFunnel/types";
import { eRFMFunnelType } from "config/FunnelConfig";

export interface ERFMFiltersType extends CommonFilterStateType {
    segment?: string;
    country?: string;
}

export interface ERFMStateType {
    erfmTableRows: ERFMTableType;
    loading: boolean;
    distinctDates: string[];
    dataByApp: {};
    dataByCountry: {};
}

export interface ERFMFunnelStateType {
    loading: boolean;
    error: string;
    distinctDates: string[];
}

export interface ERFMFiltersPropsType {
    erfmState?: ERFMStateType;
    filters: ERFMFiltersType;
    onFilterChange: (item: string, value: string) => void;
}

export interface ERFMRowType {
    Date: string;
    Segment: string;
    MAU?: number;
    PercentCAMAU?: number;
    M1?: number;
    M2?: number;
    M3?: number;
    PercentConvertedUsers?: number;
    UAECrashRate?: number;
    WatsonCrashRate?: number;
}

export interface ERFMTableType {
    [application: string]: ERFMRowType[];
}

export interface ERFMTablePropsType {
    items: ERFMTableType;
    columns: IColumn[];
    loading: boolean;
    application: string;
    dataByApp: {};
    dataByCountry: {};
    country: string;
}

export interface ERFMChartFlyoutPropsType {
    isPanelOpen: boolean;
    togglePanel: () => void;
    segment?: string;
    application: string;
    dataByApp: {};
    dataByCountry: {};
}

export interface MonthGrouping {
    [monthString: string]: string[];
}

export interface ERFMTableColumnConfig {
    [segment: string]: {
        level: number;
        displayName: string;
        title?: string;
        exportCSVHeaderName: string;
    };
}

export enum ERFMQueryNames {
    ERFM_DATES = "erfmDates",
    ERFM_TABLE = "erfmTable",
    ERFM_APP_CHART = "erfmAppChart",
    ERFM_COUNTRY_CHART = "erfmCountryChart",
}

export enum ERFMTab {
    Scorecard = "scorecard",
    Funnel_Upsell = "funnel_upsell",
}

export const eRFMSectionTabs = {
    Scorecard: "Scorecard",
    Funnel_Upsell: eRFMFunnelType.Upsell,
    Funnel_Engagement: eRFMFunnelType.Engagement,
};
