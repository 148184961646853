import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Stack, Icon, MessageBar, Link } from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import { CommonFunnelDecomposition } from "components/CommonFunnelComponents/CommonFunnelDecomposition/CommonFunnelDecomposition";
import { CommonFunnelDecompositionAutoPivots } from "components/CommonFunnelComponents/CommonFunnelDecompositionAutoPivots/CommonFunnelDecompositionAutoPivots";
import { CommonFunnelGraph } from "components/CommonFunnelComponents/CommonFunnelGraph/CommonFunnelGraph";
import { StandaloneMetrics } from "components/CommonFunnelComponents/CommonFunnelStandaloneMetrics/StandaloneMetrics";
import { CommonHistoricalTrendsPanel } from "components/CommonFunnelComponents/CommonHistoricalTrendsPanel/CommonHistoricalTrendsPanel";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderRight,
    CardHeaderTitle,
} from "components/CustomComponents/Card/Card";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { ShimmeredCardBody } from "components/CustomComponents/ShimmeredWrapper/ShimmeredCardBody";
import { getRequiredFiltersUrlFriendly } from "components/CommonFunnelComponents/CommonFunnel/funnelConfigHelper";
import {
    getStages,
    formatStagesData,
} from "components/CommonFunnelComponents/CommonFunnelStages/ApiHandler";
import {
    getFirstDateWithAllStages,
    formatFunnelStageOverTimeData,
    formatFunnelDropOffOverTime,
    IsFunnelDataEmpty,
} from "components/CommonFunnelComponents/CommonFunnelStages/commonFunnelStagesHelper";
import { horizontalStackTokensCommonFunnel } from "components/CommonFunnelComponents/CommonFunnelStages/constants";
import {
    CommonFunnelStageState,
    CommonFunnelStagesPropsType,
    FunnelDataType,
} from "components/CommonFunnelComponents/CommonFunnelStages/types";
import { getFunnelConfig } from "config/FunnelConfig";
import { logException } from "utils/AppInsightsHelper";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { NO_DATA_MESSAGES } from "utils/Messages";
import { sendUserActionTelemetryEvent } from "utils/PlgTelemetryLogger";

export const CommonFunnelStages = ({
    filters,
    funnelType,
    onFilterChange,
    getAdditionalTelemetryFields,
}: CommonFunnelStagesPropsType) => {
    const {
        messageBars,
        decompositionStages,
        decompositionVersion,
        noDataMessage,
        funnelDocumentationLink,
        standaloneMetrics,
    } = getFunnelConfig(funnelType);

    const [funnelStageState, setFunnelStageState] = useState<CommonFunnelStageState>(
        {
            loading: true,
            funnelData: {},
            funnelDataOverTime: [],
            funnelDropOffOverTime: [],
            error: null,
        },
    );
    const [showPanel, setShowPanel] = useState<boolean>(false);

    // Needs to be memoized to not cause infinite render loop in below useEffect
    const requiredFiltersUrlFriendly = useCallback(
        () => getRequiredFiltersUrlFriendly(funnelType, filters),
        [funnelType, filters],
    );

    // filtersCalculated will be one of the dependencies in useEffect, to avoid refresh with Obj reference change,
    // Creating a copy of the filters object with useMemo.
    const filtersCalculated = useMemo(() => {
        return {
            date: filters.date,
            application: filters.application,
            ...requiredFiltersUrlFriendly(),
            additionalFilters: filters.additionalFilters,
        };
    }, [filters, requiredFiltersUrlFriendly]);

    // Fetch stages data for the selected funnel type and filters
    useEffect(() => {
        const getPayload = async () => {
            setFunnelStageState((oldState) => ({ ...oldState, loading: true }));
            try {
                const rawStagesData = await getStages(filtersCalculated, funnelType);
                const stagesData: FunnelDataType = formatStagesData(rawStagesData);
                const funnelDataOverTime: any[] =
                    formatFunnelStageOverTimeData(rawStagesData);
                const funnelDropOffOverTimeData =
                    formatFunnelDropOffOverTime(stagesData);
                setFunnelStageState({
                    funnelData: stagesData,
                    funnelDataOverTime: funnelDataOverTime,
                    funnelDropOffOverTime: funnelDropOffOverTimeData,
                    loading: false,
                    error: null,
                });
            } catch (e) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        `Consumer${funnelType}FunnelStagesFetchFailed`,
                    ),
                    {
                        message: `Failed to fetch or format Consumer ${funnelType} Funnel Stages`,
                    },
                    e,
                );
                setFunnelStageState({
                    funnelData: {},
                    funnelDataOverTime: [],
                    funnelDropOffOverTime: [],
                    loading: false,
                    error: e.message,
                });
            }
        };
        getPayload();
    }, [funnelType, filtersCalculated]);

    // Retention funnels will not have all data available at first available date
    const firstDateWithAllStages: string = getFirstDateWithAllStages(
        funnelStageState.funnelData,
    );
    const showAllStagesHandler = () => {
        if (firstDateWithAllStages) {
            onFilterChange("date", firstDateWithAllStages);
        }
    };

    return funnelStageState.loading || filters.date == null ? (
        <ShimmeredCardBody />
    ) : funnelStageState.error ? (
        <EmptyTableContainer message={funnelStageState.error} />
    ) : Object.keys(funnelStageState.funnelData).length === 0 ||
      IsFunnelDataEmpty(funnelStageState.funnelData[filters.date]) ? (
        <EmptyTableContainer
            message={noDataMessage ?? NO_DATA_MESSAGES.NO_DATA_FOR_FILTER}
        />
    ) : (
        <>
            {messageBars &&
                messageBars.map(({ message, type }, index) => (
                    <MessageBar key={`message-bar-${index}`} messageBarType={type}>
                        {message}
                    </MessageBar>
                ))}
            <Stack horizontal tokens={horizontalStackTokensCommonFunnel}>
                <Stack.Item>
                    <Card>
                        <CardHeader>
                            <CardHeaderTitle>
                                {funnelType} Funnel{" "}
                                {funnelDocumentationLink && (
                                    <Link
                                        href={funnelDocumentationLink}
                                        target="_blank"
                                        aria-label="View Funnel Documentation"
                                        title="View Funnel Documentation"
                                    >
                                        <Icon iconName="Info" />
                                    </Link>
                                )}
                            </CardHeaderTitle>
                            <CardHeaderRight>
                                <div
                                    className="exploreButton"
                                    onClick={() => {
                                        sendUserActionTelemetryEvent(
                                            {
                                                userActionName:
                                                    "ExploreCopilotFunnelHistoricalTrendsButtonClick",
                                                commandSurface:
                                                    UserActionCommandSurface.Canvas,
                                            },
                                            getAdditionalTelemetryFields
                                                ? getAdditionalTelemetryFields()
                                                : {},
                                        );
                                        setShowPanel(true);
                                    }}
                                    aria-label="Explore Historical Trends"
                                    title="Explore Historical Trends"
                                >
                                    Historical Trends <Icon iconName="Chart" />
                                </div>
                            </CardHeaderRight>
                        </CardHeader>
                        <CardBody>
                            <CommonFunnelGraph
                                stagesData={
                                    funnelStageState.funnelData[filters.date]
                                }
                                showAllStagesHandler={
                                    firstDateWithAllStages
                                        ? showAllStagesHandler
                                        : null
                                }
                            />
                        </CardBody>
                    </Card>
                </Stack.Item>
                {decompositionStages &&
                    (decompositionVersion === 1 ? (
                        <Stack.Item>
                            <CommonFunnelDecomposition
                                filters={filters}
                                funnelType={funnelType}
                            />
                        </Stack.Item>
                    ) : (
                        <Stack.Item>
                            <CommonFunnelDecompositionAutoPivots
                                filters={filters}
                                funnelType={funnelType}
                            />
                        </Stack.Item>
                    ))}
                {standaloneMetrics && (
                    <Stack.Item>
                        <StandaloneMetrics
                            funnelType={funnelType}
                            filters={filters}
                        />
                    </Stack.Item>
                )}
                <Stack.Item>
                    <CommonHistoricalTrendsPanel
                        showPanel={showPanel}
                        setShowPanel={setShowPanel}
                        funnelType={funnelType}
                        funnelDataOverTime={funnelStageState.funnelDataOverTime}
                        funnelDropOffOverTime={
                            funnelStageState.funnelDropOffOverTime
                        }
                        funnelData={funnelStageState.funnelData}
                        filters={filters}
                        getAdditionalTelemetryFields={getAdditionalTelemetryFields}
                    />
                </Stack.Item>
            </Stack>
        </>
    );
};
