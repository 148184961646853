import React from "react";
import { CSVLink } from "react-csv";
import { CommandBarButton, Stack, Label } from "@fluentui/react";
import { CadenceFrequency } from "components/CustomComponents/CadenceLabel/CadenceLabel";
import DropdownFilter from "components/CustomComponents/DropdownFilter/DropdownFilter";
import NavigableDatePickerWithCalendar from "components/CustomComponents/NavigableDatePickerWithCalendar/NavigableDatePickerWithCalendar";
import { ERFMColumnConfig } from "components/ERFMComponents/ERFMHelper";
import { ERFMFiltersPropsType } from "components/ERFMComponents/types";
import { DrilldownPages, getPageConfig } from "config/PagesConfig";
import { getExportCsvHeaders } from "utils/Helpers";

const config = getPageConfig(DrilldownPages.ERFM);

const ERFMScorecardFilters = ({
    erfmState,
    filters,
    onFilterChange,
}: ERFMFiltersPropsType) => {
    return (
        <Stack horizontal className="filterPanel">
            <Stack horizontal style={{ minWidth: 270 }}>
                <NavigableDatePickerWithCalendar
                    backTitle="Go to Previous Week"
                    forwardTitle="Go to Next Week"
                    dateOptions={erfmState?.distinctDates}
                    onDateChange={(newDate: string) =>
                        onFilterChange("date", newDate)
                    }
                    dateFilter={filters.date}
                    cadence={CadenceFrequency.Weekly}
                    showGoToToday={false}
                />
                <Label
                    className="dropdownFilter"
                    styles={{
                        root: {
                            fontFamily: "Segoe UI",
                            fontWeight: "normal",
                            fontSize: "14px",
                            color: "#333",
                            backgroundColor: "unset",
                            padding: "5px",
                            borderRadius: "3px",
                        },
                    }}
                >
                    Country:
                </Label>
                <DropdownFilter
                    dropdownOptions={config.countryList}
                    onChange={(newCountry: string) =>
                        onFilterChange("country", newCountry)
                    }
                    selectedOption={filters.country}
                />
            </Stack>
            {Object.keys(erfmState.erfmTableRows).length > 0 &&
                erfmState.erfmTableRows[filters.application] && (
                    <CSVLink
                        data={erfmState.erfmTableRows[filters.application]}
                        filename={`eRFM-${filters.application}_${filters.date}.csv`}
                        headers={getExportCsvHeaders(
                            erfmState.erfmTableRows,
                            filters.application,
                            ERFMColumnConfig,
                        )}
                    >
                        <CommandBarButton
                            iconProps={{
                                iconName: "Download",
                            }}
                            styles={{
                                root: {
                                    height: 30,
                                    backgroundColor: "unset",
                                },
                            }}
                            text="Export"
                            title="Export all weeks data"
                        />
                    </CSVLink>
                )}
        </Stack>
    );
};

export default ERFMScorecardFilters;
