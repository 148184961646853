import React, { useEffect, useState } from "react";
import { PivotItem, Stack } from "@fluentui/react";
import { UserActionCommandSurface } from "@oga-plg/plg-telemetry/dist";
import { config } from "components/AIScorecardComponents/config";
import CopilotTabs from "components/AIScorecardComponents/CopilotTabs";
import { ErrorFallback } from "components/ErrorFallbackComponents/ErrorFallback";
import {
    audienceGroupOptions,
    cadenceOptions,
    cohortOptions,
    office365CategoryOptions,
    platformOptions,
} from "components/AIScorecardComponents/AIScorecardConstants";
import {
    getAIScorecardTabSelection,
    sendAIScorecardUserActionTelemetryEvent,
} from "components/AIScorecardComponents/AIScorecardTelemetryHelper";
import {
    fetchCopilotData,
    formatDates,
} from "components/AIScorecardComponents/ApiHandler";
import { AIScorecardFiltersType } from "components/AIScorecardComponents/types";
import { commonPageStyle } from "pages/common";
import AppInsights from "utils/AppInsights";
import { logException, logFilterUsage } from "utils/AppInsightsHelper";
import { Severity, TenantInsightsException } from "utils/Exceptions";
import { extractQueryParams, setUpDocumentUrl } from "utils/Helpers";
import "pages/AIScorecard/style.css";
import {
    flattenFilters,
    sendFiltersChangeTelemetryEvent,
    useSendLaunchEvent,
} from "utils/PlgTelemetryLogger";

const AIScorecard = () => {
    const [error, setError] = useState<string>(null);

    const [tab, setTab] = useState<string>(() => {
        return getAIScorecardTabSelection();
    });

    const [filters, setFilters] = useState<AIScorecardFiltersType>(() => {
        const initFilters: AIScorecardFiltersType = {
            application: config.defaults.app,
            date: null,
            platform: config.defaults.platform,
            office365Category: config.defaults.office365Category,
            audienceGroup: config.defaults.audienceGroup,
            cadence: config.defaults.cadence,
            cohort: config.defaults.cohort,
            tenantId: "All",
        };
        const filtersFromQueryParams = extractQueryParams();

        if (filtersFromQueryParams["application"]) {
            const dateFromURL = filtersFromQueryParams["date"] as string;
            initFilters.date = dateFromURL;
            const appFromURL = filtersFromQueryParams["application"] as string;
            const platformFromURL = filtersFromQueryParams["platform"] as string;
            const audienceGroupFromURL = filtersFromQueryParams[
                "audienceGroup"
            ] as string;
            const office365CategoryFromURL = filtersFromQueryParams[
                "office365Category"
            ] as string;
            const cadenceFromUrl = filtersFromQueryParams["cadence"] as string;
            const cohortFromUrl = filtersFromQueryParams["cohort"] as string;

            if (config.appsList.includes(appFromURL)) {
                initFilters.application = appFromURL;
            }
            if (platformOptions.map((x) => x.key).includes(platformFromURL)) {
                initFilters.platform = platformFromURL;
            }
            if (
                audienceGroupOptions.map((x) => x.key).includes(audienceGroupFromURL)
            ) {
                initFilters.audienceGroup = audienceGroupFromURL;
            }
            if (
                office365CategoryOptions
                    .map((x) => x.key)
                    .includes(office365CategoryFromURL)
            ) {
                initFilters.office365Category = office365CategoryFromURL;
            }
            if (cadenceOptions.map((x) => x.key).includes(cadenceFromUrl)) {
                initFilters.cadence = cadenceFromUrl;
            }
            if (cohortOptions.map((x) => x.key).includes(cohortFromUrl)) {
                initFilters.cohort = cohortFromUrl;
            }
        }

        return {
            ...initFilters,
        };
    });

    const [dates, setDates] = useState<string[]>([]);

    const onFilterChange = (item: string, value: string) => {
        const updatedFilter = { ...filters, [item]: value };
        const updatedTab = getAIScorecardTabSelection(updatedFilter.application);
        const updatedQueryParams = { ...updatedFilter, tab: updatedTab };
        logFilterUsage(config.pageTitle, item, value);
        setUpDocumentUrl(updatedQueryParams, config.pageTitle);
        setFilters(updatedFilter);
        setTab(updatedTab);

        sendFiltersChangeTelemetryEvent(updatedFilter);
    };

    const onTabSelected = (item?: PivotItem) => {
        const updatedTab = item.props.itemKey;
        sendAIScorecardUserActionTelemetryEvent(
            {
                userActionName: "ScorecardDetailsTabSwitch",
                commandSurface: UserActionCommandSurface.Canvas,
            },
            { SwitchedToTab: updatedTab },
        );
        setUpDocumentUrl(
            {
                ...extractQueryParams(),
                tab: updatedTab,
            },
            config.pageTitle,
        );
        setTab(updatedTab);
    };

    useSendLaunchEvent(config?.pageToolNameForTelemetry, flattenFilters(filters));

    useEffect(() => {
        const getDates = async () => {
            setError(null);

            try {
                const dateJson = await fetchCopilotData(
                    { cadence: filters.cadence },
                    "aiScorecardDates",
                );

                const datesData = formatDates(dateJson);

                if (datesData.length === 0)
                    throw new Error("Copilot Scorecard has no dates");

                let filterDate = datesData.includes(filters.date)
                    ? filters.date
                    : datesData[0];
                if (filterDate !== filters.date) {
                    setFilters((filters) => ({
                        ...filters,
                        date: filterDate,
                    }));
                }
                setDates(datesData);
            } catch (error) {
                logException(
                    new TenantInsightsException(
                        Severity.SEV3,
                        "CopilotScorecardDatesProcessingFailed",
                    ),
                    {
                        message:
                            "Failed to fetch or format Copilot Scorecard date options",
                    },
                    error,
                );

                setError(error.message);
                setDates([]);
            }
        };
        getDates();
    }, [filters.cadence, filters.date]);

    // App insights tracking
    useEffect(() => {
        document.title = config.pageTitle;
        AppInsights.getInstance().TrackPage(config.pageTitle);
    }, []);

    return (
        <>
            {error && <ErrorFallback message={error} />}
            <Stack styles={commonPageStyle}>
                <Stack.Item>
                    <div className="orgHeader">{config.pageHeader}</div>
                </Stack.Item>
                <Stack.Item>
                    <CopilotTabs
                        onFilterChange={onFilterChange}
                        onTabSelected={onTabSelected}
                        filters={filters}
                        dates={dates}
                        tab={tab}
                    />
                </Stack.Item>
            </Stack>
        </>
    );
};

export default AIScorecard;
